import { useCallback, useEffect, useMemo } from 'react'
import ReactFlow, {
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
} from 'reactflow'

import 'reactflow/dist/style.css'
import { StepNode } from './StepNode'
import { Workflow } from '@/stores/WorkflowDefinition'
import { observer } from 'mobx-react-lite'
import { WorkflowToNodes } from './util'
import { computed } from 'mobx'

export interface DesignerProps {
    workflow: Workflow
}

export const Designer = observer(({workflow}: DesignerProps) => {
    const [wfNodes, wfEdges] = useMemo(() => computed(() => WorkflowToNodes(workflow)), [workflow]).get()

    const [nodes, setNodes, onNodesChange] = useNodesState(wfNodes)
    const [edges, setEdges, onEdgesChange] = useEdgesState(wfEdges)

    const nodeTypes = useMemo(() => ({ step: StepNode }), [])
    const onConnect = useCallback((params: any) => setEdges((eds) => addEdge(params, eds)), [setEdges])
    const defaultViewport = { x: 300, y: 100, zoom: 1 };

    useEffect(() => {
        setNodes(wfNodes)
        setEdges(wfEdges)
    }, [wfNodes, wfEdges])

    return (
    <ReactFlow
        defaultViewport={defaultViewport}
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        

    >
      <MiniMap />
      <Controls />
      <Background />
    </ReactFlow>
    )
})