import { makeAutoObservable } from 'mobx'
import { ReactNode } from 'react'
import { createUseStore } from './util'
import { TablerIconsProps } from '@tabler/icons-react'

export class UtilityBar {
    items: Array<UtilityBarItem> = []

    constructor() {
        makeAutoObservable(this)
    }

    getItem(id: string) {
        return this.items.find(i => i.id == id) as UtilityBarItem
    }

    addOrReplaceItem(item: UtilityBarItem) {
        const existing = this.items.find(i => i.id == item.id)
        if (existing) {
            const index = this.items.indexOf(existing)
            this.items.splice(index, 1, item)
        }
        else {
            this.items.push(item)
        }
    }

    addItems(items: Array<UtilityBarItem>) {
        items.forEach(i => this.items.push(i))
    }

    containerGroupItems(container: string, group: string) {
        const items = this.items.filter(i => i.group == group && i.container == container)
        return items
    }

    containerItems(container: string) {
        const items = this.items.filter(i => i.container == container)

        return items
    }

    groupItems(group: string) {
        const items = this.items.filter(i => i.group == group)
        return items
    }
}
export const [UtilityBarCtx, useUtilityBar] = createUseStore(UtilityBar)

export type UtilityBarItem = UtilityWidgetItem | UtilityActionItem

export interface UtilityItem {
    id: string
    icon?: (props: TablerIconsProps) => React.ReactElement
    class?: string
    label?: string
    container?: string
    group?: string
    visible: boolean
    count?: number
}

export interface UtilityActionItem extends UtilityItem {
    type: 'action',
    action: () => void
}

export interface UtilityWidgetItem extends UtilityItem {
    type: 'widget'
    widget: ReactNode
}

