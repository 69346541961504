interface StepNameProps {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
    name: string
}

export const BaseStepNameStyles = {
    xs: 'text-xs capitalize',
    sm: 'text-sm font-medium capitalize',
    md: 'text-base capitalize',
    lg: 'text-lg capitalize',
    xl: 'text-xl capitalize',
    xxl: 'text-2xl font-bold capitalize mb-3 mt-3',
}

//export const StepName = ({name}: {name: string}) => {
export const StepName = (props: StepNameProps) => {
    const {
        size = 'sm',
        name= 'step'
    } = props
    return (
        <div className={BaseStepNameStyles[size]}>
            {name}
        </div>
    )
}