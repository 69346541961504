import {
  OperationParameter,
  OperationURLParameter,
  OperationQueryParameter,
} from "@azure/core-client";
import {
  AuthLoginDto as AuthLoginDtoMapper,
  AuthRegisterDto as AuthRegisterDtoMapper,
  WorkflowCreateDto as WorkflowCreateDtoMapper,
  BulkResponseCreateDto as BulkResponseCreateDtoMapper,
  ReviewCreateDto as ReviewCreateDtoMapper,
  RunbookCreateDto as RunbookCreateDtoMapper,
  RunbookUpdateDto as RunbookUpdateDtoMapper,
  RunbookMoveDto as RunbookMoveDtoMapper,
  WorkspaceCreateDto as WorkspaceCreateDtoMapper,
} from "../models/mappers";

export const accept: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json, text/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const $host: OperationURLParameter = {
  parameterPath: "$host",
  mapper: {
    serializedName: "$host",
    required: true,
    type: {
      name: "String",
    },
  },
  skipEncoding: true,
};

export const apiVersion: OperationURLParameter = {
  parameterPath: "apiVersion",
  mapper: {
    defaultValue: "1",
    serializedName: "apiVersion",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const xSwOrgId: OperationParameter = {
  parameterPath: "xSwOrgId",
  mapper: {
    serializedName: "x-sw-org-id",
    required: true,
    type: {
      name: "Number",
    },
  },
};

export const contentType: OperationParameter = {
  parameterPath: ["options", "contentType"],
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Content-Type",
    type: {
      name: "String",
    },
  },
};

export const body: OperationParameter = {
  parameterPath: "body",
  mapper: AuthLoginDtoMapper,
};

export const provider: OperationQueryParameter = {
  parameterPath: ["options", "provider"],
  mapper: {
    serializedName: "provider",
    type: {
      name: "String",
    },
  },
};

export const returnUrl: OperationQueryParameter = {
  parameterPath: ["options", "returnUrl"],
  mapper: {
    serializedName: "returnUrl",
    type: {
      name: "String",
    },
  },
};

export const body1: OperationParameter = {
  parameterPath: "body",
  mapper: AuthRegisterDtoMapper,
};

export const userId: OperationQueryParameter = {
  parameterPath: "userId",
  mapper: {
    serializedName: "userId",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const code: OperationQueryParameter = {
  parameterPath: "code",
  mapper: {
    serializedName: "code",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const email: OperationQueryParameter = {
  parameterPath: "email",
  mapper: {
    serializedName: "email",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const password: OperationQueryParameter = {
  parameterPath: "password",
  mapper: {
    serializedName: "password",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const docId: OperationURLParameter = {
  parameterPath: "docId",
  mapper: {
    serializedName: "docId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const orgId: OperationURLParameter = {
  parameterPath: "orgId",
  mapper: {
    serializedName: "orgId",
    required: true,
    type: {
      name: "Number",
    },
  },
};

export const version: OperationQueryParameter = {
  parameterPath: ["options", "version"],
  mapper: {
    serializedName: "version",
    type: {
      name: "Number",
    },
  },
};

export const data: OperationParameter = {
  parameterPath: ["options", "data"],
  mapper: {
    serializedName: "data",
    type: {
      name: "Stream",
    },
  },
};

export const accept1: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json, text/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String",
    },
  },
};

export const contentType1: OperationParameter = {
  parameterPath: "contentType",
  mapper: {
    serializedName: "Content-Type",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const contentLength: OperationParameter = {
  parameterPath: "contentLength",
  mapper: {
    serializedName: "Content-Length",
    required: true,
    type: {
      name: "Number",
    },
  },
};

export const xSwFilename: OperationParameter = {
  parameterPath: ["options", "xSwFilename"],
  mapper: {
    serializedName: "x-sw-filename",
    type: {
      name: "String",
    },
  },
};

export const fileId: OperationURLParameter = {
  parameterPath: "fileId",
  mapper: {
    serializedName: "fileId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const workflowId: OperationURLParameter = {
  parameterPath: "workflowId",
  mapper: {
    serializedName: "workflowId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const revision: OperationQueryParameter = {
  parameterPath: ["options", "revision"],
  mapper: {
    serializedName: "revision",
    type: {
      name: "Number",
    },
  },
};

export const body2: OperationParameter = {
  parameterPath: "body",
  mapper: WorkflowCreateDtoMapper,
};

export const id: OperationURLParameter = {
  parameterPath: "id",
  mapper: {
    serializedName: "id",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const executionId: OperationURLParameter = {
  parameterPath: "executionId",
  mapper: {
    serializedName: "executionId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const body3: OperationParameter = {
  parameterPath: "body",
  mapper: BulkResponseCreateDtoMapper,
};

export const body4: OperationParameter = {
  parameterPath: "body",
  mapper: ReviewCreateDtoMapper,
};

export const stepId: OperationURLParameter = {
  parameterPath: "stepId",
  mapper: {
    serializedName: "stepId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const workspaceId: OperationURLParameter = {
  parameterPath: "workspaceId",
  mapper: {
    serializedName: "workspaceId",
    required: true,
    type: {
      name: "Number",
    },
  },
};

export const body5: OperationParameter = {
  parameterPath: "body",
  mapper: RunbookCreateDtoMapper,
};

export const runbookId: OperationURLParameter = {
  parameterPath: "runbookId",
  mapper: {
    serializedName: "runbookId",
    required: true,
    type: {
      name: "Uuid",
    },
  },
};

export const body6: OperationParameter = {
  parameterPath: "body",
  mapper: RunbookUpdateDtoMapper,
};

export const body7: OperationParameter = {
  parameterPath: "body",
  mapper: RunbookMoveDtoMapper,
};

export const search: OperationQueryParameter = {
  parameterPath: "search",
  mapper: {
    serializedName: "search",
    required: true,
    type: {
      name: "String",
    },
  },
};

export const query: OperationQueryParameter = {
  parameterPath: ["options", "query"],
  mapper: {
    serializedName: "query",
    type: {
      name: "String",
    },
  },
};

export const orgId1: OperationQueryParameter = {
  parameterPath: ["options", "orgId"],
  mapper: {
    serializedName: "orgId",
    type: {
      name: "Number",
    },
  },
};

export const org: OperationURLParameter = {
  parameterPath: "org",
  mapper: {
    serializedName: "org",
    required: true,
    type: {
      name: "Number",
    },
  },
};

export const body8: OperationParameter = {
  parameterPath: ["options", "body"],
  mapper: WorkspaceCreateDtoMapper,
};

export const body9: OperationParameter = {
  parameterPath: "body",
  mapper: WorkspaceCreateDtoMapper,
};
