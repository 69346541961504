import React, { useContext, useEffect, useMemo } from 'react'
import { NodeViewWrapper, NodeViewProps } from '@tiptap/react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'
import { RootContext, WorkspaceContext } from '@/contexts'
import { AbstractStep, Workflow } from '@/stores/WorkflowDefinition'
import { WorkflowStepInfo } from '@/components/Workflow/StepInfo'
import { computed } from 'mobx'
import { useButton, usePress } from 'react-aria'
import { WorkspaceMode } from '@/stores/WorkspaceCtx'


function renderStep(workflow: Workflow, step: AbstractStep) {
    return (<>
        {step ? <WorkflowStepInfo mode={WorkspaceMode.Read} step={step} variant='chip' /> : renderPlaceholder()}
    </>
    )}

function renderPlaceholder() {return(
    <div className="h-20">
        Oops.
    </div>
)}

export const Component =  observer((props: NodeViewProps) => {
    const rootStore = useContext(RootContext)!
    const workspaceCtx = useContext(WorkspaceContext)!
    const {runbooks, workflows} = rootStore

    const runbook = workspaceCtx.activeRunbook!
    console.log('Runbook', runbook)
    const workflow = workflows.byRef.get(workflows.refToKey(runbook.workflowRef))

    const stepId = props.node.attrs['step-id']
    const step = workflow?.stepsById.get(stepId) ?? null

    const selected = computed( () => workflow?.selectedStep === step).get()

    useEffect(() => {
        if (props.selected) {
            workflow && (workflow.selectedStep = step)
        }
    }, [props.selected])

    return (
        <NodeViewWrapper
            draggable={true}
            className={clsx(
                'stepwise-step rounded-lg p-1 bg-gray-200 w-8/12',
                props.selected ? 'ring-4 ring-blue-300' :
                selected ? '' : '',
            )}
            data-drag-handle
        >
            {workflow && step ? renderStep(workflow, step) : renderPlaceholder()}
        </NodeViewWrapper>
    )
})
Component.displayName = 'StepNodeView'