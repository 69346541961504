import { RootContext } from "@/contexts"
import { Context, createContext, useContext } from "react"

export const createUseStore = <T>(storeType: Newable<T>): [Context<T | null>, () => T] => {
    const context = createContext<T | null>(null)

    return [context, () => {
        const rootStore = useContext(RootContext)

        let store = rootStore?.getStoreOf(storeType)

        if (!store)
            store = useContext(context)

        if (!store) throw new Error(`Must call use hook for ${storeType.name} in context`)

        return store
    }]
}