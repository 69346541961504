import { useAppStore } from "@/stores/AppStore"
import { observer } from "mobx-react-lite"
import { RunbookContent } from "./runbookContent/RunbookContent"
import { ActivityContent } from "./activityContent/ActivityContent"


export const MainContent = observer(() => {
    const app = useAppStore()

    switch (app.screen) {
    case "runbook":
        return <RunbookContent/>
    case "activity":
        return <ActivityContent/>
    }
})