import { SwBadge } from "@/components/Badge/Badge"
import { SwIcon } from "@/components/Icon/Icon"
import { SwText } from "@/components/Text/Text"
import { useWorkspaceCtx } from "@/contexts"
import { RunbookExecution } from "@/stores/Runbook"
import { ExecutionStatus, Input, InputStep, ResponseRec, Step, StepStatus, StepType, Workflow, WorkflowState } from "@/stores/WorkflowDefinition"
import { IconClock, IconKey, IconCircleCheck, IconCircleX, IconProgress } from "@tabler/icons-react"
import humanizeDuration from "humanize-duration"
import { observer } from "mobx-react-lite"


export const Summary = observer(() => {
    const workspaceCtx = useWorkspaceCtx()
    const runbookEx = workspaceCtx.activeRunbookExecution
    const workflow = workspaceCtx.activeWorkflow
    const wfState = workflow?.state

    if (!workflow || !wfState || !runbookEx) return <></>

    return  <div className="sw-scroller h-full xl:max-w-4xl 2xl:max-w-5xl 3xl:max-w-6xl mx-auto">
        <div className="flex flex-col py-4 px-2 gap-4 max-w-5xl mx-auto">

            <QuickRef state={wfState} runbookEx={runbookEx}/>
            <Steps workflow={workflow}/>
        </div>
    </div>
})


const StatusBadge = ({status}: {status: ExecutionStatus}) => {
    const [variant, icon] =
        status == ExecutionStatus.Succeeded ? ['positive', IconCircleCheck]
        : status == ExecutionStatus.Failed ? ['negative', IconCircleX]
        : status == ExecutionStatus.Running ? ['info', IconProgress]
        : ['neutral', IconProgress]

    // @ts-ignore
    return <SwBadge variant={variant}>
        <SwIcon icon={icon} />
        <SwText>{status}</SwText>
    </SwBadge>
}

const StepStatusBadge = ({status}: {status: StepStatus}) => {
    const [variant, icon] =
        status == StepStatus.Succeeded ? ['positive', IconCircleCheck]
        : status == StepStatus.Failed ? ['negative', IconCircleX]
        : status == StepStatus.Running ? ['info', IconProgress]
        : ['neutral', IconProgress]

    // @ts-ignore
    return <SwBadge variant={variant}>
        <SwIcon icon={icon} />
        <SwText>{status}</SwText>
    </SwBadge>
}

const QuickRef = ({state, runbookEx}: {state: WorkflowState, runbookEx: RunbookExecution}) => {

    const duration = (state.startedAt && state.finishedAt) ?
        `${humanizeDuration((state.finishedAt.getTime() - state.startedAt.getTime()), {maxDecimalPoints: 0, largest: 2, delimiter: ' '})}`
        : '-- --'

    return <div className="grid grid-cols-4 gap-2 rounded-md py-4">
        <QuickRefItem label="Duration" data={duration} />
        <QuickRefItem label="Trigger" data={"Manual"} />
        <QuickRefItem label="Id" data={`#${runbookEx.id.split('-')[0].slice(0, 6)}`} />
        <QuickRefItem label="Author" data={state.author.mentionName} />
    </div>
}

const QuickRefItem = ({label, data}: {label: string, data: string}) => (
    <div className="flex flex-col items-center text-center bg-gray-100 rounded-xl p-3 ">
        <div className="uppercase tracking-wide text-xs  text-gray-500">{label}</div>
        <div className="text-2xl font-bold text-ellipsis overflow-hidden max-w-[100px] xl:max-w-[160px]">{data}</div>
    </div>
)

const Steps = ({workflow}: {workflow: Workflow}) => {
    return <div className="flex flex-col gap-2">
        {workflow.stepList.map((s, index) => <StepItem step={s} index={index}/>)}
    </div>
}


const StepItem = ({step, index}: {step: Step, index: number}) => {
    const duration = (step.startedAt && step.finishedAt) ?
    `${humanizeDuration((step.finishedAt.getTime() - step.startedAt.getTime()), {maxDecimalPoints: 0, largest: 2, delimiter: ' '})}`
    : '-- --'
    return <div className="border border-neutral-300 p-4 rounded-lg flex flex-row justify-between space-x-4">
        <div className="flex justify-start items-start">
            <div className="h-6 w-6 bg-black text-white rounded-full flex items-center justify-center mr-3">{index+1}</div>
                <div className="">
                    <div className="text-3xl -mt-1">{step.displayName}</div>
                    <div className="text-sm text-neutral-500">{step.comment}</div>
                    <div className="mt-3 text-xs">
                        <span className="font-mono bg-amber-50 text-amber-700 rounded-full px-2 py-1">
                            <IconClock size="15" className="-mt-[.6px] mr-1 inline-block"/>
                            {duration}
                        </span>
                        <span className="font-mono bg-cyan-50 text-cyan-700 rounded-full px-2 py-1">
                            <IconKey size="15" className="-mt-[.6px] mr-1 inline-block"/>
                            {step.id}
                        </span>
                    </div>
                    <StepSummary step={step}/>
            </div>
        </div>

        <StepStatusBadge status={step.status!}/>

    </div>
}

const StepSummary = ({step}: {step: Step}) => {
    if (step instanceof InputStep)
        return <ItemStepSummary step={step}/>
    else
        return <></>
}

const ItemStepSummary = ({step}: {step: InputStep}) => {
    return <>
        {step?.state?.submittedAt && <div className="flex gap-2">
            <span>Submitter:</span>
            <span>{step?.state?.submitter?.userName}</span>
            <span>{step.state?.submittedAt?.toLocaleString()}</span>
        </div>}
        {step?.state?.review?.reviewedAt && <div className="flex gap-2">
            <span>Reviewer:</span>
            <span>{step?.state?.review?.reviewer?.userName}</span>
            <span>{step.state?.review?.reviewedAt?.toLocaleString()}</span>
            <span>{step.state?.review?.approved ? 'Approved' : 'Rejected'}</span>
        </div>}
        {[...step.inputs.values()].map(p => <Item prompt={p}/>)}
    </>
}

const Item = observer(({prompt}: {prompt: Input}) => {
    return <div>
        {prompt.key}
        <div className="ml-2">

        </div>
    </div>
})