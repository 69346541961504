import {
    MenuItem,
    MenuItemProps,
    Menu as AriaMenu,
    MenuProps,
    MenuTrigger as AriaMenuTrigger,
    MenuTriggerProps } from 'react-aria-components'
import { SwButton } from '../Button/Button'

import './Menu.css'
import { SwPopover } from '../Popover/Popover'


export const SwMenuTrigger = AriaMenuTrigger
export const SwMenu = AriaMenu

interface MyMenuButtonProps<T>
    extends MenuProps<T>, Omit<MenuTriggerProps, 'children'> {
    label?: string;
}

export const SwMenuButton = <T extends object>(
    { label, children, ...props }: MyMenuButtonProps<T>
) => {
    return (
    <AriaMenuTrigger {...props}>
      <SwButton>{label}</SwButton>
      <SwPopover>
        <AriaMenu {...props}>
            {children}
        </AriaMenu>
      </SwPopover>
    </AriaMenuTrigger>
    )
}

export function SwMenuItem(props: MenuItemProps) {
    return (
    <MenuItem
        {...props}
    />
    )
}


