import { SwButton } from "@/components"
import { SwIcon } from "@/components/Icon/Icon"
import { SwText } from "@/components/Text/Text"
import { useWorkflowStore } from "@/stores/Workflow"
import { InputStep, InputType, StepStatus } from "@/stores/WorkflowDefinition"
import { IconBox, IconCheck, IconCheckbox, IconSquare } from "@tabler/icons-react"
import { observer } from "mobx-react-lite"
import { StepName } from "./component/StepName"
import { useWorkspaceCtx } from "@/contexts"
import { WorkspaceMode } from "@/stores/WorkspaceCtx"
import { StepIcon } from "@/components/Workflow/StepIcon"
import { SwBadge } from "@/components/Badge/Badge"
import { StepLabel } from "./StepLabel"

export interface AckStepProps {
    step: InputStep
}

export const AckStep = observer(({step}: AckStepProps) => {
    const workspaceCtx = useWorkspaceCtx()
    const workflows = useWorkflowStore()

    const isRunning = step.status == StepStatus.Running
    const isComplete = step.status == StepStatus.Succeeded

    const onSubmit = async () => {
        const prompt = Array.from(step.inputs.values())[0]

        await workflows.bulkResponse(step.workflow!, {
            step: step.key
        })
    }
    return <>
        <div contentEditable={false} className='bg-slate-200 flex items-center p-2 justify-between rounded-b-md'>
            <StepLabel step={step} onClick={() => {}}/>
            {workspaceCtx.mode == WorkspaceMode.Execute && <>
                {isRunning && <AckButton onPress={onSubmit} step={step} active={workspaceCtx.mode == WorkspaceMode.Execute}/>}
                {isComplete && <AckBadge/>}
            </>}
        </div>
    </>
})


const AckBadge = () => {
    return <SwBadge variant="neutral">
        <SwIcon icon={IconCheckbox}/>
        <SwText>Completed</SwText>
    </SwBadge>
}

interface AckButtonProps {
    onPress: () => void
    active: boolean
    step: InputStep
}

const AckButton = observer(({step, active, onPress}: AckButtonProps) => {
    const workspaceCtx = useWorkspaceCtx()
    const complete = active && step.status == StepStatus.Succeeded

    const workflow = workspaceCtx.activeWorkflow

    const label = complete ? 'Completed' : 'Complete'
    const icon = complete ? IconCheckbox : IconSquare

    const variant = active ?
        complete ? 'cta' : 'primary'
        : 'secondary'

    const style = active ? 'fill' : 'outline'

    if(workspaceCtx.mode == WorkspaceMode.Write){
        return (
            <SwButton onPress={() => {workflow!.selectedStep = step}} size="sm" variant={variant}>
                <SwText>edit</SwText>
            </SwButton>
        )
    } else {
        return (<SwButton onPress={onPress} size="sm" style={style} variant={variant}><SwText>{label}</SwText></SwButton>)
    }
})
