import { observer } from "mobx-react-lite"
import { useCallback, useState } from 'react'


import {
    RJSFSchema,
    UiSchema } from '@rjsf/utils'
import { IChangeEvent } from '@rjsf/core'
import validator from '@rjsf/validator-ajv8'
import { SchemaForm } from "@/components/SchemaForm/SchemaForm"
import { useAuthStore } from "@/hooks/useStores"
import { ErrorDto } from "@/client"

const schema: RJSFSchema = {
    type: 'object',
    required: [
        "username",
        "password"
    ],
    properties: {
        username: {
            type: "string",
            title: "Username"
        },
        password: {
            type: "string",
            title: "Password"
        },
    }
}

const uiSchema: UiSchema = {
    password: {
        "ui:widget": "password"
    },
    "ui:submitButtonOptions": {
        submitText: "Login"
    }
}

export interface LoginWidgetProps {
    onLogin: () => void
}

export const LoginWidget = observer(({onLogin}: LoginWidgetProps) => {
    const auth = useAuthStore()
    const [swError, setSwError] = useState<ErrorDto | undefined>(undefined)

    const _onSubmit = useCallback(async (e: IChangeEvent<{username: string, password: string}>) => {
        if (!e.formData) return

        console.log("Login:", e)

        const {username, password} = e.formData
        try {
            const user = await auth.login(username, password)
        } catch (e) {
            const ex = e as ErrorDto
            setSwError(ex)
        }
        onLogin()
    }, [])

    return (
    <SchemaForm
        className="w-full"
        onSubmit={_onSubmit}
        onChange={(e) => {console.log('Change', e)}}
        schema={schema}
        validator={validator}
        uiSchema={uiSchema}
        swError={swError}/>
    )
})