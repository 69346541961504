import React, { ForwardedRef, forwardRef, useRef } from 'react'
import {useFocusRing, mergeProps, useToggleButton} from 'react-aria'

import clsx from 'clsx'
import { ToggleButtonProps } from './Props'
import { SwText } from '../Text/Text'
import { useToggleState } from 'react-stately'
import './ToggleButton.css'
import { SlotProvider } from '@react-spectrum/utils'
import { BaseButtonStyles } from '../LegacyButton'
import { ToggleButton } from 'react-aria-components'

const Styles = {
    ...BaseButtonStyles
}

/**
 * Primary UI component for user interaction
 */
export const SwToggleButton = forwardRef((props: ToggleButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
        primary = false,
        isQuiet = false,
        size = 'md',
        variant = 'secondary',
        children,
        ...otherProps
    } = props

    const isTextOnly = React.Children.toArray(children).every(c => !React.isValidElement(c))

    return (
    <ToggleButton
        className={({isFocusVisible, isDisabled}) => clsx(
            ...[Styles.outline, Styles.main],
            Styles.size[size],
            Styles.variant[variant],
            isFocusVisible && Styles.focus,
            isQuiet ? Styles.border.quiet : Styles.border.loud,
            !isDisabled && Styles.hover,
        )}
        ref={ref}
        {...otherProps}
    >
        <SlotProvider
            slots={{
                text: { className: "last:mr-3 first:ml-3" },
                icon: { className: "h-5 w-5 m-1" }
            }}
        >
            {typeof children === 'string' || isTextOnly
                ? <SwText>{children}</SwText>
                : children}
        </SlotProvider>
    </ToggleButton>)
})
