import { Extension } from "@tiptap/core"
import {Node as PNode} from 'prosemirror-model'
import { Step } from "../nodes/step-block/Extension"
import { WorkflowChange } from "@/stores/Workflow"
import { WorkspaceCtx } from "@/stores/WorkspaceCtx"
import { IStep, IWorkflowDefinition, Workflow } from "@/stores/WorkflowDefinition"
import { EditorState, Plugin, PluginKey } from "prosemirror-state"

export interface WorkflowSyncOpts {
    workspaceCtx: WorkspaceCtx
}

const wfSyncKey = new PluginKey('wfSync')

/** Syncs a Workflow with document updates. */
export const WorkflowSync = Extension.create<WorkflowSyncOpts>({
    name: 'WorkflowSync',

    onCreate() {
        this.options.workspaceCtx.workflows.onDidChange(({detail}) => {
            queueMicrotask(() => {
                const tr = syncWorkflowToDocTx(this.editor.state, detail)
                this.editor.view.dispatch(tr)
            })
        })
    },

    addOptions: () => ({
        workspaceCtx: null! as WorkspaceCtx
    }),

    onUpdate() {},

    onTransaction({transaction}) {
        // let changeset = ChangeSet.create(transaction.before)
        // changeset = changeset.addSteps(transaction.doc, transaction.mapping.maps, ["a"])
        // console.log(changeset)
        // changeset.changes.forEach(change => {
        //     const doc = transaction.before
        //     const _fromA = doc.resolve(change.fromA)
        //     const _toA = doc.resolve(change.toA)
        //     const selection = new SelectionRange(_fromA, _toA)

        //     console.log(transaction.before.nodeAt(change.fromA))
        //     transaction.before.nodesBetween(change.fromA, change.toA, (node, pos, parent, index) => {
        //         console.log(node, pos, parent, index)
        //     })
        // })

        if (transaction.getMeta(wfSyncKey)) return
        if (!transaction.docChanged) return

        const doc = this.editor.state.doc
        const steps = extractSteps(doc)

        const workflow = this.options.workspaceCtx.activeWorkflow
        if (!workflow) return
        syncDocToWorkflow(workflow, steps)
    },

    addProseMirrorPlugins() {
        const workspaceCtx = this.options.workspaceCtx

        return [
            new Plugin({
                key: new PluginKey('WorkflowSync'),
                appendTransaction(transactions, oldState, newState) {
                    return null
                }
            })
        ]
    }
})


type NodeStep = {
    id: string
    pos: number
    step: IStep
}

function extractSteps(node: PNode) {
    const nodeSteps: NodeStep[] = []
    const steps: [string, number][] = []
    node.nodesBetween(0, node.content.size -1, (node, pos) => {
        if (node.type.name == Step.name) {
            const nodeStep = { id: node.attrs.id, pos, step: node.attrs.step }
            nodeSteps.push(nodeStep)
            steps.push([node.attrs.id, pos])
        }
        // We want to only visit top-level nodes
        return false
    })
    return nodeSteps
}

function syncDocToWorkflow(workflow: Workflow, steps: NodeStep[]) {
    if (steps.length == 0) {
        workflow.clear()
        return
    }

    const wfSteps = steps.map(s => structuredClone(s.step))
    const wfDef = {
        startAt: wfSteps[0].key,
        steps: {}
    } as IWorkflowDefinition

    wfSteps.forEach(s => {wfDef.steps[s.key] = s; s.next = undefined})
    wfSteps.at(-1)!.end = true
    for (let i = 0; i < wfSteps.length -1; i++) {
        wfSteps[i].next = wfSteps[i+1].key
    }

    workflow.updateFromDefinition(wfDef)
}

function syncWorkflowToDocTx(node: EditorState, change: WorkflowChange) {
    let tr = node.tr
    tr.setMeta(wfSyncKey, {})
    const steps = extractSteps(node.doc)
    change.steps.forEach(s => {
        const nodeStep = steps.find(n => n.id == s.id)
        if (!nodeStep) return
        tr = tr.setNodeAttribute(nodeStep.pos, 'step', s.toDefinition())
    })
    return tr
}