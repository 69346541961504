import { useContext } from "react"
import { StepWiseEditorContext } from "../context"
import { BoldButton } from "./BoldButton"
import { observer } from "mobx-react-lite"
import { Icon, IconBold } from "@tabler/icons-react"
import { StepWiseEditor } from "@/stores/StepWiseEditor"
import { SwTooltip, SwTooltipTrigger } from "@/components/Tooltip/Tooltip"
import { Placement } from "react-aria"


export interface ControlBaseProps {
    onChange(editor?: StepWiseEditor): void
    isActive(editor?: StepWiseEditor): boolean
    isDisabled?: (editor?: StepWiseEditor) => boolean
    icon: Icon
    label: string
    tooltipPlacement?: Placement
}

export const ControlBase = observer(({icon, label, tooltipPlacement: toolTipPlacement = "top", isActive, isDisabled, onChange}: ControlBaseProps) => {
    const editor = useContext(StepWiseEditorContext)

    const active = isActive(editor || undefined)

    return (
        <SwTooltipTrigger>
            <BoldButton
                onChange={() => onChange(editor || undefined)}
                isActive={active}
                icon={icon}
                isDisabled={isDisabled?.(editor || undefined) || false}
            />
            <SwTooltip placement={toolTipPlacement}>
                {label}
            </SwTooltip>
        </SwTooltipTrigger>)
})