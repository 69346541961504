import * as coreClient from "@azure/core-client";

export const AuthInfoDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AuthInfoDto",
    modelProperties: {
      methods: {
        serializedName: "methods",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AuthMethodDto",
            },
          },
        },
      },
    },
  },
};

export const AuthMethodDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AuthMethodDto",
    modelProperties: {
      provider: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "provider",
        required: true,
        type: {
          name: "String",
        },
      },
      entry: {
        serializedName: "entry",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const AuthLoginDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AuthLoginDto",
    modelProperties: {
      userName: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "userName",
        required: true,
        type: {
          name: "String",
        },
      },
      password: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "password",
        required: true,
        type: {
          name: "String",
        },
      },
      rememberMe: {
        serializedName: "rememberMe",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const UserDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "UserDto",
    modelProperties: {
      orgId: {
        serializedName: "orgId",
        required: true,
        type: {
          name: "Number",
        },
      },
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      userName: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "userName",
        required: true,
        type: {
          name: "String",
        },
      },
      avatarUrl: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "avatarUrl",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ErrorDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ErrorDto",
    modelProperties: {
      message: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "message",
        required: true,
        type: {
          name: "String",
        },
      },
      code: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "code",
        required: true,
        type: {
          name: "String",
        },
      },
      errors: {
        serializedName: "errors",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ValidationErrorDto",
            },
          },
        },
      },
    },
  },
};

export const ValidationErrorDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ValidationErrorDto",
    modelProperties: {
      property: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "property",
        required: true,
        type: {
          name: "String",
        },
      },
      message: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "message",
        required: true,
        type: {
          name: "String",
        },
      },
      code: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "code",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const AuthRegisterDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AuthRegisterDto",
    modelProperties: {
      email: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "email",
        required: true,
        type: {
          name: "String",
        },
      },
      password: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "password",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const DocMaterialDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "DocMaterialDto",
    modelProperties: {
      version: {
        serializedName: "version",
        required: true,
        type: {
          name: "Number",
        },
      },
      baseDoc: {
        serializedName: "baseDoc",
        required: true,
        type: {
          name: "any",
        },
      },
      commits: {
        serializedName: "commits",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ICommit",
            },
          },
        },
      },
    },
  },
};

export const ICommit: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ICommit",
    modelProperties: {
      version: {
        serializedName: "version",
        required: true,
        type: {
          name: "Number",
        },
      },
      ref: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "ref",
        required: true,
        type: {
          name: "String",
        },
      },
      steps: {
        serializedName: "steps",
        required: true,
        type: {
          name: "any",
        },
      },
    },
  },
};

export const FileInfoDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "FileInfoDto",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      mediaType: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "mediaType",
        required: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      size: {
        serializedName: "size",
        required: true,
        type: {
          name: "Number",
        },
      },
      url: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "url",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const WorkflowDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowDto",
    modelProperties: {
      ref: {
        serializedName: "ref",
        type: {
          name: "Composite",
          className: "WorkflowRef",
        },
      },
      orgId: {
        serializedName: "orgId",
        required: true,
        type: {
          name: "Number",
        },
      },
      name: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      definition: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "definition",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const WorkflowRef: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowRef",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      revision: {
        serializedName: "revision",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const WorkflowCreateDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkflowCreateDto",
    modelProperties: {
      name: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      definition: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "definition",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ExecutionDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ExecutionDto",
    modelProperties: {
      orgId: {
        serializedName: "orgId",
        required: true,
        type: {
          name: "Number",
        },
      },
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      status: {
        serializedName: "status",
        required: true,
        type: {
          name: "String",
        },
      },
      author: {
        serializedName: "author",
        type: {
          name: "Composite",
          className: "AuthorDto",
        },
      },
      workflow: {
        serializedName: "workflow",
        type: {
          name: "Composite",
          className: "WorkflowRef",
        },
      },
      currentStep: {
        serializedName: "currentStep",
        nullable: true,
        type: {
          name: "String",
        },
      },
      steps: {
        serializedName: "steps",
        required: true,
        type: {
          name: "Dictionary",
          value: { type: { name: "Composite", className: "ExecutionStepDto" } },
        },
      },
      breakPoints: {
        serializedName: "breakPoints",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IBreakPoint",
            },
          },
        },
      },
      createdAt: {
        serializedName: "createdAt",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      startedAt: {
        serializedName: "startedAt",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      finishedAt: {
        serializedName: "finishedAt",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
    },
  },
};

export const AuthorDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AuthorDto",
    modelProperties: {
      orgId: {
        serializedName: "orgId",
        required: true,
        type: {
          name: "Number",
        },
      },
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      userName: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "userName",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ExecutionStepDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ExecutionStepDto",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      key: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "key",
        required: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "String",
        },
      },
      orgId: {
        serializedName: "orgId",
        required: true,
        type: {
          name: "Number",
        },
      },
      status: {
        serializedName: "status",
        required: true,
        type: {
          name: "String",
        },
      },
      error: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "error",
        required: true,
        type: {
          name: "String",
        },
      },
      errorCause: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "errorCause",
        required: true,
        type: {
          name: "String",
        },
      },
      inputs: {
        serializedName: "inputs",
        required: true,
        type: {
          name: "Dictionary",
          value: {
            type: { name: "Composite", className: "ExecutionInputDto" },
          },
        },
      },
      end: {
        serializedName: "end",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      submittedAt: {
        serializedName: "submittedAt",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      submitter: {
        serializedName: "submitter",
        type: {
          name: "Composite",
          className: "AuthorDto",
        },
      },
      review: {
        serializedName: "review",
        type: {
          name: "Composite",
          className: "ReviewDto",
        },
      },
      output: {
        serializedName: "output",
        type: {
          name: "Composite",
          className: "JsonNode",
        },
      },
      startedAt: {
        serializedName: "startedAt",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      finishedAt: {
        serializedName: "finishedAt",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
    },
  },
};

export const ExecutionInputDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ExecutionInputDto",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "String",
        },
      },
      key: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "key",
        required: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      isSatisfied: {
        serializedName: "isSatisfied",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      value: {
        serializedName: "value",
        nullable: true,
        type: {
          name: "any",
        },
      },
      users: {
        serializedName: "users",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
      groups: {
        serializedName: "groups",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid",
            },
          },
        },
      },
    },
  },
};

export const ReviewDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ReviewDto",
    modelProperties: {
      approved: {
        serializedName: "approved",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      reviewedAt: {
        serializedName: "reviewedAt",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      reviewer: {
        serializedName: "reviewer",
        type: {
          name: "Composite",
          className: "AuthorDto",
        },
      },
    },
  },
};

export const JsonNode: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "JsonNode",
    modelProperties: {
      options: {
        serializedName: "options",
        type: {
          name: "Composite",
          className: "JsonNodeOptions",
        },
      },
      parent: {
        serializedName: "parent",
        type: {
          name: "Composite",
          className: "JsonNode",
        },
      },
      root: {
        serializedName: "root",
        type: {
          name: "Composite",
          className: "JsonNode",
        },
      },
    },
  },
};

export const JsonNodeOptions: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "JsonNodeOptions",
    modelProperties: {
      propertyNameCaseInsensitive: {
        serializedName: "propertyNameCaseInsensitive",
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const IBreakPoint: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IBreakPoint",
    modelProperties: {
      state: {
        serializedName: "state",
        nullable: true,
        type: {
          name: "String",
        },
      },
      status: {
        serializedName: "status",
        type: {
          name: "String",
        },
      },
    },
  },
};

export const StepActivityDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "StepActivityDto",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      key: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "key",
        required: true,
        type: {
          name: "String",
        },
      },
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "String",
        },
      },
      orgId: {
        serializedName: "orgId",
        required: true,
        type: {
          name: "Number",
        },
      },
      status: {
        serializedName: "status",
        required: true,
        type: {
          name: "String",
        },
      },
      error: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "error",
        required: true,
        type: {
          name: "String",
        },
      },
      errorCause: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "errorCause",
        required: true,
        type: {
          name: "String",
        },
      },
      inputs: {
        serializedName: "inputs",
        required: true,
        type: {
          name: "Dictionary",
          value: {
            type: { name: "Composite", className: "ExecutionInputDto" },
          },
        },
      },
      end: {
        serializedName: "end",
        required: true,
        type: {
          name: "Boolean",
        },
      },
      submittedAt: {
        serializedName: "submittedAt",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      submitter: {
        serializedName: "submitter",
        type: {
          name: "Composite",
          className: "AuthorDto",
        },
      },
      review: {
        serializedName: "review",
        type: {
          name: "Composite",
          className: "ReviewDto",
        },
      },
      output: {
        serializedName: "output",
        type: {
          name: "Composite",
          className: "JsonNode",
        },
      },
      startedAt: {
        serializedName: "startedAt",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      finishedAt: {
        serializedName: "finishedAt",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      runbookExecutionId: {
        serializedName: "runbookExecutionId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      runbookName: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "runbookName",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const BulkResponseCreateDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "BulkResponseCreateDto",
    modelProperties: {
      step: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "step",
        required: true,
        type: {
          name: "String",
        },
      },
      values: {
        serializedName: "values",
        nullable: true,
        type: {
          name: "Dictionary",
          value: { type: { name: "Composite", className: "ResponseDto" } },
        },
      },
    },
  },
};

export const ResponseDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ResponseDto",
    modelProperties: {
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "String",
        },
      },
      value: {
        serializedName: "value",
        nullable: true,
        type: {
          name: "any",
        },
      },
    },
  },
};

export const ReviewCreateDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ReviewCreateDto",
    modelProperties: {
      stepKey: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "stepKey",
        required: true,
        type: {
          name: "String",
        },
      },
      decision: {
        serializedName: "decision",
        required: true,
        type: {
          name: "Boolean",
        },
      },
    },
  },
};

export const TaskDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TaskDto",
    modelProperties: {
      name: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      propSchema: {
        serializedName: "propSchema",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const ExecutionAttachmentDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ExecutionAttachmentDto",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      orgId: {
        serializedName: "orgId",
        required: true,
        type: {
          name: "Number",
        },
      },
      authorId: {
        serializedName: "authorId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      file: {
        serializedName: "file",
        type: {
          name: "Composite",
          className: "FileInfoDto",
        },
      },
      createdAt: {
        serializedName: "createdAt",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      updatedAt: {
        serializedName: "updatedAt",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      executionId: {
        serializedName: "executionId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      stepId: {
        serializedName: "stepId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const ExecutionReportDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ExecutionReportDto",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid",
        },
      },
      status: {
        serializedName: "status",
        type: {
          name: "String",
        },
      },
      startedAt: {
        serializedName: "startedAt",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      finishedAt: {
        serializedName: "finishedAt",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      steps: {
        serializedName: "steps",
        nullable: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "StepReportDto",
            },
          },
        },
      },
    },
  },
};

export const StepReportDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "StepReportDto",
    modelProperties: {
      key: {
        serializedName: "key",
        nullable: true,
        type: {
          name: "String",
        },
      },
      status: {
        serializedName: "status",
        type: {
          name: "String",
        },
      },
      startedAt: {
        serializedName: "startedAt",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      finishedAt: {
        serializedName: "finishedAt",
        nullable: true,
        type: {
          name: "DateTime",
        },
      },
      duration: {
        serializedName: "duration",
        nullable: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const OrgDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "OrgDto",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Number",
        },
      },
      name: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      key: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "key",
        required: true,
        type: {
          name: "String",
        },
      },
      emailDomain: {
        serializedName: "emailDomain",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const OrgStatusDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "OrgStatusDto",
    modelProperties: {
      completedExecutions: {
        serializedName: "completedExecutions",
        required: true,
        type: {
          name: "Number",
        },
      },
      runningExecutions: {
        serializedName: "runningExecutions",
        required: true,
        type: {
          name: "Number",
        },
      },
      pendingPromps: {
        serializedName: "pendingPromps",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const RunbookDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "RunbookDto",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      parentId: {
        serializedName: "parentId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      name: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      orgId: {
        serializedName: "orgId",
        required: true,
        type: {
          name: "Number",
        },
      },
      workspaceId: {
        serializedName: "workspaceId",
        required: true,
        type: {
          name: "Number",
        },
      },
      rank: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "rank",
        required: true,
        type: {
          name: "String",
        },
      },
      docId: {
        serializedName: "docId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      workflowRef: {
        serializedName: "workflowRef",
        type: {
          name: "Composite",
          className: "WorkflowRef",
        },
      },
    },
  },
};

export const RunbookCreateDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "RunbookCreateDto",
    modelProperties: {
      name: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      parentId: {
        serializedName: "parentId",
        nullable: true,
        type: {
          name: "Uuid",
        },
      },
    },
  },
};

export const RunbookUpdateDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "RunbookUpdateDto",
    modelProperties: {
      name: {
        serializedName: "name",
        nullable: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const RunbookMoveDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "RunbookMoveDto",
    modelProperties: {
      targetId: {
        serializedName: "targetId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      operation: {
        serializedName: "operation",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const RunbookExecutionDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "RunbookExecutionDto",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      orgId: {
        serializedName: "orgId",
        required: true,
        type: {
          name: "Number",
        },
      },
      runbookId: {
        serializedName: "runbookId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      author: {
        serializedName: "author",
        type: {
          name: "Composite",
          className: "AuthorDto",
        },
      },
      docId: {
        serializedName: "docId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      docVersion: {
        serializedName: "docVersion",
        required: true,
        type: {
          name: "Number",
        },
      },
      workflowRef: {
        serializedName: "workflowRef",
        type: {
          name: "Composite",
          className: "WorkflowRef",
        },
      },
      executionId: {
        serializedName: "executionId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      executionStatus: {
        serializedName: "executionStatus",
        required: true,
        type: {
          name: "String",
        },
      },
      createdAt: {
        serializedName: "createdAt",
        required: true,
        type: {
          name: "DateTime",
        },
      },
      updatedAt: {
        serializedName: "updatedAt",
        required: true,
        type: {
          name: "DateTime",
        },
      },
    },
  },
};

export const RunbookFtsDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "RunbookFtsDto",
    modelProperties: {
      orgId: {
        serializedName: "orgId",
        required: true,
        type: {
          name: "Number",
        },
      },
      workspaceId: {
        serializedName: "workspaceId",
        required: true,
        type: {
          name: "Number",
        },
      },
      name: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      runbookId: {
        serializedName: "runbookId",
        required: true,
        type: {
          name: "Uuid",
        },
      },
      text: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "text",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const WorkspaceDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkspaceDto",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Number",
        },
      },
      name: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
      orgId: {
        serializedName: "orgId",
        required: true,
        type: {
          name: "Number",
        },
      },
    },
  },
};

export const WorkspaceCreateDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "WorkspaceCreateDto",
    modelProperties: {
      name: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};

export const FileCreateDto: coreClient.CompositeMapper = {
  type: {
    name: "Composite",
    className: "FileCreateDto",
    modelProperties: {
      contentLength: {
        serializedName: "contentLength",
        required: true,
        type: {
          name: "Number",
        },
      },
      mediaType: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "mediaType",
        required: true,
        type: {
          name: "String",
        },
      },
      name: {
        constraints: {
          MinLength: 1,
        },
        serializedName: "name",
        required: true,
        type: {
          name: "String",
        },
      },
    },
  },
};
