import { action, autorun, computed, makeAutoObservable, observable } from "mobx"
import { createUseStore } from "./util"


export enum Theme {
    dark = "dark",
    light = "light"
}

export enum UserPreference {
    system = "system",
    dark = 'dark',
    light = 'light'
}

const USER_PREFERENCES_KEY = 'sw/theme/preferred-color-scheme'

export class ThemeStore {
    @observable private accessor userPreference!: UserPreference
    @observable private accessor systemPreference!: Theme

    themeMediaQuery: MediaQueryList

    constructor() {
        this.loadConfig()

        this.themeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
        this.handleSystemChange(this.themeMediaQuery)
        this.themeMediaQuery.addEventListener('change', this.handleSystemChange)

        autorun(() => {
            if (this.theme == Theme.dark)
                document.documentElement.classList.add('dark')
            else {
                document.documentElement.classList.remove('dark')
                document.documentElement.style.backgroundColor = 'unset'
            }

        })
    }

    @computed get theme(): Theme {
        if (this.userPreference === UserPreference.system)
            return this.systemPreference
        else
            return Theme[this.userPreference]
    }

    @action setUserPreference(theme: UserPreference) {
        this.userPreference = theme
        this.saveConfig()
    }

    @action.bound handleSystemChange(e: MediaQueryListEvent | MediaQueryList) {
        this.systemPreference = e.matches ? Theme.dark : Theme.light
    }

    private loadConfig() {
        const preference = localStorage.getItem(USER_PREFERENCES_KEY) as keyof typeof UserPreference
        this.userPreference = preference ? UserPreference[preference] : UserPreference.system
    }

    private saveConfig() {
        localStorage.setItem(
            USER_PREFERENCES_KEY,
            this.userPreference?.valueOf() ?? UserPreference.system.valueOf())
    }
}
export const [ThemeCtx, useThemeCtx] = createUseStore(ThemeStore)