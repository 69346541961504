import { StepWise } from "@/client"
import type { JSONSchema7 } from "json-schema"
import { makeAutoObservable } from "mobx"
import { createUseStore } from "./util"


export class TaskStore {
    tasks: Task[] = []

    constructor(readonly sw: StepWise) {
        makeAutoObservable(this)
    }

    async load(orgId: number) {
        const tasks = await this.sw.taskList(orgId)
        this.add(...tasks)
    }

    add(...tasks: ITask[]) {
        this.tasks = tasks.map(t => new Task(t))
    }
}

export interface ITask {
    name: string
    propSchema?: any
}

export class Task {
    name!: string
    inputSchema?: JSONSchema7

    constructor(spec: ITask) {
        makeAutoObservable(this)
        this.fromSpec(spec)
    }

    fromSpec(spec: ITask) {
        console.log(spec)
        this.name = spec.name
        this.inputSchema = JSON.parse(spec.propSchema)! as JSONSchema7
        this.inputSchema.title = "Parameters"
    }
}
export const [TaskContext, useTaskStore] = createUseStore(TaskStore)