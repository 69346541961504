import { createRoot } from 'react-dom/client'
import './main.css'
import '@/components/styles/global.css'

import { StepWise } from './client/index'
import { RootStore } from './stores/Root'
import { RootContext, WorkspaceContext, OrgContext } from './contexts'
import { OrgCtx } from './stores/OrgCtx'

import { Wrapper } from './screens/Wrapper'


import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom"

import { RoutePath } from './routes'
import { LoginScreen } from './screens/Login'
import { RegistrationScreen } from './screens/auth/Registration'
import { ConfirmEmailScreen } from './screens/auth/ConfirmEmail'
import { ForgotPasswordScreen } from './screens/auth/ForgotPassword'
import { ResetPasswordScreen } from './screens/auth/ResetPassword'
import { IconForms, IconSettingsSearch, IconSun } from '@tabler/icons-react'
import { ThemePicker } from './components/widgets/utility/ThemePicker'
import { autorun } from 'mobx'
import { StepWiseClient } from './StepWise'
import { Activity } from './components/widgets/utility/Activity'

const router = createBrowserRouter([
    {
        path: RoutePath.AuthLogin,
        element: (<LoginScreen/>)
    },
    {
        path: RoutePath.AuthRegistration,
        element: (<RegistrationScreen/>)
    },
    {
        path: RoutePath.AuthConfirmEmail,
        element: (<ConfirmEmailScreen/>)
    },
    { path: RoutePath.AuthForgotPassword, element: (<ForgotPasswordScreen/>)},
    { path: RoutePath.AuthResetPassword, element: (<ResetPasswordScreen/>)},
    {
        path: "/",
        element: (<Wrapper/>)
    },
    {
        path: RoutePath.EditRunbook,
        element: <Wrapper routePath={RoutePath.EditRunbook}/>
    },
    {
        path: RoutePath.Execution,
        element: <Wrapper routePath={RoutePath.Execution}/>
    },
    {
        path: RoutePath.WorkspaceActivity,
        element: <Wrapper routePath={RoutePath.WorkspaceActivity}/>
    },
])

router.subscribe((state) => console.log('Router', state))

const client = new StepWiseClient(location.origin, 0, {allowInsecureConnection: true})
const rootStore = new RootStore(client, router)
autorun(() => {
    client.xSwOrgId = rootStore.orgCtx.orgId ?? 0
})

rootStore.auth.onDidLogout(({detail: evt}) => {
    router.navigate({
        pathname: RoutePath.AuthLogin,
        search: new URLSearchParams({returnUrl: evt.returnUrl ?? "/"}).toString()
    })
})
rootStore.init()
rootStore.utilityBar.addItems([
    {
        type: 'widget',
        id: 'Hello',
        visible: true,
        label: 'Foo',
        group: 'left',
        icon: IconSun,
        widget: <ThemePicker/>
    },
    {
        type: 'widget',
        id: 'Activity',
        visible: true,
        label: 'Foo',
        group: 'right',
        icon: IconForms,
        widget: <Activity/>
    }
])

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
    // <React.StrictMode>
    <RootContext.Provider value={rootStore}>
    <OrgContext.Provider value={rootStore.orgCtx}>
    <WorkspaceContext.Provider value={rootStore.workspaceCtx}>
      <RouterProvider router={router}/>
      {/* <TanRouterProvider router={tanRouter} /> */}
    </WorkspaceContext.Provider>
    </OrgContext.Provider>
    </RootContext.Provider>
    // </React.StrictMode>
)


// ReactDOM.render(
//   <React.StrictMode>
//     <MantineProvider withGlobalStyles withNormalizeCSS>
//     <RootContext.Provider value={root}>
//       <Shell />
//     </RootContext.Provider>
//     </MantineProvider>
//   </React.StrictMode>,
//   document.getElementById('root')
// )
