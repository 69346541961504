import { useWorkspaceCtx } from "@/contexts"
import { observer } from "mobx-react-lite"
import { SwButton } from "../../../../components"
import { SwIcon } from "../../../../components/Icon/Icon"
import { IconPlus } from "@tabler/icons-react"


export const CreateStep = observer(() => {
    const ctx = useWorkspaceCtx()
    const {editor} = ctx

    return <div className="flex flex-col items-center mt-16">
        <div className="flex flex-col items-center mt-16 text-center w-[300px]">
            <p>No Steps Have been Created</p>
            <p>Highlight documentation and click to create step.</p>
            <SwButton onPress={() => editor?.tipTap.commands.toggleSwStep()}><SwIcon icon={IconPlus}/>Create Step</SwButton>
        </div>
    </div>
})