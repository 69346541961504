import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import humanizeDuration from "humanize-duration"
import { observer } from "mobx-react-lite"
import { IconLink } from "@tabler/icons-react"

import { SwButton } from "@/components"
import { SwIcon } from "@/components/Icon/Icon"
import { SwTable } from "@/components/Table/Table"
import { SwText } from "@/components/Text/Text"
import { WorkflowHistory } from "@/components/widgets/charts/workflow/WorkflowHistory"
import { WorkflowMonthly } from "@/components/widgets/charts/workflow/WorkflowMonthly"
import { useWorkspaceCtx } from "@/contexts"
import { useRootStore } from "@/hooks/useStores"
import { AbstractStep, IStep, Step } from "@/stores/WorkflowDefinition"

import { Column, Row, TableBody, TableHeader, Cell } from "react-aria-components"



export const ActivityContent = () =>
    <div className="flex bg-sky-50 h-full w-full gap-4 pt-6 px-4">
        <div className="w-48 h-full grow-0 shrink-0 border border-red-500">Activity</div>
        <div className="flex flex-col grow gap-4">
            <Top/>
            <div className="flex gap-4 h-48">
                <div className="rounded-md grow bg-white">
                    <WorkflowMonthly/>
                </div>
                <div className="rounded-md grow bg-white">
                    <WorkflowHistory/>
                </div>
            </div>
        </div>
    </div>

const Top = () => {
    return <div className="h-48 rounded-md bg-white">
        <UserRunbookActivityTable/>
    </div>
}


type ActivityInfo = {id: string, step: Step, execId: string, runbookName: string, startedAt?: Date}

export const UserRunbookActivityTable = observer(() => {
    const root = useRootStore()

    const [data, setData] = useState<ActivityInfo[]>([])
    const load = async () => {
        const steps = await root.sw.stepActivityGetForUser()
        setData( steps.map(s => ({id: s.id, step: AbstractStep.FromSpec(s.key, s as IStep), execId: s.runbookExecutionId, runbookName: s.runbookName, startedAt: s.startedAt})) )
    }
    useEffect(() => {load()}, [])

    return (
        <SwTable>
            <TableHeader>
                <Column>Execution</Column>
                <Column>Runbook</Column>
                <Column isRowHeader>Step</Column>
                <Column>Type</Column>
                <Column>Started</Column>
                <Column>Reason</Column>
            </TableHeader>
            <TableBody items={data}>
                { (item) => <ActivityRow info={item}/>}
            </TableBody>
        </SwTable>
    )
})

const ActivityRow = observer( ({info}: {info: ActivityInfo}) => {
    const {step, execId, runbookName, startedAt} = info
    const workspaceCtx = useWorkspaceCtx()
    const navigate = useNavigate()

    const isActive = workspaceCtx.activeRunbookExecution?.id == execId

    return <Row key={step.id}>
        <Cell><SwButton style={isActive ? 'fill' : 'outline'} onPress={() => {
            workspaceCtx.loadActiveRunbookExecution(execId)
            navigate(`/${workspaceCtx.orgCtx.orgId}/1/runbook-execution/${execId}`)
        }}><SwText>#{execId.split('-')[0]}</SwText><SwIcon icon={IconLink}/></SwButton></Cell>
        <Cell>{runbookName}</Cell>
        <Cell>{step.displayName}</Cell>
        <Cell>{step.displayType}</Cell>
        <Cell>{humanizeDuration(Date.now() - startedAt!.getTime() ?? 0, {largest: 2})} ago</Cell>
        <Cell>Requires your approval.</Cell>
    </Row>
})