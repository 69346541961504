import { LoginWidget } from "@/widgets/Login"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Logo } from "@/components/Logo"
import { IconBulbFilled } from "@tabler/icons-react"
import { Link, RouterLink } from "@/components/Link"
import { GoogleLogo } from "@/components/icons/GoogleLogo"
import { SwIcon } from "@/components/Icon/Icon"
import { SwButton } from "@/components"
import { SwText } from "@/components/Text/Text"
import { useEffect, useState } from "react"
import { AuthInfoDto } from "@/client"
import { useRootStore } from "@/hooks/useStores"


export const LoginScreen = () => {
    const [params, _] = useSearchParams()
    const navigate = useNavigate()
    const root = useRootStore()
    const checkemail = params.get('checkemail')

    const [auth, setAuth] = useState<Nullable<AuthInfoDto>>(null)

    const getAuthMethods = async () => {
        const authInfo = await root.sw.authGet()
        setAuth(authInfo)
    }

    useEffect(() => {
        getAuthMethods()
    }, [])

    const continueTo = () => {
        const returnUrl = params.get('returnUrl')
        if (returnUrl) {
            navigate(returnUrl)
        } else {
            navigate('/')
        }
    }

    const googleAuth = auth?.methods.find(m => m.provider == 'Google')

    return (

        <div className="flex items-center px-3 w-screen h-screen justify-around radial-top-right  bg-gradient-to-r from-indigo-500/60 via-purple-400/60 to-pink-400/60 dark:from-indigo-900 dark:via-purple-900 dark:to-pink-900">
        <div className="max-w-6xl mx-auto grid grid-cols-12 gap-12 bg-white dark:bg-zinc-900 rounded-xl p-12 shadow-xl">
            <div className="col-span-6 p-8 flex items-center">
                <div>
                    {checkemail ? (
                        <div className="bg-green-100 p-2 rounded px-2 text-green-700 text-center font-bold mb-6">
                                <p>Check your Email for Confirmation!</p>
                        </div>

                    ) : <></>}
                    <Logo />
                    <div className="my-6 text-[2rem] font-medium text-indigo-800 dark:text-indigo-500 leading-6">Login</div>
                    <div className="mt-6 mb-6 text-gray-600 dark:text-zinc-100"><span className="text-black bg-yellow-300 py-1 px-2 font-bold">Log In</span> to your account or <Link className="underline"><RouterLink to="/auth/register">Register</RouterLink></Link> for a new one.</div>
                    {googleAuth && <form action={googleAuth.entry!} method="POST">
                        <SwButton type="submit"><SwIcon icon={GoogleLogo}/><SwText>Continue with Google</SwText></SwButton>
                    </form>}
                    <div className="my-4">or</div>
                    <div className="mt-4 w-72">
                        <LoginWidget onLogin={continueTo}/>
                    </div>
                </div>

            </div>
            <div className="col-span-6 flex items-center">
                <div className="p-6 bg-[rgb(238,242,255,0.5)] rounded-lg">
                    <img src="/images/svg/login.svg" className="w-9/12 mx-auto"></img>

                    <div className="bg-indigo-100 dark:bg-[rgb(238,242,255,0.3)] p-4 rounded-lg flex items-start">
                        <IconBulbFilled className="text-indigo-600 mr-3 w-24"/>
                        <div>
                        <div className="text-sm font-bold text-indigo-900 tracking-loose">ProTip: Did you know that most automated processes lack proper documentation?</div>
                        <div className="text-sm mt-3 text-indigo-900">StepWise has been meticulously crafted with a "Documentation First" approach, ensuring that your processes are always contextualized and devoid of any ambiguity.</div>
                        </div>


                    </div>
                </div>

            </div>
        </div>
        </div>
    )
}