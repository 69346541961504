/** Still used by action and toggle buttons */
export const BaseButtonStyles = {
    outline: 'outline-none focus:outline-none',
    border: {
        loud: 'border border-gray-800 dark:border-gray-600 rounded',
        quiet: 'border border-transparent rounded',
    },
    size: {
        xs: 'text-xs py-0.2',
        sm: 'text-xs py-0.5',
        md: 'text-xs h-8 min-w-8',
        lg: 'text-base px-3 py-1',
    },
    main: 'inline-flex max-w-fit items-center text-gray-900 font-medium text-center dark:text-gray-400 relative',
    hover: 'hover:text-white hover:bg-gray-900 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600',
    focus: 'focus:ring-gray-300 dark:focus:ring-gray-800 focus:ring-4',
    variant: {
        primary: '',
        secondary: '',
        icon: 'border-transparent',
        link: 'border-none !p-0 hover:underline hover:!text-black hover:!bg-white'
    },
}