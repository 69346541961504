import { useSlotProps } from "@react-spectrum/utils"
import { ElementType, HTMLAttributes, ReactNode } from "react"

export interface TextProps extends HTMLAttributes<HTMLElement> {
    children?: ReactNode
}

export const SwText: React.FC<TextProps> = ({children, ...otherProps}) => {
    const props = useSlotProps(otherProps, 'text')
    return <span {...props}>{children}</span>
}