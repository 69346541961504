import { observer } from "mobx-react-lite"
import { PropsWithChildren, useContext } from "react"

import { SwPanel } from "@/components/Panel/Panel"
import { WorkspaceContext, useWorkspaceCtx } from "@/contexts"
import { RunbookView, WorkspaceMode } from "@/stores/WorkspaceCtx"
import { Editor } from "@/widgets/Editor"
import { Designer } from "@/widgets/Designer"

import { ExecutionStatusBar } from "../ExecutionStatusBar"
import { ContextBar } from './ContextBar'
import { LeftPanel } from "./leftPanel/LeftPanel"
import { RightPanel } from "./rightPanel/RightPanel"

import './RunbookContent.css'
import { Insights } from "./Insights"
import { Summary } from "./Summary"
import { useDocStore } from "@/stores/DocStore"

export const RunbookContent = observer(() => {
    const workspaceCtx = useContext(WorkspaceContext)!

    if (workspaceCtx.mode == WorkspaceMode.Write) return <>
        <ContextBar/>
        <div  style={{display: 'flex', flexDirection: 'row', flexGrow: 1, minHeight: 0}}>
            <RunbookLeftContent/>
            <RunbookCenterPanel>
                <RunbookCenterContent/>
            </RunbookCenterPanel>
            <RunbookRightContent/>
        </div>
    </>

    else return (
        <div  style={{display: 'flex', flexDirection: 'row', flexGrow: 1, minHeight: 0}}>
            <RunbookLeftContent/>
            <RunbookCenterPanel>
                <ContextBar/>
                <RunbookCenterContent/>
            </RunbookCenterPanel>
            <RunbookRightContent/>
        </div>
    )
})
RunbookContent.displayName = 'MainContent'

const RunbookLeftContent = observer(() => {
    return (
        <div
            className="bg-neutral-100 lg:w-[200px] xl:w-[250px] 2xl:w-[300px] flex-none dark:bg-zinc-900 border-r border-gray-300 dark:border-zinc-700"
        >
            <LeftPanel/>
        </div>
    )
})

const RunbookCenterPanel = observer(({children}: PropsWithChildren) => {
    return (
        <div
            id="main-content-center"
            className="h-full"
            style={{width: '100%', display: 'flex', flexDirection: 'column', minHeight: 0, flexGrow: 1, overflow: 'hidden', minWidth: '600px', flexBasis: 0}}
        >
            {children}
        </div>
    )
})

const RunbookCenterContent = observer(() => {
    const workspaceCtx = useWorkspaceCtx()
    const docs = useDocStore()
    const workflow = workspaceCtx.activeWorkflow
    const activeDoc = workspaceCtx?.activeDoc

    return <>
        {workspaceCtx.mode == WorkspaceMode.Execute && workspaceCtx?.activeWorkflow?.state && <>
            <div className="shrink-0 min-h-0 flex flex-col">
                <ExecutionStatusBar execution={workspaceCtx.activeWorkflow!} />
            </div>
        </>}
        <div className="h-full min-h-0">
            {workspaceCtx.view == RunbookView.Runbook && <Editor doc={activeDoc ? docs.getByRef(activeDoc) : null}/>}
            {workflow && workspaceCtx.view == RunbookView.Workflow && <Designer workflow={workflow} />}
            {workspaceCtx.view == RunbookView.Insights && <Insights/>}
            {workspaceCtx.view == RunbookView.Summary && <Summary/>}
        </div>
    </>
})

const RunbookRightContent = observer(() => {
    return (
        <SwPanel
            id="main-panel-right"
            className="
                grow-1
                shrink-1
                min-w-[300px]
                dark:bg-zinc-900
                border-l
                border-gray-300
                bg-neutral-100
                dark:border-zinc-700"
            initX={400}
            axis="left"
        >
            <RightPanel/>
        </SwPanel>
    )
})