import { SwButton } from "@/components/Button"
import { Icon } from "@/components/Icon"
import { SwPopover } from "@/components/Popover/Popover"
import { SplitGroup } from "@/components/SplitGroup/SplitGroup"
import { useUtilityBar, UtilityActionItem, UtilityWidgetItem } from "@/stores/UtilityBar"
import { IconBell, IconDots } from "@tabler/icons-react"
import { observer } from "mobx-react-lite"
import { Dialog, DialogTrigger } from "react-aria-components"


export const UtilityBar = observer(() => {
    return (
        <div className='bg-gradient-to-r from-indigo-500/10 via-purple-500/10 to-pink-500/10 dark:bg-zinc-900'>
            <div className="h-[1px] w-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
            </div>
            <div className="px-3" style={{ height: '24px', flexGrow: 0, flexShrink: 0, display: 'flex', alignItems: 'center'}}>
                <SplitGroup>
                    <div className="flex items-center">
                        <BarGroup group="left"/>
                    </div>
                    <div className="flex items-center">
                        <BarGroup group="right"/>
                    </div>
                </SplitGroup>
            </div>
        </div>
    )
})


const BarGroup = observer(({group}: {group: string}) => {
    const utilityBar = useUtilityBar()

    return <>
        {utilityBar.groupItems(group).map((item, idx) =>
        'action' in item ?
        <UtilityBarAction key={idx} item={item} /> :
        'widget' in item ?
        <UtilityBarWidget key={idx} item={item} /> : <></>)}
    </>
})

const UtilityBarAction = observer( ({item}: {item: UtilityActionItem}) => <>
    <SwButton variant="link" size="xs" onPress={item.action} >
        <IconBell size='16px' className='text-gray-950 dark:stroke-zinc-100 stroke-2' strokeWidth='0.1em'/>
    </SwButton>
</>)

const UtilityBarWidget = observer( ({item}: {item: UtilityWidgetItem}) => <>
    <DialogTrigger>
    <SwButton variant="link" size="xs" >
        <Icon icon={item.icon || IconDots} size='16px' className='text-gray-950 dark:stroke-zinc-100 stroke-2' strokeWidth='0.1em' />
    </SwButton>
    <SwPopover>
        <Dialog>
            {item.widget}
        </Dialog>
    </SwPopover>
    </DialogTrigger>
</>)