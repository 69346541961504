import { useContext } from "react"
import { observer } from "mobx-react-lite"

import { SwTabs as AriaTabs, SwTab, SwTabList as AriaTabList, SwTabPanel } from "@/components/Tabs/Tabs"
import { SwButton } from "@/components"
import { SwTooltip, SwTooltipTrigger } from "@/components/Tooltip/Tooltip"
import { OrgContext, useWorkspaceCtx } from "@/contexts"
import { RunbookList } from "@/components/widgets/RunbookList/RunbookList"
import { WorkflowStepList } from "./WorkflowStepList"
import { IconBook2, IconListDetails, IconPlus } from "@tabler/icons-react"
import { SwIcon } from "@/components/Icon/Icon"
import { WorkspaceMode } from '@/stores/WorkspaceCtx'


export const LeftPanel = observer(() => {
    const workspaceCtx = useWorkspaceCtx()
    const orgCtx = useContext(OrgContext)!

    if (workspaceCtx.mode == WorkspaceMode.Write)
        return (
            <WorkflowStepList/>
        )
    else return (
        <div className="flex flex-col h-full">
            <div className=" p-2 px-3 flex flex-row justify-between items-center">
                <div className="tracking-wide text-3xl font-medium dark:text-zinc-200">Runbooks</div>
                <SwTooltipTrigger>
                    <SwButton variant="link" onPress={() => {
                        workspaceCtx.createRunbook()
                    }}>
                        <div className="w-8 h-8 rounded-full bg-indigo-600 text-white flex items-center justify-center">
                            <IconPlus size="18"/>
                        </div>

                    </SwButton>
                    <SwTooltip placement="bottom">Create Runbook</SwTooltip>
                </SwTooltipTrigger>
            </div>
            <RunbookList/>
        </div>
    )




})

function tabsToInclude(runbookSelected: boolean) {
    const tabs = [
        <SwTab id="RbL" variant="compact">
            <SwIcon icon={IconBook2}/>
        </SwTab>
    ]

    runbookSelected && tabs.push(
        <SwTab id="WfS" variant="compact">
            <SwIcon icon={IconListDetails} />
        </SwTab>
    )

    return tabs
}