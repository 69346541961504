import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import { Component } from './Component'

export const EmbedExt = Node.create({
    name: 'swEmbed',

    group: 'top',

    atom: true,

    draggable: true,

    addAttributes: () => ({
        src: {},
    }),

    addCommands() {
        return {
            setEmbed: options => ({ commands }) => {
                return commands.insertContent({
                    type: this.name,
                    attrs: options,
                })
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: 'sw-embed',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['sw-embed', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return ReactNodeViewRenderer(Component, {className: 'my-4'})
    },
})