import { SwButton } from "@/components/Button"
import { Icon } from "@/components/Icon"
import { SwMenu, SwMenuItem, SwMenuTrigger } from "@/components/Menu/Menu"
import { Modal } from "@/components/Modal"
import { SwPopover } from "@/components/Popover/Popover"
import { SwText } from "@/components/Text/Text"
import { OrgContext } from "@/contexts"
import { useAuthStore } from "@/hooks/useStores"
import { LoginWidget } from "@/widgets/Login"
import { IconLogin, IconLogout } from "@tabler/icons-react"
import { observer } from "mobx-react-lite"
import { Key, useContext, useState } from "react"
import { Dialog, Heading, Separator } from "react-aria-components"
import {LogoIcon} from "@/components/Logo/LogoIcon"
import { RunbookSearchBar } from "@/components/widgets/RunbookSearch"
import { SwToggleButton } from "@/components"
import { useAppStore } from "@/stores/AppStore"
import { useOrgCtx } from "@/stores/OrgStore"
import { SwSelectItem, SwSelect } from "@/components/Select/Select"
import { useNavigate } from "react-router-dom"
import { UserAvatar } from "@/components/widgets/user/UserAvatar"


export const Header = observer(() => {
    const orgCtx = useContext(OrgContext)!
    const orgs = useOrgCtx()
    const app = useAppStore()

    return (
                <div className="h-12 bg-neutral-100 flex-shrink-0 px-3 border-b border-gray-300 dark:border-zinc-700 dark:bg-zinc-900  dark:text-white">
                        <div className="flex justify-between items-center h-full">
                            <div className='flex items-center text-2xl font-extrabold space-x-2 block flex-none'>
                                <LogoIcon/>
                                <OrgMenu/>
                            </div>
                            <div className="flex items-center shrink-0 h-full flex-grow px-16 max-w-[800px]">
                                <RunbookSearchBar/>
                            </div>
                            <div className="flex gap-2 items-center ">
                                <SwToggleButton isQuiet isSelected={app.screen == 'runbook'} onPress={() => app.navigateToRunbooks()}>Runbooks</SwToggleButton>
                                {/* <SwToggleButton isQuiet isSelected={app.screen == 'activity'} onPress={() => app.navigateToActivity()}>Activity</SwToggleButton> */}
                                {/* <SwToggleButton isQuiet isDisabled>Vault</SwToggleButton>
                                <SwToggleButton isQuiet isDisabled>Integrations</SwToggleButton> */}
                                {/* <SwToggleButton isQuiet isDisabled>Settings</SwToggleButton> */}
                                {/* <AriaTextField aria-label="Search" icon={IconSearch} placeholder="Search"/> */}
                                <UserMenu/>
                                        {/* <Dialog>
                                        {({close}) =>
                                            <div>
                                                <LoginWidget onLogin={() => {console.log('Submit'); close();}} />
                                            </div>
                                        }
                                    </Dialog> */}
                            </div>
                        </div>
                </div>
    )
})
Header.displayName = 'Header'

const UserMenu = observer(() => {
    const auth = useAuthStore()
    const [isLoginOpen, setIsLoginOpen] = useState(false)

    const handleSelect = (id: Key) => {
        if (id == 'login') setIsLoginOpen(true)
        if (id == 'logout') auth.logout()
    }

    return (<SwMenuTrigger>
        <SwButton variant="icon" ><UserAvatar user={auth.user ?? undefined} /></SwButton>
        <SwPopover>
            <div className="p-2">
                <SwMenu onAction={handleSelect}>
                    <SwMenuItem id="login" >
                        <Icon icon={IconLogin}/>
                        <SwText slot="label">Login</SwText>
                    </SwMenuItem>
                    <SwMenuItem id="logout" >
                        <Icon icon={IconLogout}/>
                        <SwText slot="label">Logout</SwText>
                    </SwMenuItem>
                </SwMenu>
            </div>
        </SwPopover>
        {isLoginOpen && <LoginModal isOpen={isLoginOpen} close={() => setIsLoginOpen(false)}/>}
    </SwMenuTrigger>)
})

const OrgMenu = observer(() => {
    const navigate = useNavigate()
    const orgCtx = useContext(OrgContext)!
    const orgs = useOrgCtx()

    if (orgs.orgs.length <=1)
        return <span className="uppercase font-medium text-gray-800 dark:text-gray-300 tracking-tightest text-lg">{orgCtx.org?.name ?? ""}</span>
    else
        return (
            <SwSelect
                selectedKey={orgCtx.orgId?.toString()}
                onSelectionChange={(key) => {
                    if (!key) return
                    navigate("/")
                    orgCtx.orgId = Number.parseInt(key.toString())
                }}>
                {orgs.orgs.map(o => <SwSelectItem key={o.id} id={o.id.toString()}>{o.name}</SwSelectItem>)}
            </SwSelect>
        )
})

interface LoginModalProps {
    isOpen: boolean
    close: () => void
}

const LoginModal = observer(({close, isOpen}: LoginModalProps) => {
    return (
        <Modal isOpen={isOpen} isDismissable onOpenChange={close}>
            <Dialog>
                <Heading slot="heading">Login</Heading>
                <Separator slot="separator"/>
                <LoginWidget onLogin={close}/>
            </Dialog>
        </Modal>
    )
})