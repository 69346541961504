import clsx from "clsx"
import { PropsWithChildren, ReactElement } from "react"


interface Props {
    className?: string
    children: [ReactElement, ReactElement, ReactElement, ...ReactElement[]]
}

export const TriGroup = ({children, className}: Props) => {
    return (
    <div className={clsx("flex items-center w-full h-full min-h-0 min-w-0", className)}>
        <TriGroupSection>
            {children[0]}
        </TriGroupSection>
        <TriGroupSection className="justify-center">
            {children[1]}
        </TriGroupSection>
        <TriGroupSection className="justify-end">
            {children[2]}
        </TriGroupSection>
    </div>
    )
}


const TriGroupSection = ({children, className}: PropsWithChildren & {className?: string}) => {
    return (
        <div className={`flex items-center grow-1 h-full w-full ${className}`}>{children}</div>
    )
}