import { SwButton } from "@/components"
import { SwIcon } from "@/components/Icon/Icon"
import { SwText } from "@/components/Text/Text"
import { useWorkflowStore } from "@/stores/Workflow"
import { AbstractStep, TaskStep as WfTaskStep } from "@/stores/WorkflowDefinition"
import { IconBox, IconCheck, IconCheckbox, IconSquare } from "@tabler/icons-react"
import { observer } from "mobx-react-lite"
import { StepName } from "./component/StepName"
import { useWorkspaceCtx } from "@/contexts"
import { WorkspaceMode } from "@/stores/WorkspaceCtx"
import { StepStatusIcon } from "@/components/Workflow/StepInfo"
import { StepIcon } from "@/components/Workflow/StepIcon"

export interface TaskStepProps {
    step: WfTaskStep
}

export const TaskStep = observer(({step}: TaskStepProps) => {
    const workspaceCtx = useWorkspaceCtx()
    const workflows = useWorkflowStore()
    const workflow = workspaceCtx.activeWorkflow

    if(workspaceCtx.mode == WorkspaceMode.Write){
        return (
            <div contentEditable={false} className='bg-slate-200 flex items-center p-2 justify-between'>
                <div className="flex justify-start items-center space-x-1 bg-neutral-50 rounded pr-2">
                    <StepIcon iconType={step.displayType} size="sm"/>
                    <StepName name={step.displayName} />
                </div>
                    <TaskIcon step={step} />
                <SwButton onPress={() => {workflow!.selectedStep = step}} size="sm" variant="secondary">

                <SwText>edit</SwText>

            </SwButton>
            </div>

        )
    } else {
        return (
            <div contentEditable={false} className='bg-slate-200 flex items-center p-2 justify-between rounded-b-md'>
                <div className="flex justify-start items-center space-x-1 bg-neutral-50 rounded pr-2">
                    <StepIcon iconType={step.displayType} size="sm"/>
                    <StepName name={step.displayName} />
                </div>
                {workspaceCtx.mode == WorkspaceMode.Execute && <TaskIcon step={step} /> }
            </div>
        )
    }
})


interface TaskIconProps {
    step: AbstractStep
}

const TaskIcon = observer(({step}: TaskIconProps) => {
    return <StepStatusIcon step={step}/>
})
