import { StepWise } from "@/client"
import { TransferProgressEvent } from "@azure/core-rest-pipeline"
import { makeAutoObservable } from "mobx"

export class UploadStore {
    uploads: IUpload<any>[] = []

    constructor() {
        makeAutoObservable(this)
    }

    async upload(upload: IUpload<any>) {
        this.uploads.push(upload)
        await upload.execute()
    }
}

export enum UploadStatus {
    Created = 'created',
    Uploading = 'uploading',
    Aborted = 'aborted',
    Failed = 'failed',
    Succeeded = 'completed',
}

export interface IUpload<T> {
    key: string
    fileInfo: UploadFileInfo
    status: UploadStatus
    progress: number
    progressBytes: number
    error?: Error
    execute: () => Promise<T>
    abort: () => void
}

export class UploadFileInfo {
    constructor(
        readonly name: string,
        readonly size: number,
        readonly type: string,
    ) {}

    static FromFile(file: File) {
        return new UploadFileInfo(file.name, file.size, file.type)
    }
}

export interface IProviderParams {
    file: File,
    fileInfo: UploadFileInfo,
    onProgress: (progress: TransferProgressEvent) => void
    abortSignal: AbortSignal
}

type StepWiseUploadRequestProvider<T> = (params: IProviderParams) => Promise<T>

export class StepWiseUpload<T> implements IUpload<T> {
    key: string = Math.random().toString()
    fileInfo: UploadFileInfo
    status: UploadStatus = UploadStatus.Created
    progress: number = 0
    progressBytes: number = 0
    error?: Error
    readonly abortController: AbortController = new AbortController()

    private _executeProm?: Promise<T>


    constructor(
        private readonly file: File,
        readonly upload: StepWiseUploadRequestProvider<T>
    ) {
        this.fileInfo = UploadFileInfo.FromFile(file)
        makeAutoObservable(this)
    }

    async execute() {
        if (this._executeProm) return this._executeProm
        this.status = UploadStatus.Uploading
        this._executeProm = this.upload({
            file: this.file,
            fileInfo: this.fileInfo,
            onProgress: this._handleProgress.bind(this),
            abortSignal: this.abortController.signal
        })
        try {
            const resp = await this._executeProm
            this.status == UploadStatus.Uploading && this.setStatus(UploadStatus.Succeeded)
            return resp
        } catch (e) {
            const ex = e as Error
            this.error = ex
            console.log("Woah error!")
            this.setStatus(UploadStatus.Failed)
            throw e
        }
    }

    setStatus(status: UploadStatus) {
        this.status = status
    }

    abort() {
        this.status = UploadStatus.Aborted
        this.abortController.abort()
    }

    private _handleProgress({loadedBytes}: TransferProgressEvent) {
        this.progressBytes = loadedBytes
        console.log(loadedBytes, this.fileInfo.size)
        this.progress = Math.min(loadedBytes / this.fileInfo.size, 1)
    }
}

const upload = new StepWiseUpload(new File([], 'test'), async (opts) => {
    return {foo: 'bar'}
})

export const createMockUpload = (file: File) => {
    return new StepWiseUpload(file, async (params) => {
        const {fileInfo, onProgress, abortSignal} = params
        let bytes = 0
        while(bytes < file.size && !abortSignal?.aborted) {
            bytes += 10000
            onProgress({loadedBytes: bytes})
            await new Promise(r => setTimeout(r, 5))
        }
        return {foo: 'bar'}
    })
}
