import { observer } from "mobx-react-lite"
import { AriaButtonProps } from "react-aria"
import { AbstractStep, StepStatus } from "@/stores/WorkflowDefinition"
import { IconCircleDotted, IconProgress, IconProgressAlert, IconProgressCheck, IconUsers } from "@tabler/icons-react"
import { SplitGroup } from "../SplitGroup/SplitGroup"
import clsx from "clsx"
import { WorkspaceMode } from "@/stores/WorkspaceCtx"
import { StepIcon } from "./StepIcon"
import { Step } from "prosemirror-transform"

const Styles = {
    focus: 'focus:ring-gray-300 dark:focus:ring-gray-800 focus:ring-4',
    selected: 'ring-gray-300 dark:ring-gray-800 ring-2',
    border: {
        default: 'rounded',
        chip: 'rounded-tl rounded-bl'
    }
}

interface Props extends React.PropsWithChildren<AriaButtonProps> {
    mode: WorkspaceMode
    step: AbstractStep
    selected?: boolean
    variant?: 'default' | 'chip'
}

export const WorkflowStepInfo = observer(({mode, step, selected = false, ...props}: Props) => {
    const displayExecStatus = WorkspaceMode.Execute == mode

    console.log('step info mode', mode)
    console.log('the step', step)

    return (
        <div className="flex items-center w-full">
            <SplitGroup>
                <div className={`flex space-x-2 p-1 ${step.comment ? 'items-start' : 'items-center'}`}>
                    <StepIcon iconType={step.displayType} />

                    <div className="flex flex-col">
                        <span className={"font-semibold leading-5"}>{`${step.displayName}`}</span>
                        <span className="text-sm">{`${step.comment || ''}`}</span>
                    </div>
                </div>
                <div>
                    {displayExecStatus && <StepStatusIcon step={step} />}
                </div>
            </SplitGroup>
        </div>
    )
})

export const StepStatusIcon = observer(({step}: {step: AbstractStep}) => {
    return <>
        {step.status == StepStatus.Running && <div>
            <IconProgress className="text-green-600"/>
        </div>}
        {step.status == StepStatus.Pending && <div>
            <IconCircleDotted/>
        </div>}
        {step.status == StepStatus.Succeeded && <div>
            <IconProgressCheck className="text-green-600"/>
        </div>}
        {step.status == StepStatus.Failed && <div>
            <IconProgressAlert className="text-red-600"/>
        </div>}
    </>
})