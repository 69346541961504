import { diff } from "deep-diff"
import { runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import validator from '@rjsf/validator-ajv8'

import { SchemaForm } from "@/components/SchemaForm/SchemaForm"
import { InputStep, InputType, TaskStep } from "@/stores/WorkflowDefinition"
import { ConcreteStepSettingsProps, PromptSchema, StepSchema, StepUiSchema } from "../StepSettings"
import { cloneDeep, merge } from "lodash-es"
import { SplitGroup } from "@/components/SplitGroup/SplitGroup"
import { SwButton } from "@/components"


interface PromptStepSettingsProps extends ConcreteStepSettingsProps {
    step: InputStep
}

export const PromptStepSetting = observer(({step, onSubmit, reset, isDirty, setIsDirty, readonly}: PromptStepSettingsProps) => {

    const stepSchema = merge(cloneDeep(StepSchema),
        {
            // properties: {
            //     prompts: cloneDeep(PromptSchema)
            // }
        }
    )

    const formData = {
        name: step.name,
        comment: step.comment,
        prompts: [...step.inputs.values()]
    }

    return <>
        <SchemaForm
            schema={stepSchema}
            uiSchema={StepUiSchema}
            formData={formData}
            validator={validator}
            readonly={readonly}
            onChange={(e, id) => {
                console.log(id)
                if (!id) return
                console.time("Update")
                const diffs = diff(formData, e.formData)
                updatePromptStep(step, e.formData)
                if (diffs) setIsDirty(true)
                console.timeEnd("Update")
            }}
            onSubmit={({formData}) => {
                onSubmit(step)
                setIsDirty(false)
            }}
        >
            <div className="mt-4">
                <SplitGroup>
                    <div>{isDirty && <SwButton onPress={() => reset()}>Reset</SwButton>}</div>
                    <div>{isDirty && <SwButton type="submit" variant="cta" >Save</SwButton>}</div>
                </SplitGroup>
            </div>

        </SchemaForm>

    </>
})

function updatePromptStep(step: InputStep, data: any) {
    console.log("Update task")
    runInAction(() => {
        step.name = data.name
        step.comment = data.comment

        if (step instanceof TaskStep)
            step.parameters = data.parameters

        for (const [key, prompt] of step.inputs.entries()) {
            if (!data.prompts.some((p: any) => p.key == key))
                step.inputs.delete(key)
        }

        for (const dataPrompt of data.prompts) {
            console.log("Updating prompts", step, dataPrompt)
            dataPrompt.type ??= InputType.String
            dataPrompt.key ??= `prompt-${step.inputs.size}`
            step.addInput(dataPrompt.key, dataPrompt)
        }
    })
}