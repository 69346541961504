import { MarkType, Node, NodeType } from "prosemirror-model"
import { ICacheContainer } from "./ProseCache"
import { action, observable } from "mobx"


export class MobxContainer implements ICacheContainer {
    @observable.shallow accessor activeMarkTypes: Map<MarkType, boolean> = new Map()
    @observable.shallow accessor selectedNodeTypes: Map<NodeType, boolean> = new Map()
    @observable.shallow accessor containingNodes: Map<Node, boolean> = new Map()
    @observable.shallow accessor containingNodeTypes: Map<NodeType, boolean> = new Map()

    runInAction(fn: () => void) {
        action(fn)()
    }
}