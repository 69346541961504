import { useContext, useRef, useState } from 'react'

import DOMPurify from 'dompurify'
import parse from 'html-react-parser'
import type {ComboBoxProps } from 'react-aria-components'
import {Text, ListBoxItem as AriaItem, ComboBox as AriaComboBox, ListBox, Label } from 'react-aria-components'
import { useAsyncList } from 'react-stately'
import { IconSearch } from '@tabler/icons-react'

import { useRunbookCtx } from '@/stores/Runbook'
import { OrgContext, useWorkspaceCtx } from '@/contexts'
import { SwPopover } from '../../Popover/Popover'
import { SwButton } from '../..'
import { SwInput } from '../../TextField/Input'

import '@/components/Select/Select.css'
import { useNavigate } from 'react-router-dom'

import '../../Select/Select.css'
import { useResizeObserver } from '@react-spectrum/utils'

type RunbookSearchProps = ComboBoxProps<SearchResult>

interface SwComboBoxProps<T extends object>
    extends Omit<ComboBoxProps<T>, 'children'> {
    label?: string;
    description?: string | null;
    errorMessage?: string | null;
    children: React.ReactNode | ((item: T) => React.ReactNode);
}

interface SearchResult {
    id: string
    orgId: number
    workspaceId: number
    name: string
    preview: string
}

export function RunbookSearchBar(props: RunbookSearchProps) {
    const [isOpen, setIsOpen] = useState(false)

    const triggerRef = useRef<HTMLDivElement>(null)
    const menuRef = useRef<HTMLDivElement>(null)
    const width = useRef(0)

    useResizeObserver({ref: triggerRef, onResize: () => {
        width.current = triggerRef.current?.getBoundingClientRect().width ?? 0
        const menu = menuRef.current
        requestAnimationFrame(() => {
            menu && (menu.style.width = `${width.current + 6}px`)
        })
    }})

    return <div className='relative w-full'>
            <div ref={triggerRef} className="flex items-center relative cursor-pointer rounded-lg border border-gray-300 dark:border-gray-900 h-8 bg-white" onClick={() => setIsOpen(true)}>
                <SwButton variant='icon' className="absolute left-2 text-slate-600"><IconSearch size={16}/></SwButton>
            </div>
            <SwPopover offset={-35} triggerRef={triggerRef} isOpen={isOpen} onOpenChange={setIsOpen}>
                <div ref={menuRef} className='p-2'>
                    <RunbookSearch/>
                </div>
            </SwPopover>
    </div>
}

export function RunbookSearch(props: RunbookSearchProps) {
    const orgCtx = useContext(OrgContext)
    const workspaceCtx = useWorkspaceCtx()
    const runbooks = useRunbookCtx()

    const navigate = useNavigate()

    const list = useAsyncList<SearchResult>({
        async load({filterText}) {
            const results = await runbooks.search(orgCtx!.orgId!, filterText || "")
            const items = results.map(r => ({
                id: r.runbookId,
                orgId: r.orgId,
                workspaceId: r.workspaceId,
                name: r.name,
                preview: r.text
            }))
            return {items}
        }
    })

    return (
        <RunbookSearchComboBox
            key={workspaceCtx.activeRunbook?.id}
            {...props} items={list.items}
            inputValue={list.filterText}
            onInputChange={list.setFilterText}
            onSelectionChange={item => {
                if (!item) return
                const runbook = list.getItem(item)
                workspaceCtx.loadActiveRunbook(runbook.id)
                list.setFilterText('')
                navigate(`/${runbook.orgId}/${runbook.workspaceId}/${item}`)
            }}
        />
    )
}

export function RunbookSearchComboBox(props: ComboBoxProps<SearchResult>) {
    return (
        <SwComboBox {...props}>
            {item => <SearchItem item={item}/>}
        </SwComboBox>
    )
}

export function SwComboBox<T extends object>(props: SwComboBoxProps<T>) {
    const { label, description, errorMessage, children, ...otherProps } = props

    console.log('Combo', [...props.items!].length)

    return (
    <AriaComboBox
        style={{width: '100%'}}
        {...otherProps}
        // @ts-ignore
        allowsEmptyCollection={true}
    >
      <Label>{label}</Label>
      <div className="flex items-center relative">
        <SwInput autoFocus={true} placeholder='Search for Runbooks by title and content' />
      </div>
      {description && <Text slot="description">{description}</Text>}
      {errorMessage && <Text slot="errorMessage">{errorMessage}</Text>}
      {/* <SwPopover> */}
            <div className='p-2'>
                <ListBox className="">
                    {[...props.items!].length == 0 ? <PlaceHolder/> : children }
                </ListBox>
            </div>
      {/* </SwPopover> */}
    </AriaComboBox>
    )
}

const PlaceHolder = () => {
    return <AriaItem id="0">No results</AriaItem>
}

export function SearchItem({item, ...props}: {item: SearchResult}) {
    return (
    <AriaItem
        className={({ isFocused, isSelected }) => `my-item cursor-pointer ${isFocused ? 'focused' : ''} ${isSelected ? 'selected' : ''}`}
        key={item.id}
        {...props}
    >
        <div className='text-lg'>
            {item.name}
        </div>
        <div className="text-sm">
            {parse(DOMPurify.sanitize(item.preview, {ALLOWED_TAGS: ['b']}))}
        </div>
    </AriaItem>

    )
}