import { useWorkspaceCtx } from "@/contexts"
import { useWorkflowStore } from "@/stores/Workflow"
import { observer } from "mobx-react-lite"
import { WorkflowStepSettings } from "./StepSettings"
import { CreateStep } from "./CreateStep"


export const StepPanel = observer(() => {
    const ctx = useWorkspaceCtx()

    const workflow = ctx.activeWorkflow
    const step = workflow?.selectedStep

    return <>
        {step && <WorkflowStepSettings/>}
        {!step && workflow?.stepList.length == 0 && ctx.editor && <CreateStep/>}
    </>
})