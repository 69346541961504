import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import { Component } from './Component'

export const WorkflowStepExt = Node.create({
    name: 'workflowStep',

    group: 'block',

    atom: true,

    draggable: true,

    addAttributes() {
        return {
            "step-id": {
                default: 0,
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: 'stepwise-step',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['stepwise-step', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return ReactNodeViewRenderer(Component, {className: 'my-4'})
    },
})