import { DocMaterialDto, StepWise } from "@/client"
import { action, observable } from "mobx"
import { createUseStore } from "./util"
import { serial } from "@/util/async"
import { NodeJSON } from "@stepwisehq/prosemirror-collab-commit/collab-commit"


export class DocStore {
    @observable accessor byRef = new Map<string, Doc>

    constructor(readonly sw: StepWise) {}

    getByRef(ref: DocRef): Nullable<Doc> {
        return this.byRef.get(this.docKey(ref)) ?? null
    }

    async publish(doc: DocRef) {
        await this.sw.docPublish(doc.id, doc.orgId)
    }

    @action async load(doc: DocRef) {
        const material = await this._load(doc.id, doc.version)
        this.byRef.set(this.docKey(doc), new Doc({
            id: doc.id,
            orgId: doc.orgId,
            content: material.baseDoc,
            version: material.version,
            // commits: material.commits
        }))
    }
    @serial private async _load(docId: string, version?: number): Promise<DocMaterialDto> {
        return await this.sw.docMaterialGet(docId, {version})
    }

    async preLoad(doc: DocRef) {
        await this.load(doc)
    }

    private docKey(doc: DocRef) {
        const {id, version} = doc
        return `${id}-${version ?? "latest"}`
    }
}
export const [DocStoreCtx, useDocStore] = createUseStore(DocStore)

export interface DocRef {
    id: string
    orgId: number
    version?: number
}

export interface IDoc {
    id: string
    orgId: number
    version: number
    content?: NodeJSON | string
}

export class Doc implements IDoc {
    id!: string
    orgId!: number
    version = 0
    content!: NodeJSON | string

    constructor(spec: Partial<IDoc>) {
        this.fromSpec(spec)
    }

    fromSpec(spec: Partial<IDoc>) {
        Object.assign(this, spec)
    }
}