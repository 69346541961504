type ExtractRouteParams<T> =
    string extends T ?
    Record<string, string>
    : T extends `${infer _Start}:${infer Param}/${infer Rest}` ?
        Param extends `${infer _Prefix}?` ?
            { [k in _Prefix]: string | undefined } & ExtractRouteParams<Rest>
            : { [k in Param]: string } & ExtractRouteParams<Rest>
    : T extends `${infer _Start}:${infer Param}` ?
        Param extends `${infer _Prefix}?` ?
            { [k in _Prefix]: string | undefined }
            : { [k in Param]: string }
    : {};


export interface StepWiseRoute<T extends string> {
    path: T
    params: ExtractRouteParams<T>
}

export function getTypedParams<T extends string>(path: T, params: any) {
    return {
        path,
        params
    } as StepWiseRoute<T>
}

export const enum RoutePath {
    EditRunbook = "/:org/:workspace/:runbook?",
    Execution = "/:org/:workspace/runbook-execution/:execution",
    AuthLogin = "/auth/login",
    AuthRegistration = "/auth/register",
    AuthConfirmEmail = "/auth/confirm-email",
    AuthResetPassword = "/auth/reset-password",
    AuthForgotPassword = "/auth/forgot-password",
    WorkspaceActivity = "/:org/:workspace/activity",
    WorkspaceRunbooks = "/:org/:workspace",
}


export interface Routes {
    [RoutePath.AuthConfirmEmail]: {
        queryParams: {
            code?: string
        }
    }
}

