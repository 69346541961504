import { DOMAttributes } from "@react-types/shared"
import { NodeViewProps, NodeViewWrapper } from "@tiptap/react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useRef, useState } from "react"
import { useHover, useMove } from "react-aria"


export const Component =  observer((props: NodeViewProps) => {
    const {isEditable} = props.editor

    const id =  props.node.attrs.id
    const src = id ? props.extension.options.genLink(id) : props.node.attrs.src
    const alt = props.node.attrs.alt || ''
    const width = props.node.attrs.width || 100

    const xRef = useRef(100)
    const [x, setX] = useState(100)

    const {hoverProps, isHovered} = useHover({})

    const [isMoving, setMoving] = useState(false)
    const moveProps = {
        onMove(delta: number) {
            console.log('Adjusting by', delta, xRef.current)
            xRef.current = Math.max(10, Math.min(100, xRef.current - (delta * 0.5)))
            setX(xRef.current)
        },
        onMoveStart() {
            xRef.current = width
            setX(width)
            setMoving(true)
        },
        onMoveEnd() {
            setMoving(false)
            props.updateAttributes({
                width: xRef.current
            })
        }
    }

    const displayHandles = isEditable && (isHovered || isMoving)
    const displaySelectRing = isEditable && props.selected

    return (
        <NodeViewWrapper
            draggable={true}
            className={clsx(
                "flex not-prose content-around justify-center",
            )}
            data-drag-handle
        >
            <div
                className={clsx("relative grow-0 shrink-0", displaySelectRing && 'ring-2 ring-blue-300')}
                style={{maxWidth: isMoving ? `${x}%` : `${width}%`}}
                {...hoverProps}
            >
                <DragHandle position={DragHandlePosition.TopLeft} shouldDisplay={displayHandles} {...moveProps} />
                <DragHandle position={DragHandlePosition.BottomLeft} shouldDisplay={displayHandles} {...moveProps} />
                <DragHandle position={DragHandlePosition.TopRight} shouldDisplay={displayHandles} {...moveProps} />
                <DragHandle position={DragHandlePosition.BottomRight} shouldDisplay={displayHandles} {...moveProps} />
                <img className="not-prose" src={src} alt={alt}/>
            </div>
        </NodeViewWrapper>)
})

const enum DragHandlePosition {
    TopLeft,
    TopRight,
    BottomLeft,
    BottomRight
}

interface DragHandleProps extends DOMAttributes {
    position: DragHandlePosition
    shouldDisplay: boolean
    onMove: (delta: number) => void
    onMoveEnd: () => void
    onMoveStart: () => void
}

const DragHandle = (props: DragHandleProps) => {
    const {
        position,
        shouldDisplay,
        onMove,
        onMoveEnd,
        onMoveStart,
    } = props


    const {moveProps} = useMove({
        onMove(e) {
            console.log(e.deltaX, e.deltaY)
            const dist = Math.sqrt(e.deltaX * e.deltaX + e.deltaY * e.deltaY)

            let direction = 1
            switch (position) {
            case DragHandlePosition.TopLeft:
                if (e.deltaY < 0 || e.deltaX < 0) direction = -1
                break
            case DragHandlePosition.BottomLeft:
                if (e.deltaY > 0 || e.deltaX < 0) direction = -1
                break
            case DragHandlePosition.TopRight:
                if (e.deltaY < 0 || e.deltaX > 0) direction = -1
                break
            case DragHandlePosition.BottomRight:
                if (e.deltaY > 0 || e.deltaX > 0) direction = -1
                break
            }

            let delta = Math.sqrt(e.deltaX * e.deltaX + e.deltaY * e.deltaY) * direction
            // delta = Math.max(-1, Math.min(1, delta))
            onMove(delta)
        },
        onMoveStart,
        onMoveEnd
    })

    return <div
        className={clsx(
            shouldDisplay ? "block" : "hidden",
            "absolute bg-slate-600 w-2 h-2 cursor-pointer",
            position == DragHandlePosition.TopLeft ? "top-0 left-0" : "",
            position == DragHandlePosition.TopRight ? "top-0 right-0" : "",
            position == DragHandlePosition.BottomLeft ? "bottom-0 left-0" : "",
            position == DragHandlePosition.BottomRight ? "bottom-0 right-0" : "",
        )}
        {...moveProps}
    />
}