import { useAuthStore } from "@/hooks/useStores"
import { RoutePath, Routes } from "@/routes"
import { LoginWidget } from "@/widgets/Login"
import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

export const ConfirmEmailScreen = () => {
    const auth = useAuthStore()
    const [params, _] = useSearchParams()

    const code = params.get('code')
    const userId = params.get('userId')

    const confirm = async () => {
        await auth.confirmEmail(userId!, code!)
    }

    useEffect(() => {
        if (userId && code) {
            confirm().then(continueTo)
        }
    }, [])

    const navigate = useNavigate()

    const continueTo = () => {
        const continueTo = params.get('continueTo')
        if (continueTo) {
            navigate(continueTo)
        } else {
            navigate('/')
        }
    }

    return (
        <div className="flex items-center w-screen h-screen justify-around">
            <div className="w-80 h-80">
                <LoginWidget onLogin={continueTo}/>
            </div>
        </div>
    )
}