import { cn } from "@/util/cn"
import { IconUser } from "@tabler/icons-react"
import { useEffect, useState } from "react"

export interface AvatarProps {
    src?: string
    alt?: string
}

export const SwAvatar = ({src, alt}: AvatarProps) => {
    const [useFallback, setUseFallback] = useState(!src)

    useEffect(() => {
        setUseFallback(!src)
    }, [src])

    return (
        <div
            className={cn(
                "w-6 h-6 rounded-full border-[1px] border-black dark:border-white overflow-hidden",
                useFallback && "border-[1.5px]"
            )}
        >
            {!useFallback && <img src={src} alt={alt} onError={() => setUseFallback(true)} />}
            {useFallback && <IconUser className="w-6 h-6 dark:border-white pt-[0.8px]" />}
        </div>
    )
}
