import { action, computed, observable, runInAction } from "mobx"

import { StepWise } from "../client/stepWise"
import { DocRef, DocStore } from "./DocStore"
import { ExecutionStatus, WorkflowRef } from "@/client/index"
import { WorkflowLoadedEvt, WorkflowStore } from "./Workflow"
import { Workflow } from "./WorkflowDefinition"
import { createUseStore } from "./util"
import { IUser } from "./AuthStore"
import { User } from "./UserStore"

export enum RunbookMoveOp {
    InsertInto = "InsertInto",
    InsertAfter = "InsertAfter",
    InsertBefore = "InsertBefore",
}

export class RunbookStore {
    @observable accessor runbooks: Runbook[] = []
    @observable accessor byId: Map<string, Runbook> = new Map

    @observable accessor selected: Nullable<Runbook> = null

    constructor(readonly sw: StepWise, readonly workflows: WorkflowStore, readonly docs: DocStore) {
        this.workflows.onDidChangeMode(this.handleWorkflowLoaded.bind(this))
    }

    @computed get runbookRoots() {
        return this.runbooks.filter(r => !r.parent)
    }

    workflowFor(runbook: IRunbook): Nullable<Workflow> {
        return this.workflows.getByRef(runbook.workflowRef)
    }

    async create(org: number, workspace: number, name: string = "Untitled Runbook", parent?: Runbook) {
        const runbook = await this.sw.runbookCreate(org, workspace, {
            name,
            parentId: parent?.id
        })
        return this.add(runbook)
    }

    @action add(...runbooks: IRunbook[]) {
        const newRunbooks: Runbook[] = []

        runbooks.forEach(r => {
            const oldRunbook = this.byId.get(r.id)
            oldRunbook?.fromSpec(r)
            const runbook = oldRunbook ?? new Runbook(this, r)

            this.byId.set(r.id, runbook)
            if (!oldRunbook) this.runbooks.push(runbook)
            newRunbooks.push(runbook)

            // Find parent
            if (r.parentId && this.byId.has(r.parentId)) {
                const parent = this.byId.get(r.parentId)!
                parent.addChild(runbook)
                runbook.parent = parent
            }

            // Find children
            this.runbooks.filter(r => r.parent && r.parent === runbook.id).forEach(r => {
                runbook.addChild(r)
            })
        })

        this.sort()

        return newRunbooks
    }

    @action remove(runbook: Runbook) {
        if (runbook.parent instanceof Runbook) runbook.parent.removeChild(runbook)
        this.byId.delete(runbook.id)
        this.runbooks.splice(this.runbooks.indexOf(runbook), 1)
    }

    @action setSelected(runbook: Runbook) {
        this.selected = runbook
    }

    async search(org: number, query: string) {
        return await this.sw.runbookSearch(org, query)
    }

    async load(org: number) {
        const runbooks = await this.sw.runbookListByOrg(org)
        runInAction(() => {
            this.runbooks.forEach(oldRB => {
                if (!runbooks.find(newRB => oldRB.id == newRB.id)) this.remove(oldRB)
            })
            this.add(...runbooks)
        })
    }

    @action clear() {
        this.runbooks = []
        this.byId.clear()
    }

    async loadRunbookExecutions(runbook: Runbook) {
        const executions = await this.sw.runbookExecutionList(runbook.orgId, runbook.id)
        runbook.executions = executions.map(e => new RunbookExecution(e))
    }

    async loadRunbookExecution(org: number, id: string) {
        const resp = await this.sw.runbookExecutionGet(org, id)
        return new RunbookExecution(resp)
    }

    async save(runbook: Runbook) {
        const dto = runbook.toSpec()
        await this.sw.runbookUpdate(runbook.orgId, dto.id, dto)
    }

    async delete(runbook: Runbook) {
        await this.sw.runbookDelete(runbook.orgId, runbook.id)
        this.remove(runbook)
    }

    async publish(runbook: Runbook) {
        await this.sw.runbookPublish(runbook.orgId, runbook.id)
    }

    async execute(runbook: Runbook) {
        const ex = await this.sw.runbookExecute(runbook.orgId, runbook.id)
        return new RunbookExecution(ex)
    }

    async loadById(orgId: number, id: string) {
        const runbookDto = await this.sw.runbookGet(orgId, id)
        this.add(runbookDto)
        return this.byId.get(runbookDto.id)!
    }

    async moveRunbook(runbook: Runbook, target: Runbook, operation: RunbookMoveOp) {
        const dto = {
            targetId: target.id,
            operation
        }
        const updated = await this.sw.runbookMove(runbook.orgId, runbook.id, dto)

        runInAction(() => {
            runbook.update({rank: updated.rank})
            switch (operation) {
            case RunbookMoveOp.InsertInto:
                target.addChild(runbook)
                break
            case RunbookMoveOp.InsertBefore:
            case RunbookMoveOp.InsertAfter:
                if (runbook.parent instanceof Runbook) runbook.parent.removeChild(runbook)
                if (target.parent instanceof Runbook) target.parent.addChild(runbook)
                break
            }
            this.sort()
        })
    }

    handleWorkflowLoaded({detail: workflow}: WorkflowLoadedEvt) {
        const runbook = this.runbooks.find(r => r.workflowRef.id === workflow.ref!.id)
        if (!runbook) return
        // runbook.workflow = workflow
    }

    @action private sort() {
        this.runbooks.sort((a, b) => {
            return a.rankKey.localeCompare(b.rankKey)
        })
    }
}
export const [RunbookCtx, useRunbookCtx] = createUseStore(RunbookStore)

export interface IRunbook {
    id: string
    parentId: Nullable<string>
    orgId: number
    workspaceId: number
    name?: string
    docId: string
    workflowRef: WorkflowRef
    rank?: string
}

export class Runbook {
    @observable accessor id!: string
    @observable accessor orgId!: number
    @observable accessor workspaceId!: number
    @observable accessor name!: string
    @observable accessor docId!: string
    @observable accessor doc!: DocRef
    @observable accessor workflowRef!: WorkflowRef
    @observable accessor rank!: string
    @observable accessor executions: RunbookExecution[] = []

    @observable accessor parent: Nullable<Runbook | string> = null
    @observable private accessor _children: Runbook[] = []

    static MimeType = 'sw/runbook'

    @computed get children() {
        return this._children.slice().sort((a, b) => a.rankKey.localeCompare(b.rankKey))
    }

    @computed get depth() {
        return this.ancestors.length
    }

    @computed get subTreeDepth() {
        let maxDepth = 0
        this.children.forEach(c => {
            const cDepth = c.subTreeDepth + 1
            if (cDepth > maxDepth) maxDepth = cDepth
        })
        return maxDepth
    }

    @computed get ancestors() {
        const ancestors: Runbook[] = []
        let parent: Runbook | string | null = this
        while (parent = parent.parent) {
            if (parent instanceof Runbook) ancestors.push(parent)
            else break
        }
        return ancestors
    }

    constructor(readonly store: RunbookStore, spec: IRunbook) {
        this.fromSpec(spec)
    }

    @computed get rankKey() {
        const parentPart = this.parent instanceof Runbook ? `${this.parent.id}` : ""
        return `${parentPart}${this.rank}`
    }

    @computed get prevousSibling(): Runbook | null {
        const parent = this.parent

        if (parent instanceof Runbook) {
            const myIndex = parent.children.indexOf(this)
            return myIndex > -1 ? parent.children[myIndex - 1] : null
        }
        return null
    }

    @computed get nextSibling(): Runbook | null {
        const parent = this.parent

        if (parent instanceof Runbook) {
            const myIndex = parent.children.indexOf(this)
            return myIndex > -1 ? parent.children[myIndex + 1] : null
        }
        return null
    }

    @action addChild(child: Runbook) {
        if (child.parent instanceof Runbook) child.parent.removeChild(child)
        if (!this._children.includes(child)) this._children.push(child)
        child.parent = this
    }

    @action removeChild(child: Runbook) {
        const childIndex = this._children.indexOf(child)
        if (childIndex >= 0)
            this._children.splice(childIndex, 1)
        child.parent = null
    }

    @action fromSpec(spec: IRunbook) {
        this.id = spec.id
        this.parent = spec.parentId ?? null
        this.orgId = spec.orgId
        this.workspaceId = spec.workspaceId
        this.name = spec.name!
        this.docId = spec.docId
        this.doc = {id: this.docId, version: 0, orgId: this.orgId}
        this.workflowRef = { id: spec.workflowRef.id, revision: spec.workflowRef.revision }
        this.rank = spec.rank!
    }

    toSpec(): IRunbook {
        return {
            id: this.id,
            parentId: this.parent instanceof Runbook ? this.parent.id : this.parent,
            orgId: this.orgId,
            workspaceId: this.workspaceId,
            name: this.name,
            docId: this.docId,
            workflowRef: this.workflowRef,
            rank: this.rank
        }
    }

    @action update(data: Partial<Runbook>) {
        data.name && (this.name = data.name!)
        data.rank && (this.rank = data.rank!)
    }
}

export interface IRunbookExecution {
    id: string
    orgId: number
    author: IUser
    runbookId: string
    executionStatus: ExecutionStatus
    docId: string
    docVersion: number
    executionId: string
    createdAt: Date
    updatedAt: Date
}

export class RunbookExecution {
    @observable accessor id!: string
    @observable accessor orgId!: number
    @observable accessor author!: User
    @observable accessor runbookId!: string
    @observable accessor executionStatus!: ExecutionStatus
    @observable accessor workflowRef!: WorkflowRef
    @observable accessor doc!: DocRef
    @observable accessor docId!: string
    @observable accessor docVersion!: number
    @observable accessor executionId!: string
    @observable accessor createdAt!: Date
    @observable accessor updatedAt!: Date

    constructor(spec: IRunbookExecution) {
        this.fromSpec(spec)
        this.author = new User(spec.author)
    }

    @action fromSpec(spec: IRunbookExecution) {
        Object.assign(this, spec)
        this.doc = { id: this.docId, version: this.docVersion, orgId: this.orgId}
    }

    toSpec(): IRunbookExecution {
        return Object.assign({}, this)
    }
}

export interface IStep {
    label: string
    description: string
    symbol: string
    selected: boolean
}

export class Step {
    @observable accessor label!: string
    @observable accessor description: Nullable<string> = null
    @observable accessor symbol!: string

    selected: boolean = false

    constructor(spec: IStep) {
        this.fromSpec(spec)
    }

    @action fromSpec(spec: IStep) {
        Object.assign(this, spec)
    }
}