import React, { ForwardedRef, forwardRef } from 'react'

import { Button as AriaButton } from 'react-aria-components'

import clsx from 'clsx'
import { SwButtonProps } from './Props'
import { SlotProvider } from '@react-spectrum/utils'
import { SwText } from '../Text/Text'
import { cn } from '@/util/cn'

export const BaseButtonStyles = {
    outline: 'outline-none focus:outline-none',
    border: {
        loud: '',
        quiet: 'border-none',
    },
    size: {
        xs: 'text-xs py-0.2 px-0.5 rounded',
        sm: 'text-xs py-0.5 rounded h-full min-w-[1.5rem]',
        md: 'text-sm py-1 rounded h-[2rem] min-w-[2rem]',
        lg: 'text-base py-2 px-3 rounded',
    },
    main: 'inline-flex max-w-fit items-center justify-center font-medium text-center transition duration-75 ease-out hover:ease-in shrink-0 border-[1px]',
    hover: '',
    focus: 'focus:ring-2',
    variant: {
        cta: {
            fill: 'bg-green-600 border-green-600 hover:bg-green-900 hover:border-green-900 text-white focus:ring-green-300',
            outline: 'border-green-600 text-green-500 hover:bg-lime-900'
        },
        primary: {
            fill: 'bg-indigo-600 border-indigo-600 hover:bg-indigo-900 hover:border-indigo-900 text-white focus:ring-indigo-300',
            outline: 'border-indigo-600 text-indigo-600 hover:bg-indigo-300 dark:text-blue-300 dark:border-blue-300 dark:hover:bg-blue-950'
        },
        secondary: {
            fill: 'bg-gray-200 hover:bg-gray-300 text-gray-900 border-gray-200 hover:border-gray-300 dark:bg-gray-700 dark:hover:bg-gray-800 dark:text-gray-200 dark:border-gray-700 focus:ring-gray-300',
            outline: 'border-gray-800 hover:border-gray-800 hover:bg-gray-200 dark:text-gray-300 dark:hover:bg-gray-700',
        },
        negative: {
            fill: 'bg-red-600 hover:bg-red-800 text-white border-red-600 hover:border-red-800 dark:bg-gray-700 dark:hover:bg-gray-800 dark:text-gray-200 dark:border-gray-700 focus:ring-gray-300',
            outline: 'border-gray-800 hover:border-gray-800 hover:bg-gray-200 dark:text-gray-300 dark:hover:bg-gray-700',
        },
        icon: {
            fill: 'border-transparent p-0',
            outline: ''
        },
        link: {
            fill: 'border-none dark:text-white hover:underline',
            outline: ''
        }
    },
    disabled: {
        cta: 'cursor-not-allowed bg-indigo-800 border-indigo-800 text-indigo-300 hover:bg-indigo-800',
        primary: 'cursor-not-allowed bg-indigo-800 border-indigo-800 text-indigo-300 hover:bg-indigo-800',
        secondary: 'cursor-not-allowed bg-gray-100 text-gray-500 border-gray-100 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-800 ',
        negative: 'cursor-not-allowed bg-gray-100 text-gray-500 border-gray-100 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-800 ',
        icon: 'cursor-not-allowed p-0',
        link: 'cursor-not-allowed text-gray-400 dark:text-gray-400 dark:hover:text-gray-400 hover:no-underline',
    },
    icon: {
        size: {
            xs: "1rem",
            sm: "1rem",
            md: "1.125rem",
            lg: "1.75rem"
        }
    },
    style: {
        fill: [],
        outline: []
    }
}

/**
 * Primary UI component for user interaction
 */
export const SwButton = forwardRef((props: SwButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
        size = 'md',
        isQuiet = false,
        isHidden = false,
        variant = 'secondary',
        style = 'fill',
        children,
        className,
        ...otherProps
    } = props

    const isTextOnly = (typeof children !== 'function') && React.Children.toArray(children).every(c => !React.isValidElement(c))

    return (
        <AriaButton
            className={({isFocusVisible, isDisabled}) =>
                cn(
                    "sw-Button",
                    ...[BaseButtonStyles.outline, BaseButtonStyles.main],
                    BaseButtonStyles.size[size],
                    BaseButtonStyles.variant[variant][style],
                    BaseButtonStyles.style[style],
                    isFocusVisible && BaseButtonStyles.focus,
                    isQuiet ? BaseButtonStyles.border.quiet : BaseButtonStyles.border.loud,
                    isDisabled && BaseButtonStyles.disabled[variant],
                    !isDisabled && BaseButtonStyles.hover,
                    isHidden && "hidden",
                    className
                )}
            {...otherProps}
            ref={ref}
        >
          <SlotProvider
              slots={{
                  text: { className: "last:mr-3 first:ml-3 " },
                  icon: { className: "mx-1 only:mx-0 last:mr-2 first:ml-2", size: BaseButtonStyles.icon.size[size] }
              }}
          >
            {typeof children === 'string' || isTextOnly
                ? <SwText>{children}</SwText>
                : children}
          </SlotProvider>
        </AriaButton>
    )
})
