import { SwButton } from "@/components"
import { SwIcon } from "@/components/Icon/Icon"
import { SwTagField, SwTagFieldItemBase, SwTagFieldProps } from "@/components/TagField/TagField"
import { OrgContext } from "@/contexts"
import { useUserCtx } from "@/stores/UserStore"
import { cn } from "@/util/cn"
import { IconX } from "@tabler/icons-react"
import { useContext } from "react"
import { ListBoxItem, ListBoxItemProps, Tag } from "react-aria-components"
import { AsyncListData, useAsyncList } from "react-stately"


export type UserData = {id: string, name: string, orgId: number}


export interface UserTagFieldProps<T extends SwTagFieldItemBase> extends Omit<SwTagFieldProps<T>, 'children' | 'items'> {}

export function UserTagField(props: UserTagFieldProps<UserData>) {
    const orgCtx = useContext(OrgContext)
    const users = useUserCtx()

    const list = useAsyncList<UserData>({
        async load({filterText}) {
            const results = await users.search(orgCtx!.orgId!, filterText || "")

            const items = results.map(r => ({
                id: r.userName,
                name: r.userName,
                orgId: r.orgId
            }))
            return {items}
        }
    })

    return <>
        <UserTagFieldView items={list} onInputChange={list.setFilterText} {...props} />
    </>
}

export interface UserTagFieldViewProps<T extends SwTagFieldItemBase> extends Omit<SwTagFieldProps<T>, 'children' | 'items'> {
    items: AsyncListData<T>
}

export function UserTagFieldView<T extends SwTagFieldItemBase>({items, onChange, tagItems, label, isReadOnly}: UserTagFieldViewProps<T>) {
    console.log(isReadOnly)
    return <>
        <SwTagField isReadOnly={isReadOnly} label={label} items={items.items} tagItems={tagItems} onChange={onChange} onInputChange={value => items.setFilterText(value)}>
            {items => <Tag textValue={items.name} className={({isDisabled}) => `${isDisabled && 'text'}`}>
                <div className={cn('flex items-center pl-2 pr-1 rounded-full border-[1.5px] border-gray-500 bg-white', isReadOnly && 'font-mono')}>
                    {items.name}
                    {!isReadOnly && <SwButton className="bg-white" isDisabled={isReadOnly} size="xs" variant='icon' slot="remove"><SwIcon icon={IconX}/></SwButton>}
                </div>
            </Tag>}
            {item => <SearchItem textValue={item.id} id={item.id} className={({isDisabled}) => `${isDisabled && 'text-slate-300'}`}>
                {({isDisabled}) => <div className={`${isDisabled && 'text-slate-300'}`}>{item.name}</div>}
            </SearchItem>}
        </SwTagField>
    </>
}

function SearchItem(props: ListBoxItemProps) {
    return (
    <ListBoxItem
        {...props}
        className={({ isFocused, isSelected }) =>
            `my-item ${isFocused ? 'focused' : ''} ${isSelected ? 'selected' : ''}`}
    />
    )
}