import { AuthContext, RootContext } from "@/contexts"
import { useContext } from "react"


export const useRootStore = () => {
    const store = useContext(RootContext)

    if (!store) throw new Error('Must call useRootStore in context')

    else return store
}

export const useAuthStore = () => {
    const store = useContext(RootContext)

    if (store) return store.auth

    const auth = useContext(AuthContext)

    if (!auth) throw new Error('useAuthStore requires AuthContext')

    return auth
}

