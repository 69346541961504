import { OperationArguments, OperationSpec } from "@azure/core-client"
import { StepWise } from "./client"


export class StepWiseClient extends StepWise {
    async sendOperationRequest<T>(
        operationArguments: OperationArguments,
        operationSpec: OperationSpec,
    ): Promise<T> {
        operationArguments['xSwOrgId'] = this.xSwOrgId
        return super.sendOperationRequest<T>(operationArguments, operationSpec)
    }
}