import { observer } from "mobx-react-lite"
import { AriaButtonProps, DragPreview, TextDropItem, mergeProps, useButton, useDrag, useDrop, useFocusRing } from "react-aria"
import clsx from "clsx"
import { AbstractStep, Step } from "@/stores/WorkflowDefinition"
import { useRef } from "react"
import { IconDragDrop, IconUsers } from "@tabler/icons-react"
import { WorkflowStepInfo } from "../../../../components/Workflow/StepInfo"
import { useWorkspaceCtx } from "@/contexts"

const Styles = {
    focus: 'focus:ring-gray-300 dark:focus:ring-gray-800 focus:ring-4',
    dropTarget: 'after:ring-green-300 after:dark:ring-green-800 after:ring-2 after:absolute after:left-0 after:right-0 after:-bottom-1',
    selected: 'ring-indigo-600 dark:ring-indigo-800 ring-2',
}

interface Props extends React.PropsWithChildren<AriaButtonProps> {
    step: Step
    selected?: boolean
    edit?: boolean
    onInsertAfter: (step: Step, nextId: string) => void
}

export const WorkflowStepEntry = observer(({step, selected = false, edit = false, ...props}: Props) => {
    const workspaceCtx = useWorkspaceCtx()
    const preview = useRef(null)
    let ref = useRef<HTMLDivElement>(null)
    let { dropProps, isDropTarget } = useDrop({
        ref,
        async onDrop(e) {
            const item = e.items.find(i => i.kind === 'text') as TextDropItem | undefined
            if (!item || !item.types.has('stepwise/item')) return
            const {id} = JSON.parse(await item.getText('stepwise/item')!) as {id: string}
            props.onInsertAfter(step, id)
        },
        getDropOperation(types, allowedOperations) {
            return step.next ? 'move' : 'cancel'
        },
    })
    let { buttonProps } = useButton({...props, elementType: 'div'}, ref)
    let { isFocusVisible, focusProps } = useFocusRing()

    let { dragProps, dragButtonProps, isDragging } = useDrag({
        hasDragButton: true,
        preview,
        getItems() {
            return [{
                'text/html': `<stepwise-step step-id="${step.id}"/>`,
                'stepwise/item': JSON.stringify({
                    type: 'WorkflowStep',
                    id: step.id,
                })
            }]
        },
    })

    const dragRef = useRef<HTMLDivElement>(null)
    const { buttonProps: buttonDragProps } = useButton({...dragButtonProps, elementType: 'div'}, dragRef)

    return (
        <div
            className={clsx(
                " w-full rounded bg-slate-200 p-1.5 outline-none flex items-center justify-between relative",
                isFocusVisible && Styles.focus,
                selected && Styles.selected,
                isDropTarget && Styles.dropTarget)}
            key={step.id}
            {...mergeProps(buttonProps, focusProps, dropProps)}
            ref={ref}
        >
            <WorkflowStepInfo step={step} mode={workspaceCtx.mode}/>
            {edit && <div
                className="hover:cursor-grab outline-none"
                // {...buttonDragProps}
                // {...dragProps}
                ref={dragRef}
            >
                {/* <IconDragDrop/> */}
            </div>}
            <DragPreview ref={preview}>
                {(items) => (
                    renderIcon()
                )}
            </DragPreview>
        </div>
    )
})

function renderIcon() {
    return (
        <div className="bg-orange-400 p-2.5 flex items-center relative" >
            <IconUsers className="text-white" size={20}/>
        </div>
    )
}