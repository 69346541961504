import { observer } from "mobx-react-lite"
import { useContext, useEffect, useState } from "react"
import { ArrayFieldTitleProps, ObjectFieldTemplateProps, RJSFSchema, UiSchema, titleId } from "@rjsf/utils"

import { WorkspaceContext } from "@/contexts"
import { WorkspaceMode } from "@/stores/WorkspaceCtx"
import { useTaskStore } from '@/stores/Tasks'
import { useWorkflowStore } from '@/stores/Workflow'
import { AbstractStep, TaskStep, StepType, InputStep, InputType, Step, InputStepFlavor, ITaskStep } from "@/stores/WorkflowDefinition"
import { SwSelectItem, SwSelect } from '../../../../components/Select/Select'
import { UploadStore } from '@/stores/Upload'
import { useExecutionAttachmentStore } from '@/stores/AttachmentStore'
import { StepName } from '@/editor/nodes/step-block/component/StepName'
import { PromptStepSetting } from './stepSettings/PromptStepSettings'
import { SwIcon } from '@/components/Icon/Icon'
import { IconHandClick, IconWebhook, TablerIconsProps } from '@tabler/icons-react'
import { ApprovalStepSetting } from './stepSettings/ApprovalStepSettings'
import { TaskStepSettings } from './stepSettings/TaskStepSettings'
import { StepState } from "./StepState"



export const StepSchema: RJSFSchema = {
    type: 'object',
    required: [
        "name",
    ],
    properties: {
        name: {
            type: "string",
            title: "Name",

        },
        comment: {
            type:  ["string", "null"],
            title: "Description"
        },
    }
}

export const PromptSchema: RJSFSchema = {
    title: "Inputs",
    type: "array",
    items: {
        // title: 'Prompt Type',
        type: 'object',
        anyOf: [
            {
                title: "String",
                type: "object",
                properties: {
                    type: { type: "string", const: InputType.String },
                    name: { type: "string", title: "Name" },
                },
            },
        ]
    }
}

function ArrayFieldItemTemplate(props: ObjectFieldTemplateProps) {
    const { properties, title, formData } = props
    return <>
        <fieldset><legend>{formData.name || title}</legend></fieldset>
        {properties.map((element, index) =>
            // Remove the <Grid> if the inner element is hidden as the <Grid>
            // itself would otherwise still take up space.
            element.hidden ? (
            element.content
            ) : (
            element.content
            )
        )}
    </>
}

function ArrayFieldTitleTemplate(props: ArrayFieldTitleProps) {
    const { title, idSchema } = props
    const id = titleId(idSchema)
    return <h1 id={id}>Foo</h1>
}

export const StepUiSchema: UiSchema = {
    "ui:submitButtonOptions": {
        norender: true
    }
}

export const WorkflowStepSettings = observer(() => {
    const workspaceCtx = useContext(WorkspaceContext)!
    const tasks = useTaskStore()
    const readOnly = [WorkspaceMode.Execute, WorkspaceMode.Read].includes(workspaceCtx.mode)
    console.log(workspaceCtx.mode, readOnly)

    useEffect(() => {
        tasks.load(workspaceCtx.orgId)
    }, [])

    const runbook = workspaceCtx.activeRunbook
    if (!runbook) return <></>
    // const workflow = workflows.workflowsByRef.get(workflows.refToKey(runbook.workflowRef))
    const workflow = workspaceCtx.activeWorkflow
    const step = workflow?.selectedStep
    if (!step) return <></>

    return <StepSettings step={step} key={step as any} />
})


interface StepSettingProps extends React.PropsWithoutRef<{}> {
    step: Step
}


const StepSettings = observer(({step}: StepSettingProps) => {
    const workflows = useWorkflowStore()
    const workspaceCtx = useContext(WorkspaceContext)!
    const readOnly = [WorkspaceMode.Execute, WorkspaceMode.Read].includes(workspaceCtx.mode)

    const workflow = step.workflow!

    const [isDirty, setIsDirty] = useState(false)
    const [originalStep, setOriginalStep] = useState(step.toDefinition())
    const [vm, setVm] = useState(AbstractStep.FromSpec(step.key, originalStep, workflow))

    const [uploads] = useState(() => new UploadStore() )

    /** Clone the step to create an edit view model */
    useEffect(() => {
        setOriginalStep(step.toDefinition())
        setVm(AbstractStep.FromSpec(step.key, step.toDefinition(), workflow))
        setIsDirty(false)
    }, [step, step.localRev])

    const reset = () => {
        const resetStep = AbstractStep.FromSpec(step.key, originalStep, workflow)
        setVm(resetStep)
        console.log('reset step', resetStep)
        setIsDirty(false)
    }

    return (
        <div className='h-full sw-scroller--slim p-3'>
            <StepName name={vm.displayName}  size="xxl"/>
            <SwSelect
                isReadonly={readOnly}
                label="Type"
                selectedKey={stepType(vm)}
                onSelectionChange={(e) => {
                    // const newStep = step.toType(e as StepType)
                    const newStep = convertStep(step, e as string)
                    setVm(newStep)
                    setIsDirty(true)
                }}
            >
                {/* <Item id={StepType.Task}>{StepType.Task}</Item> */}
                <StepSelectItem id="Confirmation" label="Confirmation" icon={IconHandClick}/>
                <StepSelectItem id="Approval" label="Approval" icon={IconHandClick}/>
                <StepSelectItem id="HttpReq" label="HTTP Request" icon={IconWebhook}/>
            </SwSelect>

            <ConcreteStepSettings
                readonly={workspaceCtx.mode != WorkspaceMode.Write}
                step={vm}
                onSubmit={(newStep) => {
                    workflow.replaceStep(step, newStep)
                    workflows.save(workflow)
                }}
                isDirty={isDirty}
                setIsDirty={setIsDirty}
                reset={reset}
            />

            {(workspaceCtx.mode == WorkspaceMode.Execute) && <StepState step={step!}/>}

            {/* {execution && <div className="w-full h-96">
                <StepAttachments step={step} />
                <FileUploader
                    mediaTypes={[...ImageTypes, MediaType.Gzip]}
                    uploader={uploads}
                    onDrop={async (e) => {
                        console.log(e)
                        for (const item of e.items) {
                            if (item.kind === 'directory') {
                                for await (const entry of item.getEntries()) {
                                    console.log(entry)
                                }
                            } else if (item.kind === 'file') {
                                console.log("Drop", item)
                                await uploads.upload(new StepWiseUpload(await item.getFile()!, async ({file, fileInfo, onProgress}) => {
                                    const resp = await workflows.createStepAttachment(
                                        step,
                                        file,
                                        {onUploadProgress: (evt) => {
                                            console.log("Progress", evt)
                                            onProgress(evt)
                                        }}
                                    )
                                    console.log(resp)
                                }))
                            }
                        }}
                    }
                />
            </div>} */}

            {/* {step instanceof PromptStep && workspaceCtx.mode == WorkspaceMode.Execute && Array.from(step.prompts.entries()).map( ([key, prompt]) => (<>
                <div className="text-lg font-semibold">Prompts</div>
                <div className="rounded border border-black p-2">
                    <SwTextField
                        label="Type"
                        value={prompt.type}
                        isDisabled={true}
                    />
                    <SwTextField
                        label="Name"
                        value={prompt.displayName}
                        onChange={e => prompt.name = e}
                        isDisabled={readOnly}
                    />
                    {step.status == StepStatus.Running &&
                    <SwButton variant="primary" onPress={() => workflows.response(workflow.orgId, workflow.state!.executionId!, step.key, key, true)}>
                        <SwText>Reply</SwText>
                    </SwButton>}
                </div>
            </>))} */}
        </div>
    )
})

export interface ConcreteStepSettingsProps extends React.PropsWithoutRef<{}> {
    readonly: boolean
    step: Step
    onSubmit: (data: Step) => void
    isDirty: boolean
    setIsDirty: (isDirty: boolean) => void
    reset: () => void
}

export const ConcreteStepSettings = observer((props: ConcreteStepSettingsProps) => {
    const {step, ...otherProps} = props
    if (step instanceof InputStep && stepType(step) == 'Approval') return <ApprovalStepSetting {...otherProps} step={step}/>
    else if (step instanceof InputStep) return <PromptStepSetting {...otherProps} step={step}/>
    else if (step instanceof TaskStep) return <TaskStepSettings {...otherProps} step={step} />
    else throw new Error("Unknown step type")
})

const StepAttachments = observer(({step}: {step: AbstractStep}) => {
    const attachments = useExecutionAttachmentStore()
    const wf = step.workflow

    console.log(attachments.byStepId.get(step.id))

    useEffect(() => {
        if (!wf) return
        attachments.load(wf.orgId, wf.state!.executionId!)
    }, [wf])

    return <>
        {attachments.byStepId.get(step.id)?.map(a => <div>{a.file.fileName} {humanByteSize(a.file.size)}</div>)}
    </>
})

function humanByteSize(size: number) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
    let unit = 0
    while (size > 1024) {
        size /= 1024
        unit++
    }
    return `${size.toFixed(2)} ${units[unit]}`
}


interface StepSelectItemProps {
    id: string
    label: string
    icon: (props: TablerIconsProps) => React.ReactElement
}

function StepSelectItem({id, label, icon}: StepSelectItemProps) {
    return <>
        <SwSelectItem id={id}><div className='flex items-center'><SwIcon icon={icon}/><span className='pl-1'>{label}</span></div></SwSelectItem>
    </>
}

function convertStep(step: Step, type: string) {
    const def = step.toDefinition()

    if (type == 'Confirmation') {
        def.type = StepType.Input
        def.inputs = {}
    }

    if (type == 'Approval') {
        def.type = StepType.Input
        def.review = {users: []}
    }

    if (type == 'HttpReq') {
        def.type = StepType.Task
        def.inputs = {}
        const taskDef = def as ITaskStep
        taskDef.task = 'HttpReq'
    }

    const newStep = AbstractStep.FromSpec(step.key, def)
    console.log('Converted')
    return newStep
}

function stepType(step: AbstractStep) {
    if (step instanceof InputStep) {
        switch (step.flavor) {
        case InputStepFlavor.Ack:
            return 'Confirmation'
        case InputStepFlavor.Approval:
            return 'Approval'
        }
    }
    if (step instanceof TaskStep) {
        return step.task
    }
    throw new Error("Unknown step type.")
}