import { Key, useContext, useEffect, useRef, useState } from 'react'

import type { ListBoxItemProps } from 'react-aria-components'
import {Text, ListBoxItem, ListBox, Label, TagGroup, TagList, ComboBox, ComboBoxProps, ComboBoxStateContext } from 'react-aria-components'
import { IconSearch, IconX } from '@tabler/icons-react'

import { SwPopover } from '../Popover/Popover'
import { SwButton } from '..'
import { SwInput } from '../TextField/Input'

import '@/components/Select/Select.css'
import { SwIcon } from '../Icon/Icon'

export interface SwTagFieldItemBase {
    id: string
    name: string
}

export interface SwTagFieldProps<T extends SwTagFieldItemBase>
    extends Omit<ComboBoxProps<T>, 'children' | 'items'> {
    items: T[]
    tagItems: T[]
    onChange?: (items: T[]) => void
    onAdd?: (keys: Set<Key>) => void
    onRemove?: (keys: Set<Key>) => void
    label?: string;
    description?: string | null;
    errorMessage?: string | null;
    children: [((item: T) => React.ReactNode), ((item: T) => React.ReactNode)];
}

export function SwTagField<T extends SwTagFieldItemBase>(
    { label, description, errorMessage, children, onChange, onSelectionChange, onInputChange, items, tagItems, ...props }: SwTagFieldProps<T>
) {

    const [selectedKey, setSelectedKey] = useState<Nullable<Key>>(null)
    const [inputValue, setInputValue] = useState("")

    // let list = useListData<T>({
    //     initialItems: [...defaultTagItems]
    // })

    const _onSelectionChange = (key: Key) => {
        if (key) {
            if (!key) return
            const item = items.find(i => i.id == key)
            const tag = tagItems.find(i => i.id == key)
            if (!item || tag) return
            tagItems.push(item)
            onChange?.([...tagItems])
        }
    }

    const _onRemove = (keys: Set<Key>) => {
        for (let i = tagItems.length - 1; i >= 0; i--) {
            if (keys.has(tagItems[i].id)) tagItems.splice(i, 1)
        }
        onChange?.([...tagItems])
    }

    const disabledKeys = tagItems.map(t => t.id)

    const ref = useRef<HTMLInputElement>(null)

    return <>
        <div>
            <Label>{label}</Label>
            <div
                className='border p-2 rounded-md overflow-hidden min-w-0 w-full flex items-center ring-1 ring-inset ring-gray-400'
                onClick={() => ref.current?.focus()}
            >
                {!props.isReadOnly && <SwIcon className='m-2' size={15} icon={IconSearch}/>}
                <ComboBox
                    isReadOnly={!!props.isReadOnly}
                    aria-labelledby='foo'
                    className='flex-grow'
                    {...props} menuTrigger='manual'
                    items={items}
                    selectedKey={null}
                    disabledKeys={disabledKeys}
                    onSelectionChange={(key) => {setInputValue(""); setSelectedKey(key); _onSelectionChange(key); onSelectionChange?.(key)}}
                    onInputChange={(value) => {onInputChange?.(value); setInputValue(value)}}
                    inputValue={inputValue}
                    // @ts-ignore
                    allowsEmptyCollection={true}
                >
                    <ABC inputValue={inputValue} selectedKey={selectedKey} />
                    <TagGroup onRemove={_onRemove} aria-label='foo' className='w-full overflow-hidden min-w-0'>
                            <TagList items={tagItems} className='flex gap-2 w-full overflow-hidden min-w-0 flex-wrap'>
                                {children[0]}
                            </TagList>
                    </TagGroup>
                    {!props.isReadOnly && <div className={`flex items-center relative ${tagItems.length && 'mt-2'}`}>
                        <SwInput ref={ref} />
                    </div>}
                    {description && <Text slot="description">{description}</Text>}
                    {errorMessage && <Text slot="errorMessage">{errorMessage}</Text>}
                    <SwPopover>
                        <div className='max-h-[inherit] p-1 sw-scroller--slim'>
                            <ListBox className="h-full overflow-y-auto">
                            {items.length == 0 ? <></> : children[1] }
                            </ListBox>
                        </div>
                    </SwPopover>
                </ComboBox>
                {!props.isReadOnly && <SwButton variant='icon' onPress={() => _onRemove(new Set(tagItems.map(i => i.id)))}><SwIcon icon={IconX}/></SwButton>}
            </div>
        </div>
    </>
}

export function SearchItem(props: ListBoxItemProps) {
    return (
    <ListBoxItem
        {...props}
        className={({ isFocused, isSelected }) =>
            `my-item ${isFocused ? 'focused' : ''} ${isSelected ? 'selected' : ''}`}
    />
    )
}

function ABC({inputValue, selectedKey}: {inputValue?: string, selectedKey: Nullable<Key>}) {
    const state = useContext(ComboBoxStateContext)

    useEffect(() => {
        if (!state) return
        if (inputValue != "")
            state.open()
        if (inputValue == "")
            state.close()
    }, [inputValue, selectedKey])

    return <></>
}