import { DateTime, Duration } from 'luxon'

import { SplitGroup } from "@/components/SplitGroup/SplitGroup"
import { ExecutionStatus, Workflow, WorkflowState } from "@/stores/WorkflowDefinition"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { ProgressBar } from 'react-aria-components'

import { SwButton } from '@/components'
import { IconArrowLeft, IconX } from '@tabler/icons-react'
import { SwIcon } from '@/components/Icon/Icon'
import { SwText } from '@/components/Text/Text'
import { useWorkspaceCtx } from '@/contexts'
import { useNavigate } from 'react-router-dom'
import { useWorkflowStore } from '@/stores/Workflow'
import './ProgressBar.css'
import { IconProgress, IconCircleCheck, IconProgressX } from "@tabler/icons-react"

export const ExecutionStatusBar = observer(({execution}: {execution: Workflow}) => {
    const workspaceCtx = useWorkspaceCtx()
    const workflows = useWorkflowStore()
    const runbook = workspaceCtx.activeRunbook!
    const runbookEx = workspaceCtx.activeRunbookExecution
    const navigate = useNavigate()
    const state = execution.state

    return <>
        <SplitGroup className="p-4">
            <div className="flex items-center">
                <SwButton onPress={() => {
                    workspaceCtx.setActiveRunbook(workspaceCtx.activeRunbook!)
                    navigate(`/${runbook.orgId}/${runbook.workspaceId}/${runbook.id}`)
                }}>
                    <SwIcon icon={IconArrowLeft}/>
                    <SwText>Exit Execution</SwText>
                </SwButton>
                <div className="flex flex-col ml-4">
                    <div className="font-mono font-[500]">#{runbookEx?.id.split('-')[0]}</div>
                    <div className="font-semibold">{execution.state?.author?.mentionName}</div>
                </div>
            </div>
            <div className='flex items-center gap-2'>

                <div className={`h-full flex justify-start font-medium rounded-full px-3 py-1 ${execution.state?.status == 'Succeeded' ? 'bg-green-700 text-white' : execution.state?.status == 'Failed' ? 'bg-red-600 text-white' : execution.state?.status == 'Running' ? 'bg-amber-500 text-white' : ''}`}>
                {execution.state?.status == ExecutionStatus.Running&& <div>
                    <IconProgress className=" mr-2 bg-amber-600 text-amber-100 rounded-full animate-spin"/>
                </div>}
                {execution.state?.status == ExecutionStatus.Succeeded && <div>
                    <IconCircleCheck className=" mr-2 bg-white text-green-600 rounded-full"/>
                </div>}
                {execution.state?.status == ExecutionStatus.Failed && <div>
                    <IconProgressX className=" mr-2 bg-white text-red-600 rounded-full"/>
                </div>}
                    {execution.state?.status}
                </div>
                {execution.state?.status == ExecutionStatus.Running && <SwButton variant="negative" onPress={() => {
                    workflows.stop(execution)
                }}>
                    <SwIcon icon={IconX}/>
                    <SwText>Stop</SwText>
                </SwButton>}
                <DurationDisplay state={execution.state!}/>
            </div>
        </SplitGroup>

        <ProgressBar aria-label='Execution duration..' isIndeterminate={state?.status == ExecutionStatus.Running}>
            <div className="bar">
                <div className={`h-full ${execution.state?.status == 'Succeeded' ? 'bg-green-700' : execution.state?.status == 'Failed' ? 'bg-red-600' : execution.state?.status == 'Running' ? 'fill' : ''}`} style={{width: '100%'}} />

            </div>
        </ProgressBar>
    </>
})

const DurationDisplay = observer( ({state}: {state: WorkflowState}) => {
    const [_, setTick] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => setTick(Date.now()) , 1000)
        return () => clearInterval(interval)
    }, [])

    const duration = friendlyDuration(state!)

    return <div className="text-center"><div className="font-mono text-xl">{duration}</div><div className="text-sm font-neutral-800">Duration</div></div>
})


function friendlyDuration(state: WorkflowState) {
    const luxStart = DateTime.fromJSDate(state.startedAt!)
    const luxEnd = DateTime.fromMillis(state.finishedAt?.getTime() || Date.now())

    const diff = luxEnd.diff(luxStart)
    const duration = Duration.fromObject(diff.toObject())

    return duration.toFormat('hh:mm:ss')
}