import { observer } from "mobx-react-lite"
import { useContext } from "react"
import { RootContext, WorkspaceContext } from "../../../../contexts.js"
import { WorkflowStepEntry } from "./WorkflowStep.js"
import { WorkspaceMode } from "@/stores/WorkspaceCtx.js"
import { toJS } from "mobx"
import { CreateStepCta } from "./CreateStepCta.js"



export const WorkflowStepList = observer(() => {
    const workspaceCtx = useContext(WorkspaceContext)!
    const rootStore = useContext(RootContext)!
    const workflows = rootStore.workflows

    // const runbook = workspaceCtx.activeRunbook
    // if (!runbook) return <></>

    const workflow = workspaceCtx.activeWorkflow
    if (!workflow) return <></>

    const edit = workspaceCtx.mode === WorkspaceMode.Write

    if (workflow.stepsById.size == 0) return <CreateStepCta/>

    return (
        <div className="flex flex-col h-full">
            {/* <div className="mb-1.5 pl-4">
                <SwButton variant="link" onPress={() => {workflow.createStep(); workflows.save(workflow)}}>
                    <span className="underline">Create</span>
                </SwButton>
            </div> */}
            <div className="flex flex-col gap-y-2 sw-scroller--slim p-2">
                {workflow.stepList.map(s =>
                    <div key={s.id} className="w-full flex-grow">
                        <WorkflowStepEntry
                            selected={workflow.selectedStep === s}
                            step={s}
                            edit={edit}
                            onPress={() => {workflow.selectedStep = s}}
                            onInsertAfter={(state, next) => {
                                workflow.insertAfter(state, workflow.stepsById.get(next)!)
                                workflows.save(workflow)}
                            }/>
                    </div>
                )}
            </div>
        </div>
    )
}) as React.FunctionComponent
WorkflowStepList.displayName = 'WorkflowStepList'
