import { observer } from "mobx-react-lite"
import { useContext } from "react"
import clsx from "clsx"

import { AlignTextCenter, AlignTextLeft, AlignTextRight, Bold, Code, CodeBlock, Embed, H1, H2, H3, Italic, BlockQuote, Strike, Alert, Table, AddRowAfter, AddColumnAfter, DeleteRow, DeleteColumn, MergeCells, HeaderRow, HeaderColumn, SplitCell, OrderedList, BulletList } from "./controls"
import { StepWiseEditorContext } from "./context"
import { SwButton } from "@/components"
import { Link } from "./controls/Link"
import { TableMenu } from "./controls/Table"


export const FormatBar = observer(() => {
    const editor = useContext(StepWiseEditorContext)

    const isCursorInStep = editor?.proseCache.obj.containingNodeTypes.has(editor.state.schema.nodes['swStep'])

    return (
        <div className={clsx("w-full flex p-1 gap-x-6 items-center", !editor?.isEditable && "hidden")}>
            <div className={clsx("w-full flex gap-x-3 sw-scroller--slim")}>
                <div className="flex gap-x-0.5">
                    <Bold/>
                    <Italic/>
                    <Strike/>
                </div>
                <div className="flex gap-x-0.5">
                    <H1/>
                    <H2/>
                    <H3/>
                </div>
                <div className="flex gap-x-0.5">
                    <BulletList/>
                    <OrderedList/>
                </div>
                <div className="flex gap-x-0.5">
                    <AlignTextLeft/>
                    <AlignTextCenter/>
                    <AlignTextRight/>
                </div>
                <div className="flex gap-x-0.5">
                    <Code/>
                    <CodeBlock/>
                    <BlockQuote/>
                    <Alert/>
                    <Link/>
                    {/* <Step/> */}
                </div>
                 <div className="flex gap-x-0.5">
                    <Embed/>
                    <TableMenu/>
                </div>
            </div>
            <SwButton variant={isCursorInStep ? 'secondary' : 'primary'} size="sm" onPress={(evt) => {editor?.tipTap?.commands.toggleSwStep()}}>
                {isCursorInStep ? 'Unwrap Step' : 'Create Step'}
            </SwButton>
        </div>

    )
})