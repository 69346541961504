import { Checkbox as AriaCheckbox } from "react-aria-components"
import { CheckboxProps as AriaCheckBoxProps } from "react-aria-components"

import './Checkbox.css'

interface CheckBoxProps extends AriaCheckBoxProps {

}

export const SwCheckbox = ({children, ...props}: CheckBoxProps) => {
    return (
        <AriaCheckbox {...props}>
            {({isIndeterminate}) => <>
                <div className="checkbox">
                    <svg viewBox="0 0 18 18" aria-hidden="true">
                    {isIndeterminate
                        ? <rect x={1} y={7.5} width={15} height={3} />
                        : <polyline points="1 9 7 14 15 4" />
                    }
                    </svg>
                </div>
                {children}
            </>}
        </AriaCheckbox>
    )
}