import clsx from "clsx"
import { ForwardedRef, forwardRef } from "react"
import { Input, InputProps } from "react-aria-components"

import './Input.css'

const Styles = {
    focusRing: 'ring-gray-300 dark:ring-gray-800 ring-2 shadow-outline',
    focus: 'focus:ring-gray-300 focus:dark:ring-gray-400 focus:ring-1',
    border: 'rounded border border-gray-300 dark:border-gray-900',
    transition: 'transition ease-in-out duration-150'
}


export interface SWInputProps extends InputProps, React.RefAttributes<HTMLInputElement> {
    withIcon?: boolean
}

export const SwInput = forwardRef(({className, withIcon, ...props}: SWInputProps, ref: ForwardedRef<HTMLInputElement>) => (
    <Input
        className={({isFocusVisible}) => clsx(
            isFocusVisible ? Styles.focusRing : Styles.focus,
            Styles.border,
            Styles.transition,
            "relative bg-white dark:bg-zinc-800 dark:text-gray-200 appearance-none w-full text-gray-700 leading-tight",
            "form-input bg-transparent py-1 w-full outline-none rounded",
            withIcon ? "px-10" : "px-4",
            className)
        }
        {...props}
        ref={ref}
    />
))