import { EditorState } from "prosemirror-state"
import { IDoc } from "./DocStore"
import { EditorView } from "prosemirror-view"
import { CacheState, cacheKey } from "@/widgets/Editor/plugins/ProseCache"
import { Editor } from "@tiptap/react"
import { SWTipTapEditor } from "@/widgets/Editor/StepWiseEditor"

export class StepWiseEditor {
    readonly doc: IDoc
    readonly tipTap: Editor
    readonly proseCache: CacheState

    state: EditorState
    view: EditorView


    get isEditable() {
        return this.tipTap.isEditable
    }

    constructor(doc: IDoc, tipTap: SWTipTapEditor) {
        this.doc = doc
        this.tipTap = tipTap
        this.state = this.tipTap.state
        this.view = this.tipTap.view
        const cacheState = cacheKey.getState(this.state)


        if (!cacheState) throw new Error('StepWiseEditor requires the pm-cache Prosemirror plugin')

        this.proseCache = cacheState
    }

    setCursorAtCoords(x: number, y: number) {
        const rect = this.view.dom.getBoundingClientRect()

        const yClamped = Math.min(Math.max(y, rect.top), rect.bottom)
        const xClamped = Math.min(Math.max(x, rect.left), rect.right)

        console.log(xClamped, yClamped)

        const pos = this.view.posAtCoords({left: xClamped, top: yClamped})

        console.log(pos, rect)
        this.tipTap.chain().focus().setTextSelection(pos!.pos).run()
    }

    destroy() {
        this.tipTap.destroy()
    }
}