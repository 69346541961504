import { createContext, useContext, Context } from "react"

export const createUseCtx = <T>(): [Context<T | null>, () => T] => {
    const context = createContext<T | null>(null)

    return [context, () => {
        const ctx = useContext(context)

        if (!ctx) throw new Error('Must call in context')

        return ctx
    }]
}