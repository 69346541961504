import { WorkflowStepInfo } from '@/components/Workflow/StepInfo'
import { AbstractStep } from '@/stores/WorkflowDefinition'
import { WorkspaceMode } from '@/stores/WorkspaceCtx'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { Handle, Position } from 'reactflow'

const handleStyle = { left: 10 }

export const StepNode = observer( ({ data }: any) => {
    const onChange = useCallback((evt: any) => {
        console.log(evt.target.value)
    }, [])

    const step = data.step as AbstractStep

    return (<>
        <Handle type="target" position={Position.Top} />
        <div className="rounded bg-slate-200 border border-black p-1">
            <WorkflowStepInfo mode={WorkspaceMode.Read} step={step} />
        </div>
        <Handle type="source" position={Position.Bottom} id="a" />
    </>)
})