import { observer } from "mobx-react-lite"
import { useLayoutEffect, useRef } from "react"

import * as echarts  from 'echarts'
import { EChartsOption } from 'echarts'
import { Theme, useThemeCtx } from "@/stores/Theme"
import { useResizeObserver } from "@react-spectrum/utils"


export interface EChartProps {
    option: EChartsOption
}

export const EChart = observer(({option}: EChartProps) => {
    const theme = useThemeCtx()
    const ref = useRef<HTMLDivElement>(null)
    const eChartRef = useRef<echarts.EChartsType|null>(null)

    useResizeObserver({ref, onResize: () => {eChartRef.current?.resize()}})

    useLayoutEffect(() => {
        eChartRef.current = echarts.init(ref.current!, theme.theme == Theme.dark ? 'dark' : 'light', {useCoarsePointer: true})
        eChartRef.current.setOption(option)
        return () => echarts.dispose(ref.current!)
    }, [theme.theme, option])

    return <div className="h-full w-full overflow-hidden" ref={ref} />
})