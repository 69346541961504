import { OverlayTriggerProps } from '@react-types/overlays'
import { Heading, Separator } from 'react-aria-components'
import { SwButton } from '../Button/Button'
import { Modal } from '../Modal'
import { chain } from 'react-aria'

import './AlertDialog.css'
import { Content } from '../Content/Content'
import { Dialog, DialogProps } from '../Dialog/Dialog'

export interface SwAlertDialogProps extends DialogProps, OverlayTriggerProps {
    variant?: 'confirmation' | 'destructive'
    title?: string
    primaryActionLabel?: string
    secondaryActionLabel?: string
    onPrimaryAction?: () => void
    onSecondaryAction?: () => void
    onCancel?: () => void
    isOpen?: boolean
    children?: React.ReactNode
}

export const SwAlertDialog = (props: SwAlertDialogProps) => {

    const {
        variant = 'confirmation',
        title = 'Alert',
        primaryActionLabel = 'OK',
        secondaryActionLabel = 'Cancel',
        onPrimaryAction,
        onSecondaryAction,
        children,
        onOpenChange,
        isOpen = false,
        ...dialogProps
    } = props

    const buttonVariant = variant == 'destructive' ? 'negative' : 'primary'

    return (
        <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
            <Dialog className='sw-AlertDialog' {...dialogProps}>
                {({close}) => <>
                    <Heading slot="title">{title}</Heading>
                    <Separator slot="separator"/>
                        <Content>{children}</Content>
                    <div className="mt-8 flex flex-row-reverse gap-x-4">
                        <SwButton onPress={() => chain(close?.(), onSecondaryAction?.())}>{secondaryActionLabel}</SwButton>
                        <SwButton variant={buttonVariant} onPress={() => chain(close?.(), onPrimaryAction?.())}>{primaryActionLabel}</SwButton>
                    </div>
                </>}
            </Dialog>
        </Modal>
    )
}