import { PropsWithChildren, useContext, useEffect, useMemo } from "react"
import { Shell } from "./Shell"
import { createSearchParams, useLocation, useMatches, useNavigate, useNavigationType, useParams } from "react-router-dom"
import { OrgContext, WorkspaceContext } from "@/contexts"
import { RoutePath } from "@/routes"
import { useAppStore } from "@/stores/AppStore"
import { Action } from "@remix-run/router"


interface Props extends PropsWithChildren {
    routePath?: RoutePath
}

export const Wrapper = ({children, routePath}: Props) => {
    const orgCtx = useContext(OrgContext)!
    const app = useAppStore()
    const workspaceCtx = useContext(WorkspaceContext)!
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const navType = useNavigationType()

    useEffect(() => {
        if (!orgCtx.orgId) {
            navigate({
                pathname: '/auth/login',
                search: createSearchParams({
                    continueTo: location.pathname
                }).toString()
            })
        }
    }, [])

    useMemo(() => {
        console.timeEnd("Navigate")

        if (navType != Action.Pop) return

        if (routePath == RoutePath.WorkspaceActivity)
            app.setScreen('activity')

        if (params.org) orgCtx.orgId = Number(params.org)
        if (routePath == RoutePath.EditRunbook) {
            if (params.runbook) workspaceCtx.loadActiveRunbook(params.runbook)
        } else if (routePath == RoutePath.Execution) {
            if (params.execution)
                workspaceCtx.loadActiveRunbookExecution(params.execution)
        }
    }, [params, location])

    return <><Shell/></>
}