import { SwAlertDialog } from "@/components/AlertDialog/AlertDialog"
import { SwButton } from "@/components/Button"
import { Dialog } from "@/components/Dialog/Dialog"
import { Icon } from "@/components/Icon"
import { SwMenu, SwMenuItem, SwMenuTrigger } from "@/components/Menu/Menu"
import { Modal } from "@/components/Modal"
import { SwPopover } from "@/components/Popover/Popover"
import { SchemaForm } from "@/components/SchemaForm/SchemaForm"
import { SwText } from "@/components/Text/Text"
import { RootContext, useWorkspaceCtx } from "@/contexts"
import { Runbook } from "@/stores/Runbook"
import { WorkspaceCtx } from "@/stores/WorkspaceCtx"
import { RJSFSchema, UiSchema } from "@rjsf/utils"
import validator from '@rjsf/validator-ajv8'
import { IconCursorText, IconDots, IconPencil, IconTrash } from "@tabler/icons-react"
import { observer } from "mobx-react-lite"
import { createViewModel } from "mobx-utils"
import { Key, useContext, useState } from "react"
import { Heading, Separator } from "react-aria-components"
import { MenuTriggerProps } from "react-stately"

export interface RunbookItemMenuProps extends MenuTriggerProps {
    runbook: Runbook
    workspace: WorkspaceCtx
    isHidden?: boolean
}

export const RunbookItemMenu = observer(({runbook, workspace, isHidden = false, ...otherProps}: RunbookItemMenuProps) => {
    const [isEditOpen, setIsEditOpen] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)

    const canCreateChild = (runbook.ancestors.length < 2)

    const handleSelect = (id: Key) => {
        if (id == 'rename') {
            setIsEditOpen(true)
        }
        if (id == 'delete') {
            setIsDeleteOpen(true)
        }

        if (id == 'create') {
            workspace.createRunbook(runbook)
        }
    }

    return (
    <SwMenuTrigger {...otherProps}>
        <SwButton isQuiet isHidden={isHidden}><Icon icon={IconDots}/></SwButton>
        <SwPopover>
            <div className="p-2">
                <SwMenu autoFocus={true} onAction={handleSelect}>
                    <SwMenuItem id="rename">
                        <Icon icon={IconCursorText}/>
                        <SwText slot="label">Rename</SwText>
                    </SwMenuItem>
                    {canCreateChild && <SwMenuItem id="create">
                        <Icon icon={IconPencil}/>
                        <SwText slot="label">Create Child</SwText>
                    </SwMenuItem>}
                    <SwMenuItem id="delete" data-danger="true">
                        <Icon icon={IconTrash}/>
                        <SwText slot="label">Delete</SwText>
                    </SwMenuItem>
                </SwMenu>
            </div>
        </SwPopover>
        {isEditOpen && <RunbookRenameModal isOpen={isEditOpen} runbook={runbook} close={() => setIsEditOpen(false)}/>}
        {isDeleteOpen && <RunbookDeleteModal isOpen={isDeleteOpen} runbook={runbook} close={() => setIsDeleteOpen(false)}/>}
    </SwMenuTrigger>)
})

const schema: RJSFSchema = {
    type: 'object',
    required: [
        "name",
    ],
    properties: {
        name: {
            type: "string",
            title: "Name"
        },
    }
}

const uiSchema: UiSchema = {
    name: {
        "ui:autofocus": true,
    },
    "ui:submitButtonOptions": {
        submitText: "Save"
    }
}

interface RunbookActionProps {
    isOpen?: boolean
    runbook: Runbook
    close?: () => void
}

export const RunbookDeleteModal = observer(({isOpen, runbook, close}: RunbookActionProps) => {
    const workspace = useWorkspaceCtx()

    const onSubmit = () => {
        workspace.deleteRunbook(runbook)
    }

    return (
        <SwAlertDialog
            variant="destructive"
            onOpenChange={close}
            title="Delete runbook"
            isOpen={isOpen}
            primaryActionLabel="Delete"
            secondaryActionLabel="Cancel"
            onPrimaryAction={onSubmit}
            onSecondaryAction={close}>
            Are you sure you would like to delete this runbook? Deleting the runbook will also
            delete all of its associated [stuff].
        </SwAlertDialog>
    )
})


export const RunbookRenameModal = observer(({isOpen, runbook, close}: RunbookActionProps) => {
    const [vm] = useState(createViewModel(runbook))
    const formData = { name: vm.name }

    const runbooks = useContext(RootContext)!.runbooks

    const onSubmit = (data: Partial<Runbook>) => {
        runbooks.save(vm).then(() => vm.submit())
    }

    return (
        <Modal isOpen={isOpen} isDismissable isKeyboardDismissDisabled onOpenChange={close}>
            <Dialog>
                {({close}) => <>
                    <Heading slot="title">Rename</Heading>
                    <Separator slot="separator"/>
                    <SchemaForm
                        schema={schema}
                        uiSchema={uiSchema}
                        formData={formData}
                        onChange={(e, id) => Object.assign(vm, e.formData)}
                        onSubmit={({formData}) => {onSubmit(formData); close()}}
                        validator={validator}
                    >
                        <div className="mt-8 flex flex-row-reverse gap-x-4">
                            <div><SwButton onPress={close}>Cancel</SwButton></div>
                            <div><SwButton variant="primary" isDisabled={!vm.isDirty} type="submit">Save</SwButton></div>
                        </div>
                    </SchemaForm>
                </>}
            </Dialog>

        </Modal>
    )
})