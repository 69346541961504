import { FileInfoDto, StepWise } from "@/client"
import {Plugin, PluginKey} from "prosemirror-state"
import {Decoration, DecorationSet} from "prosemirror-view"

const imageUploadKey = new PluginKey<DecorationSet>("imageUpload")

export interface ImageUploadConfig {
    upload: (file: File) => Promise<FileInfoDto>
}

export const imageUpload = (config: ImageUploadConfig) => new Plugin<DecorationSet>({
    state: {
        init() { return DecorationSet.empty },
        apply(tr, set) {
            // Adjust decoration positions to changes made by the transaction
            set = set.map(tr.mapping, tr.doc)
            // See if the transaction adds or removes any placeholders
            let action = tr.getMeta(imageUploadKey)
            if (action && action.add) {
                let widget = document.createElement("placeholder")
                let deco = Decoration.widget(action.add.pos, widget, {id: action.add.id})
                set = set.add(tr.doc, [deco])
            } else if (action && action.remove) {
                set = set.remove(set.find(undefined, undefined,
                    spec => spec.id == action.remove.id))
            }
            return set
        }
    },
    props: {
        decorations(state) { return this.getState(state) },
        handlePaste(view, evt) {
            console.log('Plugin Paste!!!', evt, evt.clipboardData?.getData("text/html"))
            const items = evt.clipboardData?.items!

            for (let i = 0; i < items?.length; i++) {
                if (items[i].kind === 'file') {
                    console.log(items[i])
                    const file = items[i].getAsFile()
                    if (!file) return

                    config.upload(file).then(info => {
                        const tr = view.state.tr
                        if (tr.selection.empty) tr.deleteSelection()
                        tr.replaceWith(tr.selection.head, tr.selection.head, view.state.schema.nodes.swImage.create({id: info.id}))
                        view.dispatch(tr)
                    })

                    return true

                    // file?.arrayBuffer().then( buffer => {
                    //   sw.fileCreateInOrg(1, file.type, buffer.byteLength, {data: buffer}).then(res => {
                    //     const tr = view.state.tr
                    //     if (tr.selection.empty) tr.deleteSelection()
                    //     tr.replaceWith(tr.selection.head, tr.selection.head, view.state.schema.nodes.image.create({src: res.url}))
                    //     view.dispatch(tr)
                    //     console.log(res)

                    //   })

                // })
                }
            }
        }
    },
})