import { EditorState, Transaction } from "prosemirror-state"
import * as SignalR from "@microsoft/signalr"

import { Commit, CommitJSON, collabKey, CollabState, receiveCommitTransaction, initCollabState, NodeJSON, chainCommitTransaction } from '@stepwisehq/prosemirror-collab-commit/collab-commit'
import { IDoc } from "@/stores/DocStore"


const DocHubPath = '/hub/doc'

export interface IStateProvider {
    getState(): EditorState
    dispatch(tr: Transaction): void
}

export interface IDocMaterial {
    version: number
    baseDoc: NodeJSON
    commits: DocCommit[]
}

export interface DocCommit extends CommitJSON {
    readonly docId: string
}

export const enum DocMode {
    Read = "Read",
    Write = "Write"
}

export class HubClient {
    commit?: Commit
    buffer = new CommitBuffer()
    hub: SignalR.HubConnection

    get editor() { return this.stateProvider.getState() }

    constructor(
        readonly doc: IDoc,
        readonly stateProvider: IStateProvider,
        readonly mode: DocMode = DocMode.Read
    ) {

        const modeStr = mode == DocMode.Read ? 'read' : 'write'

        this.hub = new SignalR.HubConnectionBuilder()
            .withUrl(`${DocHubPath}?docId=${doc.id}&version=${doc.version}&mode=${modeStr}`, {
                transport: SignalR.HttpTransportType.WebSockets,
                skipNegotiation: true
            })
            .withAutomaticReconnect()
            .build()

        this.hub.on('Commit', this.handleCommit)
        this.hub.on('Init', this.handleInit)
        this.hub.start().then(this.onStart)
    }

    async close() {
        return this.hub.stop()
    }

    update() {
        const collabState = collabKey.getState(this.editor)
        if (!collabState) throw new Error('Editor must be configured with collab-commit plugin!')

        const commit = collabState.getCommit()
        if (!commit) return
        if (commit !== this.commit) this.commit = commit

        // @ts-ignore
        if (!window.pause)
            this.sendCommit(commit).catch(e => {console.error(e)})
    }

    async sendCommit(commit: Commit) {

        const runbookCommit: DocCommit = {
            docId: this.doc.id,
            ...commit.toJSON()
        }

        return this.hub.send('Commit', runbookCommit)
    }

    onStart = async () => {
        // await this.hub.send('Subscribe', this.docId)
        if (this.mode == DocMode.Write) await this.sync()
    }

    async sync() {
        const collabState = collabKey.getState(this.editor)
        const commits = await this.hub.invoke<DocCommit[]>('Sync', this.doc.id, collabState?.nextVersion)

        this.doCommit(commits)
    }

    async init(id: string, version: number, mode: string) {
        const doc = await this.hub.invoke<IDocMaterial>('Init', id, version, mode)
        this.handleInit(doc)
    }

    handleInit = (doc: IDocMaterial) => {
        const tr = initCollabState(this.editor, doc.version, doc.baseDoc)
        const preparedCommits = this.prepareCommits(doc.commits)
        preparedCommits.forEach(c => c.steps.forEach(s => tr.maybeStep(s)))
        this.stateProvider.dispatch(tr)
    }

    handleCommit = (commits: DocCommit[]) => {
        try {
            this.doCommit(commits)
            this.update()
        } catch(e) {console.error(e)}
    }

    private doCommit(commits: DocCommit[]) {
        const preparedCommits = this.prepareCommits(commits)

        const firstCommit = preparedCommits.shift()

        if (!firstCommit) return

        try {
            // Buffer the rest of the commits as they will get applied when apppropriate later.
            // Not the most efficient way but it's plenty fast on 100+ commits.
            preparedCommits.forEach(c => {
                this.buffer.bufferCommit(this.editor, c)
            })
            let tr = this.buffer.receiveCommit(this.editor, firstCommit, {mapSelectionBackward: true})
            if (tr) this.stateProvider.dispatch(tr)
            if (this.buffer.commits.size > 0) this.sync()
            // let tr = receiveCommitTransaction(this.editor, firstCommit, {mapSelectionBackward: true})
            // console.log('Chaining commits')
            // preparedCommits.forEach(c => {
            //     console.log('Chaining commits')
            //     tr = chainCommitTransaction(this.editor, tr, c, {mapSelectionBackward: true})
            // })
            // console.log(`Dispatching ${preparedCommits.length} chained commits`)
            // this.stateProvider.dispatch(tr)
        } catch(e) {
            console.error(e)
            throw e
        }

    }

    private prepareCommits(commits: DocCommit[]): Commit[] {
        return commits.map(c => {
            return Commit.FromJSON(this.editor.schema, {
                version: c.version,
                ref: c.ref,
                steps: c.steps
            })
        })
    }
}


class CommitBuffer {
    commits = new Map<number, Commit>()

    constructor() {}

    receiveCommit(
        state: EditorState,
        commit: Commit,
        options?: { mapSelectionBackward?: boolean }
    ) {
        // const collabState = collabKey.getState(state) as CollabState
        const shouldProcess = this.bufferCommit(state, commit)
        if (shouldProcess) {
            let tr = receiveCommitTransaction(state, commit, options)
            tr = this.drainCommits(state, tr, options)
            return tr
        }
    }

    /** Drain commit buffer into the provided transaction */
    private drainCommits(state: EditorState, tr: Transaction, options?: {mapSelectionBackward?: boolean}) {
        let collabState = tr.getMeta(collabKey)
        let nextCommit: Commit | undefined
        while (nextCommit = this.commits.get(collabState.nextVersion)) {
            this.commits.delete(collabState.nextVersion)
            tr = chainCommitTransaction(state, tr, nextCommit, options)
            collabState = tr.getMeta(collabKey)
        }
        return tr
    }

    bufferCommit(state: EditorState, commit: Commit) {
        const collabState = collabKey.getState(state) as CollabState
        if (commit.version == collabState.nextVersion) return true
        if (commit.version < collabState.nextVersion) return false
        if (commit.version > collabState.nextVersion) {
            this.commits.set(commit.version, commit)
            return false
        }
    }
}
