import { StepWise, WorkflowDto, WorkflowRef } from "@/client"
import { makeAutoObservable, toJS } from "mobx"
import { BulkResponse, AbstractStep, Workflow, Review } from "./WorkflowDefinition"
import { createUseStore } from "./util"
import { TransferProgressEvent } from "@azure/core-rest-pipeline"
import { OperationRequestOptions } from "@azure/core-client"
import { ExecutionAttachmentStore } from "./AttachmentStore"
import { EvtHandler } from "@/util/events"


export interface IWorkflow {
    id: number
    name: string
    steps: IWorkflowStep[]
}


export interface IWorkflowStep {
    id: number
    name: string
    description?: string
}


const Sample = {
    id: 0,
    name: 'Hello World',
    steps: [
        {
            id: 0,
            name: 'Step One'
        },
        {
            id: 1,
            name: 'Step Two'
        }
    ]
} satisfies IWorkflow


export class WorkflowStore {
    workflows: Workflow[] = []
    byRef = new Map<string, Workflow>()

    private eventTarget: WorkflowStoreEventTarget = new WorkflowStoreEventTarget

    constructor(
        readonly sw: StepWise,
        readonly attachments: ExecutionAttachmentStore
    ) {
        makeAutoObservable(this)
    }

    async save(workflow: Workflow) {
        // const dto = workflow.toWorkflowDto()
        // await this.sw.workflowUpdate(workflow.orgId, dto.ref.id, dto)
    }

    async execute(workflow: Workflow) {
        const execution = await this.sw.executionCreate(1, workflow.ref!.id)
        workflow.updateFromExecutionDto(execution)
    }

    add(workflow: Workflow) {
        const key = this.refToKey(workflow.ref!)
        if (this.byRef.has(key)) return this.byRef.get(key)
        this.workflows.push(workflow)
        this.byRef.set(key, workflow)
        this.eventTarget.dispatchEvent(new CustomEvent(WorkflowEvent.Loaded, {detail: workflow}))
        workflow.onDidChange((evt) => this.eventTarget.dispatchEvent(new CustomEvent(WorkflowEvent.Changed, {detail: evt.detail})))
        return workflow
    }

    async load(org: number, id: string, revision?: number) {
        const workflowDto = await this.sw.workflowGet(org, id, {revision})

        const workflow = Workflow.FromWorkflowDto(workflowDto)
        return this.add(workflow)
    }

    async loadExecution(orgId: number, executionId: string) {
        const executionDto = await this.sw.executionGet(executionId)
        const wf = this.workflows.find(wf => wf.is(executionDto.workflow!))
        wf?.updateFromExecutionDto(executionDto)
    }

    async createAttachment(wf: Workflow, file: File, opts?: OperationRequestOptions) {
        return await this.attachments.createAttachment(file, wf.orgId, wf.state!.executionId!, null, opts)
    }

    async stop(wf: Workflow) {
        const executionId = wf.state?.executionId!
        await this.sw.executionStop(wf.orgId, executionId)
        const executionDto = await this.sw.executionGet(executionId)
        wf.updateFromExecutionDto(executionDto)
    }

    async createStepAttachment(step: AbstractStep, file: File, opts?: OperationRequestOptions) {
        const wf = step.workflow!
        return await this.attachments.createAttachment(file, wf.orgId, wf.state!.executionId!, step.id, opts)
    }

    async createAttachmentByIds(...args: Parameters<ExecutionAttachmentStore['createAttachment']>) {
        return await this.attachments.createAttachment(...args)
    }

    async loadAttachments(orgId: number, executionId: string) {
        const wf = this.workflows.find(wf => wf.state!.executionId === executionId)
        if (!wf) return
        const attachments = await this.sw.executionAttachmentList(orgId, executionId)
        wf.attachments = attachments
        for (const attachment of attachments) {
            const step = wf.stepsById.get(attachment.stepId)
            if (!step) continue

            step.addAttachment(attachment)
        }
    }

    /** Update workflow with data from execution. */
    async refresh(wf: Workflow, executionId: string) {
        const executionDto = await this.sw.executionGet(executionId)
        wf.updateFromExecutionDto(executionDto)
    }

    async response(orgId: number, executionId: string, step: string, input: string, value: any) {
        // await this.sw.executionResponseCreate(orgId, executionId, {
        //     step,
        //     input,
        //     value
        // })
        const executionDto = await this.sw.executionGet(executionId)
        const workflow = this.byRef.get(this.refToKey(executionDto.workflow!))
        workflow?.updateFromExecutionDto(executionDto)
    }

    async bulkResponse(wf: Workflow, resp: BulkResponse) {
        const executionId = wf.state?.executionId!
        await this.sw.executionResponseBulkCreate(wf.orgId, wf.state?.executionId!, resp)
        const executionDto = await this.sw.executionGet(executionId)
        wf.updateFromExecutionDto(executionDto)
    }

    async review(wf: Workflow, review: Review) {
        const executionId = wf.state?.executionId!
        await this.sw.executionReviewCreate(executionId, review)
        const executionDto = await this.sw.executionGet(executionId)
        wf.updateFromExecutionDto(executionDto)
    }

    async refreshWorkflow(wf: Workflow) {
        const executionId = wf?.state?.executionId
        if (!executionId) return
        const executionDto = await this.sw.executionGet(executionId)
        wf.updateFromExecutionDto(executionDto)
    }

    getByRef(ref: WorkflowRef): Nullable<Workflow> {
        if (ref.revision == 0) {
            let wf: Nullable<Workflow> = null
            this.workflows.forEach(w => { if (w.ref.id == ref.id && w.ref.revision >= (wf?.ref.revision ?? 0)) wf = w } )
            return wf
        }
        return this.byRef.get(this.refToKey(ref)) ?? null
    }

    refToKey(ref: WorkflowRef) {
        return `${ref.id}-${ref.revision}`
    }

    onDidChangeMode(fn: EvtHandler<WorkflowLoadedEvt>): () => void {
        this.eventTarget.addEventListener(WorkflowEvent.Loaded, fn)
        return () => this.eventTarget.removeEventListener(WorkflowEvent.Loaded, fn)
    }

    onDidChange(handler: EvtHandler<WorkflowChangedEvt>) {
        this.eventTarget.addEventListener(WorkflowEvent.Changed, handler)
        return () => this.eventTarget.removeEventListener(WorkflowEvent.Changed, handler)
    }
}
export const [WorkflowContext, useWorkflowStore] = createUseStore(WorkflowStore)

export enum WorkflowEvent {
    Loaded = 'Loaded',
    Changed = 'Changed'
}


export interface WorkflowChange {
    workflow: Workflow
    steps: AbstractStep[]
}

export interface WorkflowLoadedEvt extends CustomEvent<Workflow> {}
export interface WorkflowChangedEvt extends CustomEvent<WorkflowChange> {}


interface WorkflowStoreEventTarget extends EventTarget {
    addEventListener(type: WorkflowEvent.Loaded, listener: EvtHandler<WorkflowLoadedEvt>, options?: AddEventListenerOptions | boolean): void
    removeEventListener(type: WorkflowEvent.Loaded, listener: EvtHandler<WorkflowLoadedEvt>, options?: AddEventListenerOptions | boolean): void
    addEventListener(type: WorkflowEvent.Changed, listener: EvtHandler<WorkflowChangedEvt>, options?: AddEventListenerOptions | boolean): void
    removeEventListener(type: WorkflowEvent.Changed, listener: EvtHandler<WorkflowChangedEvt>, options?: AddEventListenerOptions | boolean): void
    // addEventListener(type: string, callback: EventListenerOrEventListenerObject | null, options?: AddEventListenerOptions | boolean): void
    // removeEventListener(type: string, callback: EventListenerOrEventListenerObject | null, options?: AddEventListenerOptions | boolean): void
}
class WorkflowStoreEventTarget extends EventTarget {}