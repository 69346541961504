import { SplitGroup } from "@/components/SplitGroup/SplitGroup"
import { ConcreteStepSettingsProps, PromptSchema, StepSchema, StepUiSchema } from "../StepSettings"
import { AbstractStep, TaskStep } from "@/stores/WorkflowDefinition"
import { observer } from "mobx-react-lite"
import { useTaskStore } from "@/stores/Tasks"
import { cloneDeep, merge } from "lodash-es"
import { runInAction, toJS } from "mobx"
import { SchemaForm } from "@/components/SchemaForm/SchemaForm"
import validator from '@rjsf/validator-ajv8'
import { diff } from "deep-diff"
import { SwButton } from "@/components"
import { extendUiSchemaWithSchemaAnnotations } from "@/components/SchemaForm/util"

interface TaskStepSettingsProps extends Omit<ConcreteStepSettingsProps, 'step'> {
    step: TaskStep
}

export const TaskStepSettings = observer((props: TaskStepSettingsProps) => {
    const {step, onSubmit, isDirty, setIsDirty, reset, readonly} = props
    const tasks = useTaskStore()

    console.log("Task settings for", step)

    const task = tasks.tasks.find(t => t.name == step.task)

    console.log(toJS(task))

    if (!task) return <></>

    const stepSchema = merge(cloneDeep(StepSchema), {
        $defs: task.inputSchema?.$defs,
        properties: {
            parameters: task.inputSchema,
            // prompts: cloneDeep(PromptSchema)
        }
    })

    const formData = {
        name: step.name,
        comment: step.comment,
        parameters: cloneDeep(toJS(step.parameters)),
    }

    console.log("Schema", stepSchema)

    return <>
        <SchemaForm
            schema={stepSchema}
            uiSchema={StepUiSchema}
            formData={formData}
            validator={validator}
            liveOmit={true}
            omitExtraData={true}
            readonly={readonly}
            onChange={(e, id) => {
                if (!id) return
                console.log(id)
                console.time("Update")
                const diffs = diff(formData, e.formData)
                updateTaskStep(step, e.formData)
                if (diffs) setIsDirty(true)
                console.timeEnd("Update")
            }}
            onSubmit={({formData}) => {
                onSubmit(step)
                setIsDirty(false)
            }}
        >
            <div className="mt-4">
                <SplitGroup >
                    <div>{isDirty && <SwButton onPress={() => reset()}>Reset</SwButton>}</div>
                    <div>{isDirty && <SwButton type="submit" variant="cta">Save</SwButton>}</div>
                </SplitGroup>
            </div>
        </SchemaForm>
    </>
})

function updateTaskStep(step: AbstractStep, data: any) {
    console.log("Update task")
    runInAction(() => {
        step.name = data.name
        step.comment = data.comment

        if (step instanceof TaskStep) {
            step.parameters = data.parameters
            console.log(toJS(step.parameters!))
        }


        // for (const [key, prompt] of step.prompts.entries()) {
        //     if (!data.prompts.some((p: any) => p.name == key))
        //         step.prompts.delete(key)
        // }

        // for (const dataPrompt of data.prompts) {
        //     console.log("Updating prompts", step, dataPrompt)
        //     dataPrompt.type ??= PromptType.Approval
        //     dataPrompt.name ??= `Prompt-${step.prompts.size}`
        //     step.addPrompt(dataPrompt.name, dataPrompt)
        // }
    })
}