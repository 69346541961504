import { mergeAttributes, Node, wrappingInputRule } from '@tiptap/core'
import { Component } from './Component'
import { ReactNodeViewRenderer } from '@tiptap/react'

export interface AlertOptions {
    type: 'note' | 'important' | 'warning'
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        swAlert: {
            /** Toggle alert. */
            toggleAlert: () => ReturnType,
        }
    }
}

export const inputRegex = /^!note\s$/

export const Alert = Node.create<AlertOptions>({

    name: 'swAlert',

    addOptions() {
        return {
            type: 'note'
        }
    },

    content: '(paragraph)+',

    group: 'block top',

    defining: true,

    draggable: true,

    addAttributes: () => ({
        type: { default: 'note', }
    }),

    parseHTML() {
        return [
            { tag: 'sw-alert' },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['sw-alert', mergeAttributes(this.options, HTMLAttributes), ['text', 'Note'], ['div', 0]]
    },

    addCommands() {
        return {
            toggleAlert: () => ({ commands }) => {
                return commands.toggleWrap(this.name)
            },
        }
    },

    addKeyboardShortcuts() {
        return {
            // 'Mod-Shift-b': () => this.editor.commands.toggleBlockquote(),
        }
    },

    addInputRules() {
        return [
            wrappingInputRule({
                find: /^!note\s$/,
                type: this.type,
                getAttributes: { type: 'note' }
            }),
            wrappingInputRule({
                find: /^!important\s$/,
                type: this.type,
                getAttributes: { type: 'important' }
            }),
            wrappingInputRule({
                find: /^!warning\s$/,
                type: this.type,
                getAttributes: { type: 'warning' }
            })
        ]
    },

    addNodeView() {
        return ReactNodeViewRenderer(Component, {className: 'my-4'})
    }
})