import { PopoverProps as AriaPopoverProps, Popover as AriaPopover } from 'react-aria-components'

import './Popover.css'
import clsx from 'clsx'

export interface PopoverProps extends AriaPopoverProps {}

export function SwPopover({children, ...props}: PopoverProps) {
    props.offset ||= 0
    props.className = clsx(props.className, 'react-aria-Popover')
    return (
    <AriaPopover {...props}>
      {/* <OverlayArrow>
        <svg width={12} height={12}><path d="M0 0,L6 6,L12 0" /></svg>
      </OverlayArrow> */}
      {children}
      {/* <Dialog>
        {children}
      </Dialog> */}
    </AriaPopover>
    )
}