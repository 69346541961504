import { action, computed, makeAutoObservable, observable } from "mobx"
import { ErrorDto, StepWise } from "@/client"
import { PipelineRequest, RestError, SendRequest } from "@azure/core-rest-pipeline"
import { EvtHandler, EvtOptions } from "@/util/events"


export class AuthStore {
    @observable accessor user: Nullable<AuthUser> = null

    private eventTarget = new AuthEventTarget

    constructor(readonly sw: StepWise) {}

    @action init() {
        const authCookie = parseAuthCookieOrg()

        if (authCookie) {
            this.user = new AuthUser(this, {userName: authCookie.name, avatarUrl: authCookie.picture, orgId: parseInt(authCookie.org)})
            this.eventTarget.dispatchEvent(new CustomEvent(AuthEvent.Login, {detail: this.user}))
        }

        this.listenForBadCredentials()
    }

    async register(email: string, password: string) {
        try {
            await this.sw.authRegister({email, password})
        } catch (e) {
            const ex = e as RestError
            console.error(ex)
            throw ex.details as ErrorDto
        }
    }

    async confirmEmail(userId: string, code: string) {
        return await this.sw.authEmailConfirm(userId, code)
    }

    async login(userName: string, password: string) {
        const user = await this.sw.authLogin({userName, password})

        if (this.user)
            this.user.fromSpec(user)
        else
            this.user = new AuthUser(this, user)

        this.eventTarget.dispatchEvent(new CustomEvent(AuthEvent.Login, {detail: user}))
    }

    async passwordForgot(email: string) {
        return await this.sw.authPasswordForgot(email)
    }

    async passwordReset(email: string, code: string, password: string) {
        return await this.sw.authPasswordReset(email, code, password)
    }

    async logout(returnUrl?: string) {
        await this.sw.authLogout()
        const user = this.user
        this.user = null
        this.eventTarget.dispatchEvent(new CustomEvent(AuthEvent.Logout, {detail: {user, returnUrl}}))
    }

    onDidLogin(handler: EvtHandler<AuthLoginEvt>) {
        this.eventTarget.addEventListener(AuthEvent.Login, handler)
        return () => this.eventTarget.removeEventListener(AuthEvent.Login, handler)
    }

    onDidLogout(handler: EvtHandler<AuthLogoutEvt>) {
        this.eventTarget.addEventListener(AuthEvent.Logout, handler)
        return () => this.eventTarget.removeEventListener(AuthEvent.Logout, handler)
    }

    // Install a policy into the StepWise client to hook 403 responses and logout user
    private listenForBadCredentials() {
        this.sw.pipeline.addPolicy({
            name: "Bad Credentials Policy",
            sendRequest: async (request: PipelineRequest, next: SendRequest) => {
                try {
                    const result = await next(request)
                    return result
                } catch (e) {
                    const ex = e as RestError
                    if (ex.statusCode == 401)
                        this.logout.bind(this)(`${location.pathname}${location.search}${location.hash}`)
                    throw e
                }
            }
        })
    }
}


export interface IUser {
    userName: string
    avatarUrl?: string
    orgId: number
}

export class AuthUser implements IUser {
    private _store: AuthStore

    avatarUrl?: string
    userName!: string
    orgId!: number

    @computed get mentionName() {
        const emailName = this.userName.split('@')[0]
        return `@${emailName}`
    }

    constructor(store: AuthStore, spec: IUser) {
        this._store = store
        this.fromSpec(spec)
    }

    fromSpec(spec: IUser) {
        this.userName = spec.userName
        this.avatarUrl = spec.avatarUrl
        this.orgId = spec.orgId
    }

    fromCookie(cookie: AuthCookie) {
        this.orgId = parseInt(cookie.org)
        this.userName = cookie.id
        this.avatarUrl = cookie.picture
    }

    async logout() {return await this._store.logout()}
}



interface AuthCookie {
    org: string
    id: string
    name: string
    picture: string
}

function parseAuthCookieOrg(): AuthCookie | undefined {
    const swCookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("AUTH_CLIENT="))
        ?.split("=")[1]

    if (!swCookie) return

    const swAuth = JSON.parse(atob(swCookie!)) as AuthCookie

    return swAuth
}


export enum AuthEvent {
    Login = 'Loaded',
    Logout = 'Logout'
}

export interface LogoutEvent {
    user: AuthUser
    returnUrl?: string
}

export interface AuthLoginEvt extends CustomEvent<AuthUser> {}
export interface AuthLogoutEvt extends CustomEvent<LogoutEvent> {}


type AddEventListener<EvtName extends string, T extends CustomEvent> = (type: EvtName, listener: EvtHandler<T>, options?: AddEventListenerOptions | boolean) => void

interface AuthEventTarget extends EventTarget {
    addEventListener(type: AuthEvent.Login, listener: EvtHandler<AuthLoginEvt>, options?: EvtOptions): void
    removeEventListener(type: AuthEvent.Login, listener: EvtHandler<AuthLoginEvt>, options?: EvtOptions): void
    addEventListener(type: AuthEvent.Logout, listener: EvtHandler<AuthLogoutEvt>, options?: EvtOptions): void
    removeEventListener(type: AuthEvent.Logout, listener: EvtHandler<AuthLogoutEvt>, options?: EvtOptions): void
}
class AuthEventTarget extends EventTarget {}