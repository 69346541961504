import { useSlotProps } from "@react-spectrum/utils"
import { TablerIconsProps } from "@tabler/icons-react"
import clsx from "clsx"
import React from "react"


interface IconProps extends TablerIconsProps {
    children?: React.ReactElement
    icon?: (props: TablerIconsProps) => React.ReactElement
}

export const SwIcon = (props: IconProps) => {
    const { children, icon } = props
    props = useSlotProps(props, 'icon')
    // @ts-ignore
    props.slot = 'icon'

    if (!icon && !children) throw new Error("Icon must have either an icon prop or children")

    const ChildIcon = icon!

    return children ? React.cloneElement(children, {
        slot: 'icon',
        focusable: 'false',
        role: 'img',
        className: clsx(
            children.props.className,
            props.className)
    }) : <ChildIcon {...props} />
}