import { WorkflowTiming } from "@/components/widgets/charts/workflow/WorkflowTiming";
import { WorkflowVariance } from "@/components/widgets/charts/workflow/WorkflowVariance";
import { observer } from "mobx-react-lite";


export const Insights = observer(() => {
    return <>
        <div className="h-full sw-scroller">
            <div className="flex flex-col gap-4 p-4">
                <div className="w-full bg-slate-100 h-52 rounded-md p-2">
                    <WorkflowTiming />
                </div>
                <div className="h-52 w-full bg-slate-100 rounded-md p-2">
                    <WorkflowVariance/>
                </div>
            </div>
        </div>
    </>
})