import { EChart } from "@/components/EChart/EChart"
import * as echarts from 'echarts'
import { prepareBoxplotData } from 'echarts/extension/dataTool'
import { ExecutionReportDto, StepReportDto } from '@/client/models'
import { Duration } from 'luxon'
import humanizeDuration from 'humanize-duration'
import { useWorkspaceCtx } from "@/contexts"
import { useRootStore } from "@/hooks/useStores"
import { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"


let option = {
    aria: {
        show: true,
        decal: {show: true}
    },
    grid: {
        top: 40,
        bottom: 50
    },
    title: {
        text: "Step Duration IQR",
    },
    tooltip: {
        trigger: 'item',
        confine: true,
        axisPointer: {
            type: 'shadow',
        },
        valueFormatter(value) {
            if (Array.isArray(value)) return value
            return humanizeDuration(Number(value), {maxDecimalPoints: 2})
        },
    },
    toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
            mark: { show: true },
            saveAsImage: { show: true }
        }
    },
    dataset: {
        source: []
    },
    xAxis: [
        {
            type: 'category',
            axisTick: { show: false },
            axisLabel: {
                interval: 0,
                overflow: 'break',
                hideOverlap: true,
                width: 80
            }
        }
    ],
    yAxis: [
        {
            type: 'log',
            // logBase: 10,
            axisLabel: {
                formatter(value, index) {
                    const duration = Duration.fromMillis(value)
                    return duration.toFormat('hh:mm:ss')
                },
            }
        }
    ],
    series: []
} as echarts.EChartsOption

export interface WorkflowVarianceProps {
    report: Nullable<ExecutionReportDto[]>
}

export const WorkflowVariance = observer(() => {
    const workspaceCtx = useWorkspaceCtx()
    const rootStore = useRootStore()
    const workflow = workspaceCtx.activeWorkflow

    const [data, setData] = useState<Nullable<ExecutionReportDto[]>>(null)

    useEffect(() => {
        if (!workflow) return
        (async () => {
            const data = await rootStore.sw.workflowHistoryGet(workflow!.orgId, workflow!.ref.id)
            setData(data)
        })()
    },[workflow])

    return <WorkflowVarianceContent report={data}/>
})

export const WorkflowVarianceContent = ({report}: WorkflowVarianceProps) => {
    const stepKeys = reportToKeys(report ?? [])
    const series = reportToSeries(report ?? [], stepKeys)
    // @ts-ignore
    option.xAxis[0].data = stepKeys
    option.series = series

    option = {...option}

    return <EChart option={option}/>
}


function reportToKeys(report: ExecutionReportDto[]): string[] {
    const seen = new Set<string>()
    const steps = [] as StepReportDto[]

    report.forEach(e => {
        e.steps?.forEach(s => {
            if (seen.has(s.key!))
                return
            else {
                seen.add(s.key!)
                steps.push(s)
            }
        })
    })

    steps.sort((a, b) => a.startedAt! > b.startedAt! ? 1 : -1)

    const labels = steps.map(s => s.key!)
    console.log(labels)
    return labels
}

function reportToSeries(report: ExecutionReportDto[], stepkeys: string[]) {
    if (stepkeys.length == 0) return []

    const series = [] as any[]
    const data = [] as any[][]
    const stepData = {} as {[index: string]: Nullable<number>[]}

    stepkeys.forEach(k => stepData[k] = [])

    report.sort((a, b) => a.startedAt! < b.startedAt! ? 1 : -1)


    for (const ex of report) {
        ex.steps?.forEach(s => stepData[s.key!].push(s.duration ?? null))
    }

    console.log(stepData)

    for (const key in stepData) {
        data.push(stepData[key].filter(d => d != null))
    }

    series.push({
        type: 'boxplot',
        colorBy: 'data',
        itemStyle: {
            borderWidth: 2
        },
        data: prepareBoxplotData(data, {boundIQR: 'none'}).boxData
    })

    return series
}