import { makeAutoObservable } from "mobx"
import { StepWise } from "@/client"
import { createUseStore } from "./util"


export class OrgStore {
    byId = new Map<number, Org>()
    byKey = new Map<string, Org>()
    orgs: Org[] = []

    constructor(readonly sw: StepWise) {
        makeAutoObservable(this)
    }

    add(org: Org) {
        const existing = this.byId.get(org.id)

        if (existing) {
            existing.fromSpec(org.toSpec())
            return existing
        }

        this.byId.set(org.id, org)
        this.byKey.set(org.key, org)
        this.orgs.push(org)

        return org
    }

    async load(id: number) {
        const org = await this.sw.orgGetById(id)
        return new Org(org)
    }

    async loadUserOrgs() {
        const orgs = await this.sw.userOrgList()
        for (var org of orgs)
            this.add(new Org(org))
    }
}
export const [orgCtx, useOrgCtx] = createUseStore(OrgStore)

export interface IOrg {
    id: number
    name: string
    key: string
}

export class Org {
    id!: number
    name!: string
    key!: string

    constructor(spec: IOrg) {
        makeAutoObservable(this)
        this.fromSpec(spec)
    }

    fromSpec(spec: IOrg) {
        this.id = spec.id
        this.name = spec.name
        this.key = spec.key
    }

    toSpec(): IOrg {
        return {
            id: this.id,
            name: this.name,
            key: this.key
        }
    }
}