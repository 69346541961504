import { observer } from "mobx-react-lite"
import { EditorView } from "@codemirror/view"
import { json, jsonParseLinter } from "@codemirror/lang-json"
import { basicSetup } from 'codemirror'
import { dracula } from "@uiw/codemirror-theme-dracula"
import { materialLight, materialDark } from "@uiw/codemirror-theme-material"
import { memo, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import "./CodeMirror.css"
import { linter } from "@codemirror/lint"
import { Theme, useThemeCtx } from "@/stores/Theme"
import { Compartment, EditorState, Text } from "@codemirror/state"


const jsonLinter = linter(jsonParseLinter())


const themes = new Compartment


interface CodeMirrorProps {
    doc?: string | Text
}


export const CodeMirror = observer(({doc}: CodeMirrorProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const state = useRef<EditorState|null>(null)
    const view = useRef<EditorView|null>(null)
    const theme = useThemeCtx()

    useMemo(() => {
        view.current = new EditorView
    }, [])

    useMemo(() => {
        state.current = EditorState.create({
            doc: doc,
            extensions: [
                basicSetup,
                json(),
                jsonLinter,
                themes.of(theme.theme == Theme.dark ? materialDark : materialLight),
                // EditorState.readOnly.of(true)
            ],
        })

        view.current?.setState(state.current)

    }, [doc])

    useLayoutEffect(() => {
        ref.current?.appendChild(view.current!.dom)
    }, [ref.current])


    useEffect(() => {
        return () => view.current?.destroy()
    }, [])

    useEffect(() => {
        view.current?.dispatch({
            effects: themes.reconfigure(theme.theme == Theme.dark ? materialDark : materialLight)
        })
    }, [theme.theme])

    return <div className="sw-codemirror h-full w-full" ref={ref} />
})