import { SwToggleButton } from "@/components/ToggleButton"
import { Icon, IconBold } from "@tabler/icons-react"

export interface BoldButtonProps {
    isActive: boolean
    isDisabled?: boolean
    onChange(): void
    icon: Icon
}

export const BoldButton = ({isActive, isDisabled, onChange, icon: Icon}: BoldButtonProps) => (
    <SwToggleButton isQuiet isSelected={isActive} isDisabled={isDisabled} onChange={onChange}>
        <Icon size={20} className="m-1" />
    </SwToggleButton>
)