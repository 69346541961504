import { useRef, useState } from 'react'
import { NodeViewWrapper, NodeViewProps } from '@tiptap/react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'
import { SwButton } from '@/components/Button'


export const Component =  observer((props: NodeViewProps) => {
    const [key, setKey] = useState(1)

    const src = props.node.attrs['src']

    const ref = useRef<HTMLIFrameElement>(null)

    return (
        <NodeViewWrapper
            draggable={true}
            className={clsx(
                'text-white rounded bg-slate-600 w-fit overflow-hidden relative w-[80%]',
                props.selected && 'ring-4 ring-blue-300'
            )}
            data-drag-handle
        >
            <SwButton className="absolute top-0 left-0" onPress={() => setKey(key + 1)}>Refresh</SwButton>
            <iframe key={key} ref={ref} className="w-[780px] h-[300px]" src={src} sandbox="allow-scripts allow-same-origin"/>
        </NodeViewWrapper>
    )
})
Component.displayName = 'StepNodeView'