import { StepWise } from "@/client"
import { createUseStore } from "./util"
import { IUser } from "./AuthStore"
import { computed, observable } from "mobx"


export class UserStore {
    constructor(readonly sw: StepWise) {}

    async search(orgId: number, query: string) {
        return await this.sw.userList(orgId, {query})
    }
}

export const [UserCtx, useUserCtx] = createUseStore(UserStore)


export class User implements IUser {
    id!: string
    userName!: string
    orgId!: number

    @computed get mentionName() {
        const emailName = this.userName.split('@')[0]
        return `@${emailName}`
    }

    constructor(spec: IUser) {
        this.fromSpec(spec)
    }

    fromSpec(spec: IUser) {
        Object.assign(this, spec)
    }
}