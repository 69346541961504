import React, { useContext, useEffect, useLayoutEffect } from 'react'
import { NodeViewWrapper, NodeViewProps, NodeViewContent } from '@tiptap/react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'
import { WorkspaceContext, useWorkspaceCtx } from '@/contexts'
import { InputStep, InputStepFlavor, Step, StepStatus, TaskStep } from '@/stores/WorkflowDefinition'
import { computed } from 'mobx'
import { AckStep } from './AckStep'
import { ApprovalStep } from './ApprovalStep'
import { TaskStep as TaskStepDisplay } from './TaskStep'
import { StepWiseEditorContext } from '@/widgets/Editor/context'
import { WorkspaceMode } from '@/stores/WorkspaceCtx'
import { StepLabel } from './StepLabel'
import { cn } from '@/util/cn'


export const Component =  observer((props: NodeViewProps) => {
    const ref = React.useRef<HTMLDivElement>(null)

    const editor = useContext(StepWiseEditorContext)

    const workspaceCtx = useContext(WorkspaceContext)!
    const workflow = workspaceCtx.activeWorkflow

    const stepId = props.node.attrs['id']
    const step = workflow?.stepsById.get(stepId)

    const stepSelected = computed( () => workflow?.selectedStep === step).get()

    useEffect(() => {
        if (props.selected) {
            workflow && (workflow.selectedStep = step ?? null)
        }
    }, [props.selected])

    useLayoutEffect(() => {
        const section = ref.current!.querySelector('section')!
        section.classList.add('prose')
        section.classList.add('prose-doc')
        section.classList.add('max-w-none')
        section.classList.add('dark:prose-invert')
    }, [])

    const selectNode = () => {
        const pos = props.getPos()
        editor?.tipTap.chain().setNodeSelection(pos).run()
    }

    return (
        <NodeViewWrapper
            draggable={true}
            onClick={workspaceCtx.mode == WorkspaceMode.Read ? selectNode : () => {} }
            className={
                clsx(
                    "hover:drop-shadow-xl transition duration-75 ease-out hover:ease-in",
                    "rounded-md box-content",
                    stepSelected ? 'ring-blue-400 ring-2' : 'border-gray-300'
                )
            }
            data-drag-handle
        >
            <div
                ref={ref}
                className={cn(
                    "bg-white dark:bg-black border-x border-y rounded-t-md rounded-br-md overflow-hidden"
                )}
            >
                {/* {workflow && step ? renderStep(workflow, step) : renderPlaceholder()} */}
                {/* <div className="h-2 bg-slate-100" contentEditable={false}></div> */}
                <NodeViewContent className="max-w-none px-4 py-2"/>
            </div>
            {step && <StepFooter step={step} onClick={selectNode}/>}
        </NodeViewWrapper>
    )
})
Component.displayName = 'StepNodeView'


const StepFooter = observer(({step, onClick}: {step: Step, onClick: () => void}) => {
    const workspace = useWorkspaceCtx()

    const displayTab = [WorkspaceMode.Read, WorkspaceMode.Write].includes(workspace.mode) || [StepStatus.Pending, null].includes(step.status)

    return <div contentEditable={false}>
        {displayTab && <StepLabel step={step} variant='tab' onClick={onClick}/>}
        {!displayTab &&
        <div onClick={onClick} className='drag-component' contentEditable={false}>
            {step && step instanceof InputStep && step.flavor == InputStepFlavor.Ack && <AckStep step={step}/>}
            {step && step instanceof InputStep && step.flavor == InputStepFlavor.Approval && <ApprovalStep step={step}/>}
            {step && step instanceof TaskStep && <TaskStepDisplay step={step}/>}
        </div>}
    </div>
})