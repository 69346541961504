import { EChart } from '@/components/EChart/EChart'
import * as echarts from 'echarts'
import { DateTime } from 'luxon'

type EChartsOption = echarts.EChartsOption

var option: EChartsOption

function getVirtualData(year: string) {
    const data: [Date, number][] = []
    data.push([
        (new Date),
        5
    ])
    return data
}

option = {
    title: {
        top: 30,
        left: 'center',
        text: 'Daily Executions'
    },
    tooltip: {},
    visualMap: {
        min: 1,
        max: 100,
        type: 'piecewise',
        orient: 'horizontal',
        left: 'center',
        top: 65,
        precision: 0,
        show: false
    },
    calendar: {
        top: 90,
        left: 'center',
        right: 30,
        bottom: 3,
        cellSize: ['auto', 'auto'],
        dayLabel: {
            show: true,
            position: 'start',
            margin: '10'
        },
        orient: 'vertical',
        range: DateTime.now().toFormat('yyyy-M'),
        itemStyle: {
            borderWidth: 0.5
        },
        yearLabel: { show: false }
    },
    series: {
        type: 'heatmap',
        coordinateSystem: 'calendar',
        data: getVirtualData('2023'),
        label: {
            show: true,
            formatter: function (params) {
                // @ts-ignore
                var d = echarts.number.parseDate(params.value[0])
                return `${d.getDate()}`
            },
        }
    }
}

export const WorkflowMonthly = () => <EChart option={option}/>