import { cloneDeep, merge } from 'lodash-es'
import { action, computed, observable } from "mobx"
import { v4 as uuidv4 } from "uuid"

import { ExecutionDto, ExecutionAttachmentDto, WorkflowDto, WorkflowRef, ExecutionStepDto } from "@/client"
import { WorkflowChange, WorkflowEvent } from './Workflow'
import { EvtHandler } from '@/util/events'
import { IconHandClick, TablerIconsProps } from '@tabler/icons-react'
import { IUser, AuthUser } from './AuthStore'
import { User } from './UserStore'

export interface IWorkflowDefinition {
    startAt: string,
    steps: { [key: string]: IStep }
}

export enum StepType {
    Input = "Input",
    Pass = "Pass",
    Task = "Task"
}

export enum ExecutionStatus {
    Running = "Running",
    Succeeded = "Succeeded",
    Failed = "Failed",
    TimedOut = "TimedOut",
    Aborted = "Aborted"
}

export enum StepStatus {
    Pending = "Pending",
    Running = "Running",
    Succeeded = "Succeeded",
    Failed = "Failed"
}

export type IStep = IInputStep | ITaskStep | IPassStep

export interface IBaseStep {
    key: string,
    name: Nullable<string>,
    id?: string,
    comment?: string,
    status?: StepStatus,
    next?: string,
    end: boolean
    inputs?: { [key: string]: IInput }
    review?: IReviewSpec
}

export interface IPassStep extends IBaseStep {
    type: StepType.Pass
}

export interface IInputStep extends IBaseStep {
    type: StepType.Input
}

export interface ITaskStep extends IBaseStep {
    type: StepType.Task
    task: string
    parameters: any
}

export interface WorkflowState {
    author: User
    executionId: string
    status: ExecutionStatus
    createdAt: Date
    startedAt?: Date
    finishedAt?: Date
    attachments: Array<ExecutionAttachmentDto>
}

export interface StepState {
    stepId: string
    status: StepStatus
    error: Nullable<string>
    errorCause: Nullable<string>
    startedAt?: Date
    finishedAt?: Date
    attachments: Array<ExecutionAttachmentDto>
    output: any
    submittedAt?: Date
    submitter?: IUser
    review?: {
        reviewedAt: Date
        reviewer: IUser
        approved: boolean
    }
}

export class Workflow {
    @observable accessor name: string
    @observable accessor orgId: number
    @observable accessor startAt: Nullable<Step> = null
    @observable accessor steps: Map<string, Step> = new Map<string, Step>()
    @observable accessor stepsById = new Map<string, Step>()
    @observable accessor ref!: WorkflowRef

    @observable accessor attachments: Array<ExecutionAttachmentDto> = []

    @observable accessor selectedStep: Nullable<Step> = null

    @observable accessor state: Nullable<WorkflowState> = null

    private eventTarget: WorkflowEventTarget = new WorkflowEventTarget

    constructor(name: string, orgId: number, ref: WorkflowRef) {
        this.name = name
        this.orgId = orgId
        this.ref = ref
    }

    onDidChange(handler: EvtHandler<WorkflowChangedEvt>) {
        this.eventTarget.addEventListener(WorkflowEvent.Changed, handler)
        return () => this.eventTarget.removeEventListener(WorkflowEvent.Changed, handler)
    }

    @computed get stepList() {
        let curStep = this.startAt
        if (!curStep) return []
        const orderedSteps: Step[] = [curStep!]
        while (curStep = curStep?.next) {
            orderedSteps.push(curStep)
        }
        return orderedSteps
    }

    static FromWorkflowDto(dto: WorkflowDto) {
        var spec = JSON.parse(dto.definition) as IWorkflowDefinition
        return Workflow.FromDefinition(dto.name, dto.orgId, dto.ref,spec)
    }

    static FromDefinition(name: string, orgId: number, ref: WorkflowRef, spec: IWorkflowDefinition) {
        var wf = new Workflow(name, orgId, ref)

        for (const [k, v] of Object.entries(spec.steps)) {
            const step = AbstractStep.FromSpec(k, v, wf)
            wf.steps.set(k, step)
            wf.stepsById.set(step.id, step)
        }

        for (const [k, v] of Object.entries(spec.steps)) {
            const step = wf.steps.get(k)
            if (!step) throw new Error(`Step ${k} not found.`)
            if (!v.next) continue
            const next = wf.steps.get(v.next)
            if (!next) throw new Error(`Unable to find next step ${v.next}`)
            // if (!next) continue
            step.next = next
            next.previous = step
        }

        wf.startAt = wf.steps.get(spec.startAt)!

        return wf
    }

    @action updateFromDefinition(spec: IWorkflowDefinition) {
        // Remove deleted steps
        for (const step of this.stepList) {
            if (! (step.key in spec.steps) || step.type !== spec.steps[step.key].type) {
                this._removeStep(step)
                this.steps.delete(step.key)
                this.stepsById.delete(step.id)
            }
        }

        // Update and add new steps
        for (const [k, v] of Object.entries(spec.steps)) {
            if (this.steps.has(k)) {
                (<AbstractStep> this.steps.get(k))?.updateFromSpec(v)
            } else {
                const step = AbstractStep.FromSpec(k, v, this)
                this.steps.set(k, step)
                this.stepsById.set(step.id, step)
            }
        }

        // for (const [key, step] of this.steps) {}

        for (const [k, v] of Object.entries(spec.steps)) {
            const step = this.steps.get(k)
            if (!step) throw new Error(`Step ${k} not found.`)
            if (!v.next) { step.next = null; continue }
            const next = this.steps.get(v.next)
            if (!next) throw new Error(`Unable to find next step ${v.next}`)
            step.next = next
            next.previous = step
        }
        this.startAt = this.steps.get(spec.startAt)!
        this.validate()
    }

    is(ref: WorkflowRef) {
        return this.ref.id === ref.id && this.ref.revision === ref.revision
    }

    toWorkflowDto(): WorkflowDto {
        return {
            ref: this.ref!,
            orgId: this.orgId,
            name: this.name,
            definition: JSON.stringify(this.toDefinition())
        }
    }

    @action clear() {
        this.stepList.forEach(s => this._removeStep(s))
    }

    @action createStep(key?: string) {
        key = key || `Step ${this.steps.size + 1}`
        const end = Array.from(this.steps.values()).find(s => s.end)
        if (!end) throw new Error("Unable to locate end step.")

        const step = AbstractStep.FromSpec(key, {
            type: StepType.Input,
            key,
            name: null,
            end: true,
        },
        this
        )
        this.steps.set(key, step)
        this.stepsById.set(step.id, step)
        end.addNext(step)
        this.validate()
    }

    @action removeStep(step: AbstractStep) {
        // if (step === this.startAt) throw new Error("Cannot remove start step.")
        this._removeStep(step)
        this.validate()
    }

    @action private _removeStep(step: AbstractStep) {
        step.unlink()
        this.steps.delete(step.key)
        this.stepsById.delete(step.id)
        if (this.selectedStep == step) this.selectedStep = null
        if (this.steps.size == 0) this.startAt = null
    }

    @action updateFromExecutionDto(dto: ExecutionDto) {
        this.state = {
            author: new User(dto.author),
            executionId: dto.id,
            status: dto.status as ExecutionStatus,
            createdAt: dto.createdAt,
            startedAt: dto.startedAt,
            finishedAt: dto.finishedAt,
            attachments: []
        }

        for (const [k, v] of Object.entries(dto.steps!)) {
            const step = this.steps.get(k)
            if (!step) continue
            step.id = v.id
            step.status = v.status as StepStatus
            step.startedAt = v.startedAt ?? null
            step.finishedAt = v.finishedAt ?? null
            step.state = {
                stepId: v.id,
                status: v.status as StepStatus,
                error: v.error,
                errorCause: v.errorCause,
                startedAt: v.startedAt,
                finishedAt: v.finishedAt,
                attachments: [],
                output: v.output,
                submittedAt: v.submittedAt,
                submitter: v.submitter,
                review: v.review,
            }

            if (step instanceof InputStep) {
                for (const [pK, pV] of Object.entries(v.inputs)) {
                    const input = step.inputs.get(pK)
                    if (!input) continue
                    input.state = {
                        isSatisfied: pV.isSatisfied,
                        // responses: pV.responses.map(r => ({
                        //     author: {userName: r.author.userName, id: r.author.id, orgId: r.author.orgId},
                        //     createdAt: r.createdAt,
                        //     value: r.value
                        // })) as any
                    }
                }
            }
        }
        this.selectedStep = this.stepList.find(s => s.status === StepStatus.Running) ?? null
    }

    toDefinition() {
        const def: IWorkflowDefinition = {
            startAt: this.startAt!.key,
            steps: {}
        }

        this.steps.forEach(s => {
            def.steps[s.key] = s.toDefinition()
        })

        return def
    }

    @action insertAfter(step: Step, next: Step) {
        step.addNext(next)
        if (!step.previous) this.startAt = step
        this.validate()
    }

    @action replaceStep(step: AbstractStep, newStep: Step) {
        newStep.workflow = step.workflow
        if (this.selectedStep?.id === step.id) this.selectedStep = newStep
        step.replace(newStep)
        this.removeStep(step)
        if (!newStep.previous) this.startAt = newStep
        this.steps.set(newStep.key, newStep)
        this.stepsById.set(newStep.id, newStep)

        this.validate()
        this.eventTarget.dispatchEvent(new CustomEvent(WorkflowEvent.Changed, {detail: {
            workflow: this,
            steps: [newStep]
        }}))
    }

    @action updateStep(step: Step, newStep: Step) {
        const key = step.key
        step.key = newStep.key;
        (<AbstractStep> step).updateFromSpec(newStep.toDefinition())
        if (key !== newStep.key) {
            this.steps.delete(key)
            this.steps.set(step.key, step)
        }
        this.validate()
    }

    @action updateStepType(step: AbstractStep, type: StepType) {
        const spec = step.toDefinition()
        spec.type = type
        const newStep = AbstractStep.FromSpec(step.key, spec, this)
        this.validate()
        return newStep
    }

    private validate() {
        const endSteps = [...this.steps.values()].filter(s => s.end)
        if (endSteps.length > 1) {
            console.error("Multiple end steps", endSteps)
            throw new Error(`Only one end step allowed: [${endSteps}]`)
        }
    }
}

export type Step = InputStep | TaskStep

export abstract class AbstractStep {
    abstract type: StepType
    abstract displayType: StepDisplayType
    @observable accessor localRev = 0
    @observable accessor id: string = uuidv4()
    @observable accessor key!: string
    @observable accessor status: Nullable<StepStatus> = null
    @observable accessor name: Nullable<string> = null
    @observable accessor comment: Nullable<string> = null
    @observable accessor previous: Nullable<Step> = null
    @observable accessor next: Nullable<Step> = null

    @observable accessor attachments: Array<ExecutionAttachmentDto> = []

    @observable accessor startedAt: Nullable<Date> = null
    @observable accessor finishedAt: Nullable<Date> = null

    @observable accessor workflow: Nullable<Workflow> = null

    @observable accessor state: Nullable<StepState> = null

    @computed get displayName() {
        return this.name || this.key
    }

    @computed get end() {
        return ! !!this.next
    }

    constructor(key: string, spec?: Partial<IStep>, workflow?: Nullable<Workflow>) {
        this.workflow = workflow ?? null
        this.key = key
    }

    @action updateFromSpec(spec: Partial<IStep>) {
        this.localRev++
        spec.type && (this.type = spec.type)
        spec.name !== undefined && (this.name = spec.name)
        spec.status && (this.status = spec.status)
        spec.comment && (this.comment = spec.comment)
        // spec.End !== undefined && (this.end = spec.End)
        spec.id && (this.id = spec.id) || (this.id = uuidv4())
    }

    updateFromExecutionDto(dto: ExecutionStepDto) {

    }

    static FromSpec(key: string, spec: IStep, workflow: Nullable<Workflow> = null): Step {
        switch (spec.type) {
        case StepType.Input:
            return new InputStep(key, spec, workflow)
        case StepType.Task:
            return new TaskStep(key, spec, workflow)
        default:
            throw new Error(`Unknown step type ${spec.type}`)
        }
    }

    @action addAttachment(attachment: ExecutionAttachmentDto) {
        if (!this.attachments.find(a => a.id === attachment.id))
            this.attachments.push(attachment)
    }

    toType(type: StepType): Step {
        const spec = this.toDefinition()
        spec.type = type
        return AbstractStep.FromSpec(this.key, spec, this.workflow)
    }

    abstract toDefinition(): IStep

    protected baseDefinition(): IBaseStep {
        const def: IBaseStep = {
            key: this.key,
            name: this.name,
            id: this.id,
            comment: this.comment ?? undefined,
            end: this.end,
        }

        if (this.next) def.next = this.next.key
        return def
    }

    @action addNextId(id: string) {
        if (!this.workflow) throw new Error("addNextId requires Step to be attached to workflow")
        const next = this.workflow.stepsById.get(id)
        if (!next) return
        this.addNext(next)
    }

    /** Replace this step in the graph */
    @action replace(step: Step) {
        this.addNext(step)
        this.unlink()
    }

    /** Remove step from graph and close the hole */
    @action unlink() {
        this.next && (this.next.previous = this.previous)
        this.previous && (this.previous.next = this.next)

        this.next = this.previous = null
    }

    @action addNext(next: Step) {
        // @ts-ignore
        if (this === next) return

        next.unlink()

        next.next = this.next
        this.next && (this.next.previous = next)
        this.next = next
        next.previous = this as any as Step
    }
}

export enum InputStepFlavor {
    Ack = "Ack",
    Approval = "Approval",
    Multi = "Multi",
}


export interface IReviewSpec {
    users: Array<string>
}

export class ReviewSpec {
    @observable accessor users: Array<string> = []
}

export class InputStep extends AbstractStep {
    type = StepType.Input
    @observable accessor inputs: Map<string, Input> = new Map<string, Input>()
    @observable accessor review: Nullable<IReviewSpec> = null

    @computed get flavor() {
        if (this.inputs.size > 1)
            return InputStepFlavor.Multi
        if (this.inputs.size == 0 && !this.review)
            return InputStepFlavor.Ack
        if (this.inputs.size == 0 && this.review)
            return InputStepFlavor.Approval

        const singleInputType = Array.from(this.inputs.values())[0].type

        switch (singleInputType) {
        default:
            throw new Error("Unknown input step flavor!")
        }
    }

    @computed get displayType() {
        switch (this.flavor) {
        case InputStepFlavor.Ack:
            return 'Confirmation'
        case InputStepFlavor.Approval:
            return 'Approval'
        default:
            return 'Unknown'
        }
    }

    constructor(key: string, spec?: IInputStep, workflow: Nullable<Workflow> = null) {
        super(key, spec, workflow)
        if (spec) this.updateFromSpec(spec)
    }

    @action addInput(key: string, spec: IInput) {
        const input = Input.FromDefinitionSpec(key, spec)
        this.inputs.set(key, input)
    }

    @action removeInput(key: string) {
        this.inputs.delete(key)
    }

    @action updateFromSpec(spec: Partial<IInputStep>) {
        super.updateFromSpec(spec)
        this.review = spec.review ?? null
        if (!spec.inputs) return

        this.inputs.clear()

        for (const [k, v] of Object.entries(spec.inputs)) {
            this.addInput(k, v)
        }
    }

    toDefinition(): IInputStep {
        const def = this.baseDefinition()

        if (this.review) def.review = { users: [...this.review.users] }

        const inputs: {[key: string]: IInput} = {}
        for (const [k, v] of this.inputs.entries())
            inputs[k] = v.toDefinition()
        def.inputs = inputs

        return {type: StepType.Input, ...def}
    }
}

export class TaskStep extends AbstractStep {
    type = StepType.Task
    displayType: StepDisplayType = 'Task'
    @observable accessor task: string = "HttpReq"
    @observable accessor parameters: any = {}

    constructor(key: string, spec?: ITaskStep, workflow: Nullable<Workflow> = null) {
        super(key, spec, workflow)
        if (spec) this.updateFromSpec(spec)
    }

    @action updateFromSpec(spec: Partial<ITaskStep>) {
        super.updateFromSpec(spec)
        if (spec.parameters)
            merge(this.parameters = spec.parameters)
        else
            Object.keys(this.parameters).forEach(key => delete this.parameters[key])
    }

    toDefinition(): ITaskStep {
        const def = super.baseDefinition()
        return {type: StepType.Task, task: this.task, parameters: cloneDeep(this.parameters), ...def}
    }
}

/** UI specific step type depending on step configuration.
 * ie a input step with only an approval input will have an "Approval" display type. */
export type StepDisplayType = 'Task' | 'Approval' | 'Confirmation' | 'Unknown'

export enum InputType {
    Boolean = "Boolean",
    Text = "Text",
    String = "String"
}

export interface IInput {
    key: string,
    type: InputType,
    comment?: string
}

export class Input {
    type: InputType = InputType.String
    @observable accessor key: string
    @observable accessor name: Nullable<string> = null
    @observable accessor comment: Nullable<string> = null

    @observable accessor state: Nullable<InputState> = null


    constructor(key: string, spec?: Partial<IInput>) {
        this.key = key
        if (spec) this.updateFromSpec(spec)
    }

    @computed get displayName() {
        return this.name || this.key
    }

    @action updateFromSpec(spec: Partial<IInput>) {
        spec.type && (this.type = spec.type)
        spec.comment && (this.comment = spec.comment)
    }

    static FromDefinitionSpec(key: string, spec: IInput): Input {
        switch (spec.type) {
        default:
            throw new Error(`Unknown input type ${spec.type}`)
        }
    }

    toDefinition(): IInput {
        return {
            type: this.type,
            key: this.key,
            comment: this.comment ?? undefined
        }
    }
}

export interface IBoolInput extends IInput {
    default: boolean
}

export class BoolInput extends Input {
    @observable accessor default: boolean = false

    constructor(key: string, spec: Partial<IBoolInput>) {
        super(key, spec)
        // makeObservable(this, {
        //     default: observable,
        // })
        if (spec) this.updateFromSpec(spec)
    }

    @action updateFromSpec(spec: Partial<IBoolInput>): void {
        super.updateFromSpec(spec)
        this.default = !!spec.default
    }

    toDefinition(): IBoolInput {
        const def = super.toDefinition() as IBoolInput
        def.default = this.default
        return def
    }
}

// export class AckInput extends Input {
//     @observable accessor default: boolean = false
//     @observable accessor state: Nullable<AckInputState> = null

//     constructor(key: string, spec: Partial<IBoolInput>) {
//         super(key, spec)
//         if (spec) this.updateFromSpec(spec)
//     }

//     @action updateFromSpec(spec: Partial<IBoolInput>): void {
//         super.updateFromSpec(spec)
//         this.default = !!spec.default
//     }

//     toDefinition(): IBoolInput {
//         const def = super.toDefinition() as IBoolInput
//         def.default = this.default
//         return def
//     }
// }

export interface IApprovalInput extends IInput {
    users: Array<string>
    groups: Array<string>
}


export interface WorkflowChangedEvt extends CustomEvent<WorkflowChange> {}


interface WorkflowEventTarget extends EventTarget {
    addEventListener(type: WorkflowEvent.Changed, listener: EvtHandler<WorkflowChangedEvt>, options?: AddEventListenerOptions | boolean): void
    removeEventListener(type: WorkflowEvent.Changed, listener: EvtHandler<WorkflowChangedEvt>, options?: AddEventListenerOptions | boolean): void
}
class WorkflowEventTarget extends EventTarget {}


export interface BulkResponse {
    step: string,
    inputs?: Array<Response>
}


export type Response = TextResponse


export interface IResp {
    type: InputType
    key: string
}

export interface TextResponse extends IResp {
    type: InputType.Text
    value: string
}


export type ResponseRec = TextResponseRec


export interface IRespRec {
    type: InputType
    author: IUser
    value: any
    createdAt: Date
}


export interface TextResponseRec extends IRespRec {
    type: InputType.Text
    value: string
}

export interface InputState {
    isSatisfied: boolean
}

export interface Review {
    stepKey: string,
    decision: boolean
}