import { SwButton } from "@/components"
import { SwIcon } from "@/components/Icon/Icon"
import { SwText } from "@/components/Text/Text"
import { useWorkflowStore } from "@/stores/Workflow"
import { InputStep, InputType, StepStatus } from "@/stores/WorkflowDefinition"
import { IconBox, IconCheck, IconCheckbox, IconSquare, IconX } from "@tabler/icons-react"
import { observer } from "mobx-react-lite"
import { StepName } from "./component/StepName"
import { useWorkspaceCtx } from "@/contexts"
import { WorkspaceMode } from "@/stores/WorkspaceCtx"
import { StepIcon } from "@/components/Workflow/StepIcon"
import { SwBadge } from "@/components/Badge/Badge"

export interface AckStepProps {
    step: InputStep
}

export const ApprovalStep = observer(({step}: AckStepProps) => {
    const workspaceCtx = useWorkspaceCtx()
    const workflows = useWorkflowStore()

    const isExecMode = workspaceCtx.mode == WorkspaceMode.Execute
    const isRunning = step.status == StepStatus.Running
    const isQuiet = !isExecMode || !isRunning

    const onSubmit = async (decision: boolean) => {
        await workflows.review(step.workflow!, {
            stepKey: step.key,
            decision
        })
    }

    return <>
        <div contentEditable={false} className='bg-slate-200 flex items-center p-2 justify-between rounded-b-md'>
            <div className="flex justify-start items-center space-x-1 bg-neutral-50 rounded pr-2">

                <StepIcon iconType={step.displayType} size="sm" variant={isQuiet ? 'quiet' : 'loud'}/>
                <StepName name={step.displayName} />
            </div>
            {isExecMode && <>
                {isRunning && <div className="flex gap-2">
                    <RejectButton onPress={onSubmit} step={step} active={workspaceCtx.mode == WorkspaceMode.Execute}/>
                    <ApproveButton onPress={onSubmit} step={step} active={workspaceCtx.mode == WorkspaceMode.Execute}/>
                </div>}
                {!isRunning && <ApprovalBadge status={step.status} />}
            </>}
        </div>
    </>
})


const ApprovalBadge = ({status}: {status: Nullable<StepStatus>}) => {
    if (status == StepStatus.Succeeded)
        return <SwBadge variant="neutral"><SwIcon icon={IconCheckbox}/><SwText>Approved</SwText></SwBadge>
    else if (status == StepStatus.Failed)
        return <SwBadge variant="neutral"><SwIcon icon={IconX}/><SwText>Rejected</SwText></SwBadge>
    else
        return <></>
}

interface ApproveButtonProps {
    onPress: (decision: boolean) => void
    active: boolean
    step: InputStep
}

const ApproveButton = observer(({step, active, onPress}: ApproveButtonProps) => {
    const complete = active && step.status == StepStatus.Succeeded

    const label = complete ? 'Approved' : 'Approve'
    const icon = complete ? IconCheckbox : IconSquare

    const variant = active ?
        complete ? 'cta' : 'primary'
        : 'secondary'

    const style = active ? 'fill' : 'outline'

    return <SwButton onPress={() => onPress(true)} size="sm" style={style} variant={variant}><SwText>{label}</SwText></SwButton>
})

const RejectButton = observer(({step, active, onPress}: ApproveButtonProps) => {
    const complete = active && step.status == StepStatus.Succeeded

    const label = complete ? 'Rejected' : 'Reject'
    const icon = complete ? IconCheckbox : IconSquare

    const variant = active ?
        complete ? 'secondary' : 'negative'
        : 'secondary'

    const style = active ? 'fill' : 'outline'

    return <SwButton onPress={() => onPress(false)} size="sm" style={style} variant={variant}><SwText>{label}</SwText></SwButton>
})