import { ExecutionAttachmentDto, StepWise } from "@/client"
import { IObservableArray, makeAutoObservable, observable } from "mobx"
import { ObservableGroupMap } from "mobx-utils"
import { createUseStore } from "./util"
import { OperationRequestOptions } from "@azure/core-client"


export class ExecutionAttachmentStore {
    executionAttachments: IObservableArray<ExecutionAttachment> = observable.array()
    byId: ObservableGroupMap<string, ExecutionAttachment>
    byExecId: ObservableGroupMap<string, ExecutionAttachment>
    byStepId: ObservableGroupMap<string | undefined, ExecutionAttachment>

    constructor(readonly sw: StepWise) {
        makeAutoObservable(this)
        this.byId = new ObservableGroupMap(this.executionAttachments, a => a.id)
        this.byExecId = new ObservableGroupMap(this.executionAttachments, a => a.executionId)
        this.byStepId = new ObservableGroupMap(this.executionAttachments, a => a.stepId)
    }

    async load(orgId: number, executionId: string) {
        const attachments = await this.sw.executionAttachmentList(orgId, executionId)
        this.addExecutionAttachments(attachments)
    }

    addExecutionAttachments(attachments: IExecutionAttachment[]) {
        for (const attachment of attachments) {
            const newAttachment = new ExecutionAttachment(attachment)
            if (this.byId.has(attachment.id)) {
                const idx = this.executionAttachments.findIndex(a => a.id == attachment.id)
                this.executionAttachments.splice(idx, 1, newAttachment)
            } else {
                this.executionAttachments.push(newAttachment)
            }
        }
    }

    async createAttachment(file: File, orgId: number, executionId: string, stepId: string | null,  reqOpts?: OperationRequestOptions) {
        let attachment: ExecutionAttachmentDto
        if (stepId) {
            attachment =  await this.sw.executionStepAttachmentCreate(
                orgId, executionId, stepId, file.type, file.size,
                { xSwFilename: file.name, data: file, requestOptions: reqOpts}
            )
        } else {
            attachment = await this.sw.executionAttachmentCreate(
                orgId, executionId, file.type, file.size,
                { xSwFilename: file.name, data: file, requestOptions: reqOpts}
            )
        }
        console.log(attachment)
        this.addExecutionAttachments([attachment])
        return attachment
    }
}
export const [ExecutionAttachmentCtx, useExecutionAttachmentStore] = createUseStore(ExecutionAttachmentStore)


export interface IAttachment {
    id: string
    orgId: number
    file: {
        mediaType: string
        fileName?: string
        size: number
    }
}

export interface IExecutionAttachment extends IAttachment {
    executionId: string
    stepId?: string
}

export class Attachment {
    id!: string
    type!: string
    size!: number
    file!: { fileName?: string, size: number }

    constructor(spec: IAttachment) {
        Object.assign(this, spec)
    }
}


export class ExecutionAttachment extends Attachment {
    executionId!: string
    stepId?: string

    constructor(spec: IExecutionAttachment) {
        super(spec)
        Object.assign(this, spec)
    }
}
