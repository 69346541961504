import { ControlBase } from './ControlBase'
import { StepWiseEditor } from '@/stores/StepWiseEditor'
import { Icon } from '@tabler/icons-react'
import { Placement } from 'react-aria'

export interface CreateControlProps {
    label: string
    icon: Icon
    isDisabled?: (editor?: StepWiseEditor) => boolean
    isActive(editor?: StepWiseEditor): boolean
    onChange(editor?: StepWiseEditor): void
}

export interface ControlProps {
    tooltipPlacement?: Placement
}

export const createControl = ({ label, isActive, onChange, icon, isDisabled }: CreateControlProps) => (props: ControlProps) => {
    // const { editor, labels } = useRichTextEditorContext();
    // const _label = labels[label] as string;
    return (
    <ControlBase
        label={label}
        isActive={isActive}
        isDisabled={isDisabled}
        onChange={onChange}
        icon={icon}
        {...props}
    />
    )
}