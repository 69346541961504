import {
    ListBoxItemProps,
    Label,
    Select as AriaSelect,
    SelectValue,
    SelectProps as AriaSelectProps,
    ListBoxItem as AriaItem } from 'react-aria-components'
import {ListBox, Text} from 'react-aria-components'
import { SwButton } from '../Button/Button'
import { SwPopover } from '../Popover/Popover'

import "./Select.css"
import { SwText } from '../Text/Text'
import { SwIcon } from '../Icon/Icon'
import { IconChevronDown } from '@tabler/icons-react'

interface SelectProps<T extends object>
    extends Omit<AriaSelectProps<T>, 'children'> {
    label?: string
    description?: string
    errorMessage?: string
    isReadonly?: boolean
    children?: React.ReactNode | ((item: T) => React.ReactNode)
}

export function SwSelect<T extends object>(
    { label, description, errorMessage, children, isReadonly, ...props }: SelectProps<T>
) {
    return (
        <AriaSelect {...props}>
            {label && <Label className="block text-gray-700 text-sm font-bold mb-2">{label}</Label>}
            {!isReadonly ?
            <SwButton>
                <SwText><SelectValue /></SwText>
                <SwIcon icon={IconChevronDown}/>
            </SwButton> :
            <div className='h-[32px] p-1 inline-block'>
                <SelectValue/>
            </div>}
            {description && <Text slot="description">{description}</Text>}
            {errorMessage && <Text slot="errorMessage">{errorMessage}</Text>}
            <SwPopover>
                <div className='p-1'>
                    <ListBox>
                        {children as any}
                    </ListBox>
                </div>
            </SwPopover>
        </AriaSelect>
    )
}

export function SwSelectItem(props: ListBoxItemProps) {
    return (
    <AriaItem
        {...props}
        className={({ isFocused, isSelected }) =>
            `my-item ${isFocused ? 'focused' : ''} ${isSelected ? 'selected' : ''}`}
    />
    )
}
