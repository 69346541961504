import { IconAlignCenter, IconAlignLeft, IconAlignRight, IconAppWindow, IconBlockquote, IconBold, IconCode, IconCodeDots, IconCodePlus, IconColumnInsertRight, IconColumnRemove, IconH1, IconH2, IconH3, IconItalic, IconLayoutGrid, IconList, IconListNumbers, IconMessage2Exclamation, IconQuote, IconRowInsertBottom, IconRowRemove, IconSquare, IconStrikethrough, IconTable, IconTableColumn, IconTableRow } from "@tabler/icons-react"
import { createControl } from "./createControl"
import { computed, toJS } from "mobx"


export const Bold = createControl({
    label: "Bold",
    icon: IconBold,
    isActive: (editor) => editor?.proseCache.obj.activeMarkTypes.has(editor.tipTap.schema.marks['bold']) || false,
    onChange: (editor) => { editor?.tipTap.chain().focus().toggleBold().run() }
})

export const Italic = createControl({
    label: "Italic",
    icon: IconItalic,
    isActive: (editor) => editor?.proseCache.obj.activeMarkTypes.has(editor.tipTap.schema.marks['italic']) || false,
    onChange: (editor) => { editor?.tipTap.chain().focus().toggleItalic().run() }
})

export const Strike = createControl({
    label: "Strike through",
    icon: IconStrikethrough,
    isActive: (editor) => editor?.proseCache.obj.activeMarkTypes.has(editor.tipTap.schema.marks['strike']) || false,
    onChange: (editor) => { editor?.tipTap.chain().focus().toggleStrike().run() }
})

export const Code = createControl({
    label: "Inline Code",
    icon: IconCodeDots,
    isActive: (editor) => editor?.proseCache.obj.activeMarkTypes.has(editor.tipTap.schema.marks['code']) || false,
    onChange: (editor) => { editor?.tipTap.chain().focus().toggleCode().run() }
})

export const CodeBlock = createControl({
    label: "Code block",
    icon: IconCode,
    isActive: (editor) => editor?.proseCache.obj.containingNodeTypes.has(editor.tipTap.schema.nodes['codeBlock']) || false,
    onChange: (editor) => { editor?.tipTap.chain().focus().toggleCodeBlock().run() }
})

export const BlockQuote = createControl({
    label: "Block quote",
    icon: IconBlockquote,
    isActive: (editor) => editor?.proseCache.obj.containingNodeTypes.has(editor.tipTap.schema.nodes['blockquote']) || false,
    onChange: (editor) => { editor?.tipTap.chain().focus().toggleBlockquote().run() }
})

export const Alert = createControl({
    label: "Alert",
    icon: IconMessage2Exclamation,
    isActive: (editor) => editor?.proseCache.obj.containingNodeTypes.has(editor.tipTap.schema.nodes['alert']) || false,
    onChange: (editor) => { editor?.tipTap.chain().focus().toggleAlert().run() }
})

export const Step = createControl({
    label: "Step",
    icon: IconMessage2Exclamation,
    isActive: (editor) => editor?.proseCache.obj.containingNodeTypes.has(editor.tipTap.schema.nodes['sw-step']) || false,
    onChange: (editor) => { editor?.tipTap.chain().focus().toggleSwStep().run() }
})

export const OrderedList = createControl({
    label: "Ordered List",
    icon: IconListNumbers,
    isActive: (editor) => editor?.proseCache.obj.containingNodeTypes.has(editor.tipTap.schema.nodes['orderedList']) || false,
    onChange: (editor) => editor?.tipTap.chain().focus().toggleOrderedList().run()
})

export const BulletList = createControl({
    label: "Bullet List",
    icon: IconList,
    isActive: (editor) => editor?.proseCache.obj.containingNodeTypes.has(editor.tipTap.schema.nodes['orderedList']) || false,
    onChange: (editor) => editor?.tipTap.chain().focus().toggleBulletList().run()
})

export const H1 = createControl({
    label: "Heading 1",
    icon: IconH1,
    isActive: (editor) => {
        return computed(() => {
            return Array.from(editor?.proseCache.obj.containingNodes.keys() || []).some(n => n.type.name == 'heading' && n.attrs.level == 1)
        }).get()
    },
    onChange: (editor) => { editor?.tipTap.chain().focus().toggleHeading({ level: 1}).run() }
})

export const H2 = createControl({
    label: "Heading 2",
    icon: IconH2,
    isActive: (editor) => {
        return computed(() => {
            return Array.from(editor?.proseCache.obj.containingNodes.keys() || []).some(n => n.type.name == 'heading' && n.attrs.level == 2)
        }).get()
    },
    onChange: (editor) => { editor?.tipTap.chain().focus().toggleHeading({ level: 2}).run() }
})

export const H3 = createControl({
    label: "Heading 3",
    icon: IconH3,
    isActive: (editor) => {
        return computed(() => {
            return Array.from(editor?.proseCache.obj.containingNodes.keys() || []).some(n => n.type.name == 'heading' && n.attrs.level == 3)
        }).get()
    },
    onChange: (editor) => { editor?.tipTap.chain().focus().toggleHeading({ level: 3}).run() }
})

export const AlignTextLeft = createControl({
    label: "Justify left",
    icon: IconAlignLeft,
    isActive: (editor) => false,
    onChange: (editor) => { editor?.tipTap.chain().focus().setTextAlign("left").run() }
})

export const AlignTextCenter = createControl({
    label: "Justify Center",
    icon: IconAlignCenter,
    isActive: (editor) => false,
    onChange: (editor) => { editor?.tipTap.chain().focus().setTextAlign("center").run() }
})

export const AlignTextRight = createControl({
    label: "Justify left",
    icon: IconAlignRight,
    isActive: (editor) => false,
    onChange: (editor) => { editor?.tipTap.chain().focus().setTextAlign("right").run() }
})


export const Embed = createControl({
    label: "Embed",
    icon: IconAppWindow,
    isDisabled: (editor) => {
        return computed(() => {
            if (editor?.proseCache.obj.selectedNodeTypes.has(editor.tipTap.schema.nodes['text']))
                return false

            return false
        }).get()
    },
    isActive: (editor) => false,
    onChange: (editor) => {
        const selection = editor?.tipTap.state.selection

        const text = selection?.content().content.textBetween(0, selection.content().content.size)
        editor?.tipTap.commands.setEmbed({src: text!})
    }
})


export const Table = createControl({
    label: "Table",
    icon: IconTable,
    isActive: (editor) => false,
    onChange: (editor) => { editor?.tipTap.chain().focus().insertTable({rows: 3, cols: 3, withHeaderRow: true}).run() }
})

export const AddRowAfter = createControl({
    label: "Insert row after",
    icon: IconRowInsertBottom,
    isActive: (editor) => false,
    onChange: (editor) => { editor?.tipTap.chain().focus().addRowAfter().run() }
})

export const AddColumnAfter = createControl({
    label: "Insert column after",
    icon: IconColumnInsertRight,
    isActive: (editor) => false,
    onChange: (editor) => { editor?.tipTap.chain().focus().addColumnAfter().run() }
})

export const DeleteRow = createControl({
    label: "Delete row",
    icon: IconRowRemove,
    isActive: (editor) => false,
    onChange: (editor) => { editor?.tipTap.chain().focus().deleteRow().run() }
})

export const DeleteColumn = createControl({
    label: "Delete column",
    icon: IconColumnRemove,
    isActive: (editor) => false,
    onChange: (editor) => { editor?.tipTap.chain().focus().deleteColumn().run() }
})

export const HeaderRow = createControl({
    label: "Toggle header row",
    icon: IconTableRow,
    isActive: (editor) => false,
    onChange: (editor) => { editor?.tipTap.chain().focus().toggleHeaderRow().run() }
})

export const HeaderColumn = createControl({
    label: "Toggle header column",
    icon: IconTableColumn,
    isActive: (editor) => false,
    onChange: (editor) => { editor?.tipTap.chain().focus().toggleHeaderColumn().run() }
})

export const MergeCells = createControl({
    label: "Merge cells",
    icon: IconSquare,
    isActive: (editor) => false,
    onChange: (editor) => { editor?.tipTap.chain().focus().mergeCells().run() }
})

export const SplitCell = createControl({
    label: "Split cell",
    icon: IconLayoutGrid,
    isActive: (editor) => false,
    onChange: (editor) => { editor?.tipTap.chain().focus().splitCell().run() }
})