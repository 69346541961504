import { useNavigate, useSearchParams } from "react-router-dom"
import { RegistrationForm } from "./RegistrationForm"
import { Logo } from "@/components/Logo"
import { Link, RouterLink } from "@/components/Link"

export const RegistrationScreen = () => {
    const [params, _] = useSearchParams()
    const navigate = useNavigate()

    const continueTo = () => {
        const continueTo = params.get('continueTo')
        if (continueTo) {
            navigate(continueTo)
        } else {
            navigate('/')
        }
    }

    return (
        <div className="flex items-center w-screen h-screen justify-around radial-top-right  bg-gradient-to-r from-indigo-500 via-purple-400 to-pink-400 dark:from-indigo-900 dark:via-purple-900 dark:to-pink-900">
            <div className="max-w-6xl mx-auto grid grid-cols-12 gap-12 bg-white dark:bg-zinc-900 rounded-xl p-12 shadow-xl">
                <div className="col-span-6 p-8 flex items-center">
                    <div>
                        <Logo/>
                        <div className="mt-4 text-2xl font-medium text-indigo-800 dark:text-indigo-500 leading-6">Effortlessly create Process Automation for your team.</div>
                        <div className="dark:text-zinc-100 text-sm leading-5 mt-4">Fine-tune automation through a blend of manual and automated tasks until the perfect equilibrium is achieved.</div>
                        <div className="mt-6 text-gray-600 dark:text-zinc-100"><span className="text-black bg-yellow-300 py-1 px-2 font-bold">Create an account</span> or <Link className="underline"><RouterLink to="/auth/login">Log In</RouterLink></Link> with an existing one to continue.</div>
                        <div className="mt-4 max-w-sm">
                            <RegistrationForm onLogin={continueTo}/>
                        </div>
                    </div>

                </div>
                <div className="col-span-6 flex items-center">
                    <div className="p-6 bg-[rgb(238,242,255,0.5)] rounded-lg">
                        <img src="/images/svg/laidback.svg" className="w-9/12 mx-auto"></img>

                        <div className="bg-indigo-100 dark:bg-[rgb(238,242,255,0.3)] p-4 text-center rounded-lg">
                            <div className="text-sm font-bold text-indigo-900 tracking-loose">BUILT WITH:</div>
                            <img src="/images/svg/stack.svg" className="mt-4"/>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}