import { useWorkspaceCtx } from "@/contexts"
import { observer } from "mobx-react-lite"

import { SwTabs as AriaTabs, SwTab, SwTabList as AriaTabList, SwTabPanel } from "@/components/Tabs/Tabs"
import { StepPanel } from "./StepPanel"
import { useRootStore } from "@/hooks/useStores"
import { useEffect } from "react"
import { RunbookActivityList } from "@/components/widgets/RunbookActivityList/RunbookActivityList"


export const RightPanel = observer(() => {
    const workspaceCtx = useWorkspaceCtx()

    const runbook = workspaceCtx.activeRunbook
    const activeWorkflows = runbook?.executions.filter(obj => obj.executionStatus === 'Running').length

    if (!workspaceCtx.activeRunbook) return <></>

    return <>
        <AriaTabs onSelectionChange={() => {}}>
            <div className="flex items-center justify-between px-4 bg-neutral-50 border-b border-neutral-200">
                <AriaTabList aria-label="Right Bar">
                    <SwTab id="options">Step Settings</SwTab>
                    <SwTab id="activity">Activity
                    {activeWorkflows ? <span className="bg-red-500 text-white rounded-full px-2 ml-2 text-xs font-bold">{activeWorkflows}</span> : null}
                    </SwTab>
                </AriaTabList>

                {/* <SwTooltipTrigger>
                    <SwToggleButton isQuiet><Icon icon={IconLayoutSidebarRightExpand}/></SwToggleButton>
                    <SwTooltip placement="bottom">Expand</SwTooltip>
                </SwTooltipTrigger> */}
            </div>
            <SwTabPanel id="options">
                <StepPanel/>
            </SwTabPanel>
            <SwTabPanel id="activity">
                <ActivityTab/>
            </SwTabPanel>
        </AriaTabs>
    </>
})

const ActivityTab = observer(() => {
    const workspaceCtx = useWorkspaceCtx()
    const rootStore = useRootStore()
    const runbooks = rootStore.runbooks

    const runbook = workspaceCtx.activeRunbook

    useEffect(() => {
        runbooks.loadRunbookExecutions(runbook!)
    }, [runbook])

    if(runbook?.executions?.length || 0 > 0){
        return (
        <div className="h-full sw-scroller--slim">
            <RunbookActivityList executions={runbook?.executions || []}/>
        </div>
        )
    } else {
        return (
            <div className="my-12 text-center">
                <img src="/images/svg/pencilpointer.svg" className="mx-auto" />
                <div className="font-bold mt-3">No Runbooks have been executed</div>
                <div className="mt-1">Run this runbook to get started</div>
            </div>
        )
    }

})