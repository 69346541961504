import clsx from "clsx"
import { ReactElement } from "react"


interface Props {
    className?: string
    children: [ReactElement, ReactElement]
}

export const SplitGroup = ({children, className}: Props) => {
    return (
    <div className={clsx("flex justify-between items-center w-full h-full min-h-0", className)}>
      {children[0]}
      {children[1]}
    </div>
    )
}