import { action, computed, observable, runInAction } from "mobx"
import { Runbook, RunbookExecution, RunbookStore } from "./Runbook"
import { WorkflowStore } from "./Workflow"
import { ExecutionStatus, Workflow } from "./WorkflowDefinition"
import { OrgCtx } from "./OrgCtx"
import { Doc, DocRef, DocStore } from "./DocStore"
import { StepWiseEditor } from "./StepWiseEditor"
import { IAppRouter } from "./AppStore"


export enum WorkspaceMode {
    Read = "Read",
    Write = "Write",
    Execute = "Execute"
}

export enum RunbookView {
    Workflow = "Workflow",
    Runbook = "Runbook",
    Insights = "Insights",
    Summary = "Summary"
}

export enum WorkspaceEvent {
    ModeChanged = "ModeChanged"
}

export interface ModeChangedEvt extends CustomEvent<WorkspaceMode> {}

type ModeChangedEvtHandler = (this: any, e: ModeChangedEvt) => void


interface WorkspaceEventTarget extends EventTarget {
    addEventListener(type: WorkspaceEvent.ModeChanged, listener: ModeChangedEvtHandler, options?: AddEventListenerOptions | boolean): void
    removeEventListener(type: WorkspaceEvent.ModeChanged, listener: ModeChangedEvtHandler, options?: AddEventListenerOptions | boolean): void
    addEventListener(type: string, callback: EventListenerOrEventListenerObject | null, options?: AddEventListenerOptions | boolean): void
    removeEventListener(type: string, callback: EventListenerOrEventListenerObject | null, options?: AddEventListenerOptions | boolean): void
}

class WorkspaceEventTarget extends EventTarget {}

export class WorkspaceCtx {
    orgCtx: OrgCtx
    docs: DocStore
    runbooks: RunbookStore
    workflows: WorkflowStore
    router: IAppRouter

    @observable accessor mode: WorkspaceMode = WorkspaceMode.Read
    @observable accessor view: RunbookView = RunbookView.Runbook
    @observable accessor activeRunbook: Nullable<Runbook> = null
    @observable accessor activeDoc: Nullable<Doc> = null
    @observable accessor activeRunbookExecution: Nullable<RunbookExecution> = null
    @observable accessor editor: Nullable<StepWiseEditor> = null

    private eventTarget: WorkspaceEventTarget = new WorkspaceEventTarget

    constructor(orgCtx: OrgCtx, docs: DocStore, runbooks: RunbookStore, workflows: WorkflowStore, router: IAppRouter) {
        this.orgCtx = orgCtx
        this.docs = docs
        this.runbooks = runbooks
        this.workflows = workflows
        this.router = router
        this.workflowRefresh()
        // @ts-ignore
        window.workspace = this
    }

    @computed get orgId() {
        const orgId = this.orgCtx.orgId
        if (!orgId) throw new Error("No orgId set")
        return orgId
    }

    @computed get activeWorkflow() {
        if (this.mode == WorkspaceMode.Execute) {
            if (!this.activeRunbookExecution) return null
            return this.workflows.getByRef(this.activeRunbookExecution.workflowRef)
        } else {
            if (!this.activeRunbook) return null
            else return this.workflows.getByRef(this.activeRunbook.workflowRef)
        }
    }

    async createRunbook(parent?: Runbook, name?: string) {
        const [runbook] = await this.runbooks.create(this.orgId, 1, name, parent)
        this.workflows.load(runbook.orgId, runbook.workflowRef.id)
        this.setActiveRunbook(runbook, WorkspaceMode.Write)
    }

    @action setActiveEditor(editor: Nullable<StepWiseEditor>) {
        this.editor = editor
    }

    @action setActiveDoc(doc: Nullable<DocRef>) {
        if (!doc) { this.activeDoc = null; return }
        this.activeDoc?.fromSpec(doc) ?? (this.activeDoc = new Doc(doc))
    }

    @action setActiveRunbook(runbook: Nullable<Runbook>, mode = WorkspaceMode.Read) {
        if (runbook) {
            this.docs.preLoad(runbook.doc)
            this.workflows.load(runbook.orgId, runbook.workflowRef.id)
            this.activeRunbook = runbook
        }
        this.setActiveDoc(runbook?.doc ?? null)
        this.setMode(mode)
    }

    @action setActiveRunbookExecution(execution: RunbookExecution) {
        this.activeRunbookExecution = execution
        this.docs.preLoad(execution.doc)
        this.setActiveDoc(execution.doc)
        this.workflows.load(execution.orgId, execution.workflowRef.id, execution.workflowRef.revision).then(() => {
            this.workflows.loadExecution(execution.orgId, execution.executionId)
        })
        this.setMode(WorkspaceMode.Execute)
        this.router.navigate(`/${this.orgId}/1/runbook-execution/${execution.id}`)
    }

    async deleteRunbook(runbook: Runbook) {
        await this.runbooks.delete(runbook)
        runInAction(() => {
            if (this.activeRunbook != runbook) return
            console.log('Setting active runbook null')
            this.setActiveRunbook(null)
        })
    }

    @action setMode(mode: WorkspaceMode) {
        this.mode = mode
        this.eventTarget.dispatchEvent(new CustomEvent(WorkspaceEvent.ModeChanged, {detail: mode}))
    }

    @action setRunbookView(view: RunbookView) {
        this.view = view
    }

    async publishActiveRunbook() {
        if (!this.activeRunbook) return

        await this.runbooks.publish(this.activeRunbook)
        this.docs.preLoad(this.activeRunbook.doc)
        this.setMode(WorkspaceMode.Read)
    }

    @action async loadActiveRunbookExecution(executionId: string) {
        if (this.orgId == null) throw new Error(`Unable to load Runbook for null org`)

        const runbookExecution = await this.runbooks.loadRunbookExecution(this.orgId, executionId)
        const runbook = await this.runbooks.loadById(this.orgId, runbookExecution.runbookId)
        await this.workflows.load(runbook.orgId, runbookExecution.workflowRef.id, runbookExecution.workflowRef.revision)
        runInAction(() => {
            this.setActiveRunbook(runbook, WorkspaceMode.Execute)
            this.setActiveRunbookExecution(runbookExecution)
        })
    }

    async loadActiveRunbook(id: string, mode = WorkspaceMode.Read) {
        if (this.orgId == null) throw new Error(`Unable to load Runbook for null org`)

        const runbook = await this.runbooks.loadById(this.orgId, id)
        this.setActiveRunbook(runbook, mode)
        await this.workflows.load(runbook.orgId, runbook.workflowRef.id)
    }

    async executeRunbook(runbook: Runbook) {
        const execution = await this.runbooks.execute(runbook)
        this.setActiveRunbookExecution(execution)
    }

    async executeWorkflow(workflow: Workflow) {
        await this.workflows.execute(workflow)
        this.setMode(WorkspaceMode.Execute)
    }

    onDidChangeMode(fn: ModeChangedEvtHandler): () => void {
        this.eventTarget.addEventListener(WorkspaceEvent.ModeChanged, fn)
        return () => this.eventTarget.removeEventListener(WorkspaceEvent.ModeChanged, fn)
    }

    async workflowRefresh() {
        while (true) {
            await new Promise((res, _) => setTimeout(res, 1000))
            if (this.mode != WorkspaceMode.Execute) continue
            const wf = this.activeWorkflow
            if (!wf || wf?.state?.status != ExecutionStatus.Running) continue
            await this.workflows.refreshWorkflow(wf)
        }
    }
}