import { observer } from "mobx-react-lite"
import { SwSelect, SwSelectItem } from "@/components/Select/Select"
import { useThemeCtx } from "@/stores/Theme"


export const ThemePicker = observer(() => {
    const theme = useThemeCtx()

    const onChange = (key: React.Key) => {
        console.log('Changing theme to', key)
        theme.setUserPreference(key as any)
    }

    return (
        <div className="p-2">
            <SwSelect label="Theme" selectedKey={theme.theme} onSelectionChange={onChange}>
                <SwSelectItem id="system"><span className="p-1">System</span></SwSelectItem>
                <SwSelectItem id="light"><span className="p-1">Light</span></SwSelectItem>
                <SwSelectItem id="dark"><span className="p-1">Dark</span></SwSelectItem>
            </SwSelect>
        </div>
    )
})