import { createContext } from "react"
import { RootStore } from "./stores/Root"
import { OrgCtx } from "./stores/OrgCtx"
import { WorkspaceCtx } from "./stores/WorkspaceCtx"
import { AuthStore } from "./stores/AuthStore"
import { StepWiseEditor } from "./stores/StepWiseEditor"
import { createUseCtx } from "./hooks/createUseCtx"


export const RootContext = createContext<RootStore | null>(null)

export const OrgContext = createContext<OrgCtx | null>(null)

export const [WorkspaceContext, useWorkspaceCtx] = createUseCtx<WorkspaceCtx>()

export const AuthContext = createContext<AuthStore | null>(null)

export const EditorContext = createContext<StepWiseEditor | null>(null)