import { observer } from "mobx-react-lite"
import { StepIcon } from "@/components/Workflow/StepIcon"
import { StepName } from "./component/StepName"
import { Step, StepStatus } from "@/stores/WorkflowDefinition"
import { cn } from "@/util/cn"


export const StepLabel = observer(({step, variant = 'label', onClick}: {step: Step, variant?: 'tab' | 'label', onClick: () => void}) => {
    const isRunning = step.status == StepStatus.Running

    return (
        <div
            className={cn(
                "hover:cursor-pointer flex justify-start items-center space-x-1 bg-neutral-50 rounded pr-2 max-w-fit rounded-tl-none rounded-tr-none",
                variant == "tab" && "border-none bg-slate-100")}
            onClick={onClick}
        >
            <StepIcon iconType={step.displayType} size="sm" variant={isRunning ? 'loud' : 'quiet'} className="rounded-tl-none"/>
            <StepName name={step.displayName} />
        </div>
    )
})