import { ClearSlots, useSlotProps } from "@react-spectrum/utils"


export interface ContentProps {
    slot?: string
    children?: React.ReactNode
}

export const Content = (props: ContentProps) => {
    props = useSlotProps(props, 'content')

    return (
        <section {...props} slot={props.slot ?? 'content'}>
            <ClearSlots>
                {props.children}
            </ClearSlots>
        </section>
    )
}