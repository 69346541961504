import {Plugin, Transaction} from "prosemirror-state"
import {Decoration, DecorationSet} from "prosemirror-view"

function genSelectionDecorators(tr: Transaction) {
    let decos = []
    const {$to, $from} = tr.selection
    decos.push(Decoration.inline($from.pos, $to.pos, {class: "selected"}))
    return DecorationSet.create(tr.doc, decos)
}

export const selectionStylePlugin = new Plugin({
    state: {
        init(_, {doc}) { return DecorationSet.create(doc, []) },
        apply(tr) { return genSelectionDecorators(tr) }
    },
    props: {
        decorations(state) { return this.getState(state) },
    }
})