import { GridList, ListBoxItem } from "react-aria-components"
import { useNavigate } from "react-router-dom"

import { SplitGroup } from "@/components/SplitGroup/SplitGroup"
import { useWorkspaceCtx } from "@/contexts"
import { RunbookExecution } from "@/stores/Runbook"
import { ExecutionStatus } from "@/stores/WorkflowDefinition"
import { WorkspaceMode } from "@/stores/WorkspaceCtx"
import { IconProgress, IconProgressCheck, IconProgressX } from "@tabler/icons-react"

import './RunbookActivityList.css'
import { observer } from "mobx-react-lite"

export const RunbookActivityList = observer(({executions}: {executions: RunbookExecution[]}) => {
    const workspaceCtx = useWorkspaceCtx()
    const navigate = useNavigate()

    const selectKeys = workspaceCtx.mode == WorkspaceMode.Execute ?
        [workspaceCtx.activeRunbookExecution?.id ?? ""] :
        []

    return <>
        <GridList
            className="sw-activitylist"
            selectionMode="single"
            items={executions}
            selectedKeys={selectKeys}
            onSelectionChange={id => {
                if (id == 'all' || id.size == 0) return
                const key = id.entries().next().value[0]
                workspaceCtx.setActiveRunbookExecution(executions.find(e => e.id == key)!)
            }}
        >
            {item => <ActivityRow execution={item}/>}
        </GridList>
    </>
})

const ActivityRow = observer(({execution}: {execution: RunbookExecution}) => {
    const { executionStatus, id } = execution

    return <ListBoxItem id={id} className="sw-activitylist-item bg-white">
        <SplitGroup>
            <div>
                <div>
                    <pre><span className="text-sm font-bold font-mono">#{id.substring(0, 6)}</span></pre>
                </div>
                <div>{execution.createdAt.toLocaleString()}</div>
            </div>
            <div className="ml-auto">
                {executionStatus == ExecutionStatus.Running && <div>
                    <IconProgress className="text-amber-600 bg-amber-100 rounded-full animate-spin"/>
                </div>}
                {executionStatus == ExecutionStatus.Succeeded && <div>
                    <IconProgressCheck className="text-white bg-green-700 rounded-full"/>
                </div>}
                {executionStatus == ExecutionStatus.Failed && <div>
                    <IconProgressX className="text-white bg-red-600 rounded-full"/>
                </div>}
            </div>
        </SplitGroup>
    </ListBoxItem>
})