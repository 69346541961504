import {
    OverlayArrow,
    Tooltip as AriaTooltip,
    TooltipProps as AriaTooltipProps,
    TooltipTrigger as AriaTooltipTrigger} from 'react-aria-components'

import './Tooltip.css'
import { TooltipTriggerProps } from 'react-aria'
import { TooltipTriggerComponentProps } from 'react-aria-components'

export interface TooltipProps extends Omit<AriaTooltipProps, 'children'> {
    children?: React.ReactNode
}

export const SwTooltip = ({children, ...props}: TooltipProps) => {
    return (
        <AriaTooltip {...props}>
            <OverlayArrow>
                <svg width={8} height={8}><path d="M0 0,L4 4,L8 0" /></svg>
            </OverlayArrow>
            {children}
        </AriaTooltip>
    )
}


export const SwTooltipTrigger = ({children, ...props}: TooltipTriggerComponentProps) => {
    props.delay ||= 400
    props.closeDelay ||= 100

    return (
        <AriaTooltipTrigger {...props}>
            {children}
        </AriaTooltipTrigger>
    )
}