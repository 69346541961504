import clsx from "clsx"
import React, { InputHTMLAttributes, LabelHTMLAttributes, ReactElement, ReactNode, Ref, RefObject, TextareaHTMLAttributes, forwardRef, useEffect, useRef } from "react"
import { useFocusRing } from "react-aria"
import { Input, InputContext, Label, Text, TextField, TextFieldProps } from "react-aria-components"

import './TextField.css'
import { Icon } from "../Icon"
import { TablerIconsProps } from "@tabler/icons-react"
import { SwInput } from "./Input"

export interface SwTextFieldProps extends TextFieldProps {
    label?: string
    description?: string
    errorMessage?: string
    placeholder?: string
    icon?: (props: TablerIconsProps) => React.ReactElement
}

export const SwTextField = forwardRef((props: SwTextFieldProps, ref: Ref<HTMLInputElement>) => {
    const {
        label,
        description,
        errorMessage,
        ...otherProps
    } = props

    return (
        <TextField {...otherProps}>
            <InputInternals {...props} ref={ref}/>
        </TextField>
    )
})

const InputInternals = forwardRef((props: SwTextFieldProps, ref: Ref<HTMLInputElement>) => {
    const {
        label,
        description,
        errorMessage,
        icon,
        isReadOnly,
        ...otherProps
    } = props

    const inputContext = React.useContext(InputContext)
    // console.log("Input context", inputContext)

    return (<>
        <Label>{label}</Label>
        <div className={clsx(
            "textfield__input-wrapper relative flex items-center",
        )}>
            <SwInput withIcon={!!icon} ref={ref} readOnly={!!isReadOnly} />
            {icon && <Icon className="absolute left-1 text-gray-500" icon={icon}/>}
        </div>
        {description && <Text slot="description">{description}</Text>}
        {errorMessage && <Text slot="errorMessage">{errorMessage}</Text>}
    </>)
})