import * as coreClient from "@azure/core-client";
import * as Parameters from "./models/parameters";
import * as Mappers from "./models/mappers";
import {
  StepWiseOptionalParams,
  AuthGetOptionalParams,
  AuthGetResponse,
  AuthLoginDto,
  AuthLoginOptionalParams,
  AuthLoginResponse,
  AuthLogoutOptionalParams,
  AuthLogoutResponse,
  AuthLoginExternalOptionalParams,
  AuthRemoteCallbackOptionalParams,
  AuthRegisterDto,
  AuthRegisterOptionalParams,
  AuthRegisterResponse,
  AuthEmailConfirmOptionalParams,
  AuthEmailConfirmResponse,
  AuthPasswordForgotOptionalParams,
  AuthPasswordForgotResponse,
  AuthPasswordResetOptionalParams,
  AuthPasswordResetResponse,
  DocPublishOptionalParams,
  DocPublishResponse,
  DocMaterialGetOptionalParams,
  DocMaterialGetResponse,
  DocAttachmentCreateOptionalParams,
  DocAttachmentCreateResponse,
  FileGetOptionalParams,
  FileCreateInOrgOptionalParams,
  FileCreateInOrgResponse,
  WorkflowGetOptionalParams,
  WorkflowGetResponse,
  WorkflowCreateDto,
  WorkflowUpdateOptionalParams,
  WorkflowUpdateResponse,
  WorkflowCreateOptionalParams,
  WorkflowCreateResponse,
  ExecutionCreateOptionalParams,
  ExecutionCreateResponse,
  ExecutionStopOptionalParams,
  ExecutionStopResponse,
  ExecutionGetOptionalParams,
  ExecutionGetResponse,
  ExecutionGetForUserOptionalParams,
  ExecutionGetForUserResponse,
  StepActivityGetForUserOptionalParams,
  StepActivityGetForUserResponse,
  BulkResponseCreateDto,
  ExecutionResponseBulkCreateOptionalParams,
  ExecutionResponseBulkCreateResponse,
  ReviewCreateDto,
  ExecutionReviewCreateOptionalParams,
  ExecutionReviewCreateResponse,
  TaskListOptionalParams,
  TaskListResponse,
  ExecutionAttachmentCreateOptionalParams,
  ExecutionAttachmentCreateResponse,
  ExecutionAttachmentListOptionalParams,
  ExecutionAttachmentListResponse,
  ExecutionStepAttachmentCreateOptionalParams,
  ExecutionStepAttachmentCreateResponse,
  ExecutionStepAttachmentListOptionalParams,
  ExecutionStepAttachmentListResponse,
  WorkflowHistoryGetOptionalParams,
  WorkflowHistoryGetResponse,
  OrgGetByIdOptionalParams,
  OrgGetByIdResponse,
  OrgGetStatusOptionalParams,
  OrgGetStatusResponse,
  RunbookListByOrgOptionalParams,
  RunbookListByOrgResponse,
  RunbookListByOrgAndWorkspaceOptionalParams,
  RunbookListByOrgAndWorkspaceResponse,
  RunbookCreateDto,
  RunbookCreateOptionalParams,
  RunbookCreateResponse,
  RunbookGetOptionalParams,
  RunbookGetResponse,
  RunbookUpdateDto,
  RunbookUpdateOptionalParams,
  RunbookUpdateResponse,
  RunbookDeleteOptionalParams,
  RunbookPathOptionalParams,
  RunbookMoveDto,
  RunbookMoveOptionalParams,
  RunbookMoveResponse,
  RunbookExecuteOptionalParams,
  RunbookExecuteResponse,
  RunbookExecutionGetOptionalParams,
  RunbookExecutionGetResponse,
  RunbookExecutionListOptionalParams,
  RunbookExecutionListResponse,
  RunbookPublishOptionalParams,
  RunbookPublishResponse,
  RunbookSearchOptionalParams,
  RunbookSearchResponse,
  UserListOptionalParams,
  UserListResponse,
  UserOrgListOptionalParams,
  UserOrgListResponse,
  WorkspaceListOptionalParams,
  WorkspaceListResponse,
  WorkspaceGetOptionalParams,
  WorkspaceGetResponse,
  WorkspaceUpdateOptionalParams,
  WorkspaceCreateDto,
  WorkspaceCreateOptionalParams,
  WorkspaceCreateResponse,
} from "./models";

export class StepWise extends coreClient.ServiceClient {
  $host: string;
  apiVersion: string;
  xSwOrgId: number;

  /**
   * Initializes a new instance of the StepWise class.
   * @param $host server parameter
   * @param xSwOrgId The Org ID
   * @param options The parameter options
   */
  constructor(
    $host: string,
    xSwOrgId: number,
    options?: StepWiseOptionalParams,
  ) {
    if ($host === undefined) {
      throw new Error("'$host' cannot be null");
    }
    if (xSwOrgId === undefined) {
      throw new Error("'xSwOrgId' cannot be null");
    }

    // Initializing default values for options
    if (!options) {
      options = {};
    }
    const defaults: StepWiseOptionalParams = {
      requestContentType: "application/json; charset=utf-8",
    };

    const packageDetails = `azsdk-js-stepWise/1.0.0-beta.1`;
    const userAgentPrefix =
      options.userAgentOptions && options.userAgentOptions.userAgentPrefix
        ? `${options.userAgentOptions.userAgentPrefix} ${packageDetails}`
        : `${packageDetails}`;

    const optionsWithDefaults = {
      ...defaults,
      ...options,
      userAgentOptions: {
        userAgentPrefix,
      },
      endpoint: options.endpoint ?? options.baseUri ?? "{$host}",
    };
    super(optionsWithDefaults);
    // Parameter assignments
    this.$host = $host;
    this.xSwOrgId = xSwOrgId;

    // Assigning values to Constant parameters
    this.apiVersion = options.apiVersion || "1";
  }

  /** @param options The options parameters. */
  authGet(options?: AuthGetOptionalParams): Promise<AuthGetResponse> {
    return this.sendOperationRequest({ options }, authGetOperationSpec);
  }

  /**
   * @param body
   * @param options The options parameters.
   */
  authLogin(
    body: AuthLoginDto,
    options?: AuthLoginOptionalParams,
  ): Promise<AuthLoginResponse> {
    return this.sendOperationRequest({ body, options }, authLoginOperationSpec);
  }

  /** @param options The options parameters. */
  authLogout(options?: AuthLogoutOptionalParams): Promise<AuthLogoutResponse> {
    return this.sendOperationRequest({ options }, authLogoutOperationSpec);
  }

  /** @param options The options parameters. */
  authLoginExternal(options?: AuthLoginExternalOptionalParams): Promise<void> {
    return this.sendOperationRequest(
      { options },
      authLoginExternalOperationSpec,
    );
  }

  /** @param options The options parameters. */
  authRemoteCallback(
    options?: AuthRemoteCallbackOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { options },
      authRemoteCallbackOperationSpec,
    );
  }

  /**
   * @param body
   * @param options The options parameters.
   */
  authRegister(
    body: AuthRegisterDto,
    options?: AuthRegisterOptionalParams,
  ): Promise<AuthRegisterResponse> {
    return this.sendOperationRequest(
      { body, options },
      authRegisterOperationSpec,
    );
  }

  /**
   * @param userId
   * @param code
   * @param options The options parameters.
   */
  authEmailConfirm(
    userId: string,
    code: string,
    options?: AuthEmailConfirmOptionalParams,
  ): Promise<AuthEmailConfirmResponse> {
    return this.sendOperationRequest(
      { userId, code, options },
      authEmailConfirmOperationSpec,
    );
  }

  /**
   * @param email
   * @param options The options parameters.
   */
  authPasswordForgot(
    email: string,
    options?: AuthPasswordForgotOptionalParams,
  ): Promise<AuthPasswordForgotResponse> {
    return this.sendOperationRequest(
      { email, options },
      authPasswordForgotOperationSpec,
    );
  }

  /**
   * @param email
   * @param code
   * @param password
   * @param options The options parameters.
   */
  authPasswordReset(
    email: string,
    code: string,
    password: string,
    options?: AuthPasswordResetOptionalParams,
  ): Promise<AuthPasswordResetResponse> {
    return this.sendOperationRequest(
      { email, code, password, options },
      authPasswordResetOperationSpec,
    );
  }

  /**
   * @param docId
   * @param orgId
   * @param options The options parameters.
   */
  docPublish(
    docId: string,
    orgId: number,
    options?: DocPublishOptionalParams,
  ): Promise<DocPublishResponse> {
    return this.sendOperationRequest(
      { docId, orgId, options },
      docPublishOperationSpec,
    );
  }

  /**
   * @param docId
   * @param options The options parameters.
   */
  docMaterialGet(
    docId: string,
    options?: DocMaterialGetOptionalParams,
  ): Promise<DocMaterialGetResponse> {
    return this.sendOperationRequest(
      { docId, options },
      docMaterialGetOperationSpec,
    );
  }

  /**
   * @param orgId
   * @param docId
   * @param contentType
   * @param contentLength
   * @param options The options parameters.
   */
  docAttachmentCreate(
    orgId: number,
    docId: string,
    contentType: string,
    contentLength: number,
    options?: DocAttachmentCreateOptionalParams,
  ): Promise<DocAttachmentCreateResponse> {
    return this.sendOperationRequest(
      { orgId, docId, contentType, contentLength, options },
      docAttachmentCreateOperationSpec,
    );
  }

  /**
   * @param orgId
   * @param fileId
   * @param options The options parameters.
   */
  fileGet(
    orgId: number,
    fileId: string,
    options?: FileGetOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { orgId, fileId, options },
      fileGetOperationSpec,
    );
  }

  /**
   * @param orgId
   * @param contentType
   * @param contentLength
   * @param options The options parameters.
   */
  fileCreateInOrg(
    orgId: number,
    contentType: string,
    contentLength: number,
    options?: FileCreateInOrgOptionalParams,
  ): Promise<FileCreateInOrgResponse> {
    return this.sendOperationRequest(
      { orgId, contentType, contentLength, options },
      fileCreateInOrgOperationSpec,
    );
  }

  /**
   * @param orgId
   * @param workflowId
   * @param options The options parameters.
   */
  workflowGet(
    orgId: number,
    workflowId: string,
    options?: WorkflowGetOptionalParams,
  ): Promise<WorkflowGetResponse> {
    return this.sendOperationRequest(
      { orgId, workflowId, options },
      workflowGetOperationSpec,
    );
  }

  /**
   * @param orgId
   * @param workflowId
   * @param body
   * @param options The options parameters.
   */
  workflowUpdate(
    orgId: number,
    workflowId: string,
    body: WorkflowCreateDto,
    options?: WorkflowUpdateOptionalParams,
  ): Promise<WorkflowUpdateResponse> {
    return this.sendOperationRequest(
      { orgId, workflowId, body, options },
      workflowUpdateOperationSpec,
    );
  }

  /**
   * @param orgId
   * @param body
   * @param options The options parameters.
   */
  workflowCreate(
    orgId: number,
    body: WorkflowCreateDto,
    options?: WorkflowCreateOptionalParams,
  ): Promise<WorkflowCreateResponse> {
    return this.sendOperationRequest(
      { orgId, body, options },
      workflowCreateOperationSpec,
    );
  }

  /**
   * @param orgId
   * @param id
   * @param options The options parameters.
   */
  executionCreate(
    orgId: number,
    id: string,
    options?: ExecutionCreateOptionalParams,
  ): Promise<ExecutionCreateResponse> {
    return this.sendOperationRequest(
      { orgId, id, options },
      executionCreateOperationSpec,
    );
  }

  /**
   * @param orgId
   * @param executionId
   * @param options The options parameters.
   */
  executionStop(
    orgId: number,
    executionId: string,
    options?: ExecutionStopOptionalParams,
  ): Promise<ExecutionStopResponse> {
    return this.sendOperationRequest(
      { orgId, executionId, options },
      executionStopOperationSpec,
    );
  }

  /**
   * @param id
   * @param options The options parameters.
   */
  executionGet(
    id: string,
    options?: ExecutionGetOptionalParams,
  ): Promise<ExecutionGetResponse> {
    return this.sendOperationRequest(
      { id, options },
      executionGetOperationSpec,
    );
  }

  /** @param options The options parameters. */
  executionGetForUser(
    options?: ExecutionGetForUserOptionalParams,
  ): Promise<ExecutionGetForUserResponse> {
    return this.sendOperationRequest(
      { options },
      executionGetForUserOperationSpec,
    );
  }

  /** @param options The options parameters. */
  stepActivityGetForUser(
    options?: StepActivityGetForUserOptionalParams,
  ): Promise<StepActivityGetForUserResponse> {
    return this.sendOperationRequest(
      { options },
      stepActivityGetForUserOperationSpec,
    );
  }

  /**
   * @param orgId
   * @param executionId
   * @param body
   * @param options The options parameters.
   */
  executionResponseBulkCreate(
    orgId: number,
    executionId: string,
    body: BulkResponseCreateDto,
    options?: ExecutionResponseBulkCreateOptionalParams,
  ): Promise<ExecutionResponseBulkCreateResponse> {
    return this.sendOperationRequest(
      { orgId, executionId, body, options },
      executionResponseBulkCreateOperationSpec,
    );
  }

  /**
   * @param executionId
   * @param body
   * @param options The options parameters.
   */
  executionReviewCreate(
    executionId: string,
    body: ReviewCreateDto,
    options?: ExecutionReviewCreateOptionalParams,
  ): Promise<ExecutionReviewCreateResponse> {
    return this.sendOperationRequest(
      { executionId, body, options },
      executionReviewCreateOperationSpec,
    );
  }

  /**
   * @param orgId
   * @param options The options parameters.
   */
  taskList(
    orgId: number,
    options?: TaskListOptionalParams,
  ): Promise<TaskListResponse> {
    return this.sendOperationRequest({ orgId, options }, taskListOperationSpec);
  }

  /**
   * @param orgId
   * @param executionId
   * @param contentType
   * @param contentLength
   * @param options The options parameters.
   */
  executionAttachmentCreate(
    orgId: number,
    executionId: string,
    contentType: string,
    contentLength: number,
    options?: ExecutionAttachmentCreateOptionalParams,
  ): Promise<ExecutionAttachmentCreateResponse> {
    return this.sendOperationRequest(
      { orgId, executionId, contentType, contentLength, options },
      executionAttachmentCreateOperationSpec,
    );
  }

  /**
   * @param orgId
   * @param executionId
   * @param options The options parameters.
   */
  executionAttachmentList(
    orgId: number,
    executionId: string,
    options?: ExecutionAttachmentListOptionalParams,
  ): Promise<ExecutionAttachmentListResponse> {
    return this.sendOperationRequest(
      { orgId, executionId, options },
      executionAttachmentListOperationSpec,
    );
  }

  /**
   * @param orgId
   * @param executionId
   * @param stepId
   * @param contentType
   * @param contentLength
   * @param options The options parameters.
   */
  executionStepAttachmentCreate(
    orgId: number,
    executionId: string,
    stepId: string,
    contentType: string,
    contentLength: number,
    options?: ExecutionStepAttachmentCreateOptionalParams,
  ): Promise<ExecutionStepAttachmentCreateResponse> {
    return this.sendOperationRequest(
      { orgId, executionId, stepId, contentType, contentLength, options },
      executionStepAttachmentCreateOperationSpec,
    );
  }

  /**
   * @param orgId
   * @param executionId
   * @param stepId
   * @param options The options parameters.
   */
  executionStepAttachmentList(
    orgId: number,
    executionId: string,
    stepId: string,
    options?: ExecutionStepAttachmentListOptionalParams,
  ): Promise<ExecutionStepAttachmentListResponse> {
    return this.sendOperationRequest(
      { orgId, executionId, stepId, options },
      executionStepAttachmentListOperationSpec,
    );
  }

  /**
   * @param orgId
   * @param workflowId
   * @param options The options parameters.
   */
  workflowHistoryGet(
    orgId: number,
    workflowId: string,
    options?: WorkflowHistoryGetOptionalParams,
  ): Promise<WorkflowHistoryGetResponse> {
    return this.sendOperationRequest(
      { orgId, workflowId, options },
      workflowHistoryGetOperationSpec,
    );
  }

  /**
   * @param orgId
   * @param options The options parameters.
   */
  orgGetById(
    orgId: number,
    options?: OrgGetByIdOptionalParams,
  ): Promise<OrgGetByIdResponse> {
    return this.sendOperationRequest(
      { orgId, options },
      orgGetByIdOperationSpec,
    );
  }

  /**
   * @param orgId
   * @param options The options parameters.
   */
  orgGetStatus(
    orgId: number,
    options?: OrgGetStatusOptionalParams,
  ): Promise<OrgGetStatusResponse> {
    return this.sendOperationRequest(
      { orgId, options },
      orgGetStatusOperationSpec,
    );
  }

  /**
   * List Runbooks by Org
   * @param orgId
   * @param options The options parameters.
   */
  runbookListByOrg(
    orgId: number,
    options?: RunbookListByOrgOptionalParams,
  ): Promise<RunbookListByOrgResponse> {
    return this.sendOperationRequest(
      { orgId, options },
      runbookListByOrgOperationSpec,
    );
  }

  /**
   * List Runbooks by Org and Workspace
   * @param orgId
   * @param workspaceId
   * @param options The options parameters.
   */
  runbookListByOrgAndWorkspace(
    orgId: number,
    workspaceId: number,
    options?: RunbookListByOrgAndWorkspaceOptionalParams,
  ): Promise<RunbookListByOrgAndWorkspaceResponse> {
    return this.sendOperationRequest(
      { orgId, workspaceId, options },
      runbookListByOrgAndWorkspaceOperationSpec,
    );
  }

  /**
   * Create Runbook in Org and Workspace
   * @param orgId
   * @param workspaceId
   * @param body
   * @param options The options parameters.
   */
  runbookCreate(
    orgId: number,
    workspaceId: number,
    body: RunbookCreateDto,
    options?: RunbookCreateOptionalParams,
  ): Promise<RunbookCreateResponse> {
    return this.sendOperationRequest(
      { orgId, workspaceId, body, options },
      runbookCreateOperationSpec,
    );
  }

  /**
   * Get Runbook by Id
   * @param orgId
   * @param runbookId
   * @param options The options parameters.
   */
  runbookGet(
    orgId: number,
    runbookId: string,
    options?: RunbookGetOptionalParams,
  ): Promise<RunbookGetResponse> {
    return this.sendOperationRequest(
      { orgId, runbookId, options },
      runbookGetOperationSpec,
    );
  }

  /**
   * Update Runbook
   * @param orgId
   * @param runbookId
   * @param body
   * @param options The options parameters.
   */
  runbookUpdate(
    orgId: number,
    runbookId: string,
    body: RunbookUpdateDto,
    options?: RunbookUpdateOptionalParams,
  ): Promise<RunbookUpdateResponse> {
    return this.sendOperationRequest(
      { orgId, runbookId, body, options },
      runbookUpdateOperationSpec,
    );
  }

  /**
   * Delete Runbook
   * @param orgId
   * @param runbookId
   * @param options The options parameters.
   */
  runbookDelete(
    orgId: number,
    runbookId: string,
    options?: RunbookDeleteOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { orgId, runbookId, options },
      runbookDeleteOperationSpec,
    );
  }

  /**
   * Get ancestor path for runbook.
   * @param orgId
   * @param runbookId
   * @param options The options parameters.
   */
  runbookPath(
    orgId: number,
    runbookId: string,
    options?: RunbookPathOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { orgId, runbookId, options },
      runbookPathOperationSpec,
    );
  }

  /**
   * Move Runbook
   * @param orgId
   * @param runbookId
   * @param body
   * @param options The options parameters.
   */
  runbookMove(
    orgId: number,
    runbookId: string,
    body: RunbookMoveDto,
    options?: RunbookMoveOptionalParams,
  ): Promise<RunbookMoveResponse> {
    return this.sendOperationRequest(
      { orgId, runbookId, body, options },
      runbookMoveOperationSpec,
    );
  }

  /**
   * Execute Runbook
   * @param orgId
   * @param runbookId
   * @param options The options parameters.
   */
  runbookExecute(
    orgId: number,
    runbookId: string,
    options?: RunbookExecuteOptionalParams,
  ): Promise<RunbookExecuteResponse> {
    return this.sendOperationRequest(
      { orgId, runbookId, options },
      runbookExecuteOperationSpec,
    );
  }

  /**
   * Get Runbook Execution
   * @param orgId
   * @param runbookId
   * @param options The options parameters.
   */
  runbookExecutionGet(
    orgId: number,
    runbookId: string,
    options?: RunbookExecutionGetOptionalParams,
  ): Promise<RunbookExecutionGetResponse> {
    return this.sendOperationRequest(
      { orgId, runbookId, options },
      runbookExecutionGetOperationSpec,
    );
  }

  /**
   * List Runbook Executions
   * @param orgId
   * @param runbookId
   * @param options The options parameters.
   */
  runbookExecutionList(
    orgId: number,
    runbookId: string,
    options?: RunbookExecutionListOptionalParams,
  ): Promise<RunbookExecutionListResponse> {
    return this.sendOperationRequest(
      { orgId, runbookId, options },
      runbookExecutionListOperationSpec,
    );
  }

  /**
   * Publish Runbook
   * @param orgId
   * @param runbookId
   * @param options The options parameters.
   */
  runbookPublish(
    orgId: number,
    runbookId: string,
    options?: RunbookPublishOptionalParams,
  ): Promise<RunbookPublishResponse> {
    return this.sendOperationRequest(
      { orgId, runbookId, options },
      runbookPublishOperationSpec,
    );
  }

  /**
   * Search Runbooks
   * @param orgId
   * @param search
   * @param options The options parameters.
   */
  runbookSearch(
    orgId: number,
    search: string,
    options?: RunbookSearchOptionalParams,
  ): Promise<RunbookSearchResponse> {
    return this.sendOperationRequest(
      { orgId, search, options },
      runbookSearchOperationSpec,
    );
  }

  /**
   * @param orgId
   * @param options The options parameters.
   */
  userList(
    orgId: number,
    options?: UserListOptionalParams,
  ): Promise<UserListResponse> {
    return this.sendOperationRequest({ orgId, options }, userListOperationSpec);
  }

  /** @param options The options parameters. */
  userOrgList(
    options?: UserOrgListOptionalParams,
  ): Promise<UserOrgListResponse> {
    return this.sendOperationRequest({ options }, userOrgListOperationSpec);
  }

  /**
   * List Workspaces by Org
   * @param org
   * @param options The options parameters.
   */
  workspaceList(
    org: number,
    options?: WorkspaceListOptionalParams,
  ): Promise<WorkspaceListResponse> {
    return this.sendOperationRequest(
      { org, options },
      workspaceListOperationSpec,
    );
  }

  /**
   * Get Workspace by Id
   * @param workspaceId
   * @param options The options parameters.
   */
  workspaceGet(
    workspaceId: number,
    options?: WorkspaceGetOptionalParams,
  ): Promise<WorkspaceGetResponse> {
    return this.sendOperationRequest(
      { workspaceId, options },
      workspaceGetOperationSpec,
    );
  }

  /**
   * Update Workspace by Id
   * @param workspaceId
   * @param options The options parameters.
   */
  workspaceUpdate(
    workspaceId: number,
    options?: WorkspaceUpdateOptionalParams,
  ): Promise<void> {
    return this.sendOperationRequest(
      { workspaceId, options },
      workspaceUpdateOperationSpec,
    );
  }

  /**
   * Create Workspace in Org
   * @param orgId
   * @param body
   * @param options The options parameters.
   */
  workspaceCreate(
    orgId: number,
    body: WorkspaceCreateDto,
    options?: WorkspaceCreateOptionalParams,
  ): Promise<WorkspaceCreateResponse> {
    return this.sendOperationRequest(
      { orgId, body, options },
      workspaceCreateOperationSpec,
    );
  }
}
// Operation Specifications
const serializer = coreClient.createSerializer(Mappers, /* isXml */ false);

const authGetOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/auth",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.AuthInfoDto,
    },
  },
  urlParameters: [Parameters.$host, Parameters.apiVersion],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const authLoginOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/auth/login",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.UserDto,
    },
    401: {
      bodyMapper: { type: { name: "Boolean" } },
    },
    default: {
      bodyMapper: Mappers.ErrorDto,
    },
  },
  requestBody: Parameters.body,
  urlParameters: [Parameters.$host, Parameters.apiVersion],
  headerParameters: [
    Parameters.accept,
    Parameters.xSwOrgId,
    Parameters.contentType,
  ],
  mediaType: "json",
  serializer,
};
const authLogoutOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/auth/logout",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: { type: { name: "Boolean" } },
    },
  },
  urlParameters: [Parameters.$host, Parameters.apiVersion],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const authLoginExternalOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/auth/login-external",
  httpMethod: "POST",
  responses: { 200: {} },
  queryParameters: [Parameters.provider, Parameters.returnUrl],
  urlParameters: [Parameters.$host, Parameters.apiVersion],
  headerParameters: [Parameters.xSwOrgId],
  serializer,
};
const authRemoteCallbackOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/auth/external-callback",
  httpMethod: "GET",
  responses: { 200: {} },
  queryParameters: [Parameters.returnUrl],
  urlParameters: [Parameters.$host, Parameters.apiVersion],
  headerParameters: [Parameters.xSwOrgId],
  serializer,
};
const authRegisterOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/auth/registration",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: { type: { name: "Boolean" } },
    },
    default: {
      bodyMapper: Mappers.ErrorDto,
    },
  },
  requestBody: Parameters.body1,
  urlParameters: [Parameters.$host, Parameters.apiVersion],
  headerParameters: [
    Parameters.accept,
    Parameters.xSwOrgId,
    Parameters.contentType,
  ],
  mediaType: "json",
  serializer,
};
const authEmailConfirmOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/auth/registration/confirmation",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: { type: { name: "Boolean" } },
    },
    default: {
      bodyMapper: Mappers.ErrorDto,
    },
  },
  queryParameters: [Parameters.userId, Parameters.code],
  urlParameters: [Parameters.$host, Parameters.apiVersion],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const authPasswordForgotOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/auth/password/forgot",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: { type: { name: "Boolean" } },
    },
  },
  queryParameters: [Parameters.email],
  urlParameters: [Parameters.$host, Parameters.apiVersion],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const authPasswordResetOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/auth/password/reset",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: { type: { name: "Boolean" } },
    },
  },
  queryParameters: [Parameters.code, Parameters.email, Parameters.password],
  urlParameters: [Parameters.$host, Parameters.apiVersion],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const docPublishOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/docs/{docId}/publication",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: { type: { name: "Boolean" } },
    },
  },
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.docId,
    Parameters.orgId,
  ],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const docMaterialGetOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/docs/{docId}/material",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.DocMaterialDto,
    },
  },
  queryParameters: [Parameters.version],
  urlParameters: [Parameters.$host, Parameters.apiVersion, Parameters.docId],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const docAttachmentCreateOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/docs/{docId}/attachments",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.FileInfoDto,
    },
  },
  requestBody: Parameters.data,
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.docId,
    Parameters.orgId,
  ],
  headerParameters: [
    Parameters.xSwOrgId,
    Parameters.accept1,
    Parameters.contentType1,
    Parameters.contentLength,
    Parameters.xSwFilename,
  ],
  mediaType: "binary",
  serializer,
};
const fileGetOperationSpec: coreClient.OperationSpec = {
  path: "/f/{orgId}/{fileId}",
  httpMethod: "GET",
  responses: { 200: {} },
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.fileId,
  ],
  headerParameters: [Parameters.xSwOrgId],
  serializer,
};
const fileCreateInOrgOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/files",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.FileInfoDto,
    },
  },
  requestBody: Parameters.data,
  urlParameters: [Parameters.$host, Parameters.apiVersion, Parameters.orgId],
  headerParameters: [
    Parameters.xSwOrgId,
    Parameters.accept1,
    Parameters.contentType1,
    Parameters.contentLength,
    Parameters.xSwFilename,
  ],
  mediaType: "binary",
  serializer,
};
const workflowGetOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/workflows/{workflowId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.WorkflowDto,
    },
  },
  queryParameters: [Parameters.revision],
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.workflowId,
  ],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const workflowUpdateOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/workflows/{workflowId}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.WorkflowDto,
    },
  },
  requestBody: Parameters.body2,
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.workflowId,
  ],
  headerParameters: [
    Parameters.accept,
    Parameters.xSwOrgId,
    Parameters.contentType,
  ],
  mediaType: "json",
  serializer,
};
const workflowCreateOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/workflows",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: { type: { name: "Boolean" } },
    },
  },
  requestBody: Parameters.body2,
  urlParameters: [Parameters.$host, Parameters.apiVersion, Parameters.orgId],
  headerParameters: [
    Parameters.accept,
    Parameters.xSwOrgId,
    Parameters.contentType,
  ],
  mediaType: "json",
  serializer,
};
const executionCreateOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/workflows/{id}/executions",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.ExecutionDto,
    },
  },
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.id,
  ],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const executionStopOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/executions/{executionId}/stop",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: { type: { name: "Boolean" } },
    },
  },
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.executionId,
  ],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const executionGetOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/executions/{id}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.ExecutionDto,
    },
  },
  urlParameters: [Parameters.$host, Parameters.apiVersion, Parameters.id],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const executionGetForUserOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/executions/user",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "ExecutionDto" } },
        },
      },
    },
  },
  urlParameters: [Parameters.$host, Parameters.apiVersion],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const stepActivityGetForUserOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/steps/user",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: { name: "Composite", className: "StepActivityDto" },
          },
        },
      },
    },
  },
  urlParameters: [Parameters.$host, Parameters.apiVersion],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const executionResponseBulkCreateOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/executions/{executionId}/responses-bulk",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: { type: { name: "Boolean" } },
    },
  },
  requestBody: Parameters.body3,
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.executionId,
  ],
  headerParameters: [
    Parameters.accept,
    Parameters.xSwOrgId,
    Parameters.contentType,
  ],
  mediaType: "json",
  serializer,
};
const executionReviewCreateOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/executions/{executionId}/review",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: { type: { name: "Boolean" } },
    },
  },
  requestBody: Parameters.body4,
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.executionId,
  ],
  headerParameters: [
    Parameters.accept,
    Parameters.xSwOrgId,
    Parameters.contentType,
  ],
  mediaType: "json",
  serializer,
};
const taskListOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/tasks",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "TaskDto" } },
        },
      },
    },
  },
  urlParameters: [Parameters.$host, Parameters.apiVersion, Parameters.orgId],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const executionAttachmentCreateOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/executions/{executionId}/attachments",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.ExecutionAttachmentDto,
    },
  },
  requestBody: Parameters.data,
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.executionId,
  ],
  headerParameters: [
    Parameters.xSwOrgId,
    Parameters.accept1,
    Parameters.contentType1,
    Parameters.contentLength,
    Parameters.xSwFilename,
  ],
  mediaType: "binary",
  serializer,
};
const executionAttachmentListOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/executions/{executionId}/attachments",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: { name: "Composite", className: "ExecutionAttachmentDto" },
          },
        },
      },
    },
  },
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.executionId,
  ],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const executionStepAttachmentCreateOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/executions/{executionId}/steps/{stepId}/attachments",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.ExecutionAttachmentDto,
    },
  },
  requestBody: Parameters.data,
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.executionId,
    Parameters.stepId,
  ],
  headerParameters: [
    Parameters.xSwOrgId,
    Parameters.accept1,
    Parameters.contentType1,
    Parameters.contentLength,
    Parameters.xSwFilename,
  ],
  mediaType: "binary",
  serializer,
};
const executionStepAttachmentListOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/executions/{executionId}/steps/{stepId}/attachments",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: { name: "Composite", className: "ExecutionAttachmentDto" },
          },
        },
      },
    },
  },
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.executionId,
    Parameters.stepId,
  ],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const workflowHistoryGetOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/workflows/{workflowId}/timings",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: { name: "Composite", className: "ExecutionReportDto" },
          },
        },
      },
    },
  },
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.workflowId,
  ],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const orgGetByIdOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.OrgDto,
    },
  },
  urlParameters: [Parameters.$host, Parameters.apiVersion, Parameters.orgId],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const orgGetStatusOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/status",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.OrgStatusDto,
    },
  },
  urlParameters: [Parameters.$host, Parameters.apiVersion, Parameters.orgId],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const runbookListByOrgOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/runbooks",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "RunbookDto" } },
        },
      },
    },
  },
  urlParameters: [Parameters.$host, Parameters.apiVersion, Parameters.orgId],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const runbookListByOrgAndWorkspaceOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/{workspaceId}/runbooks",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "RunbookDto" } },
        },
      },
    },
  },
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.workspaceId,
  ],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const runbookCreateOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/{workspaceId}/runbooks",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: Mappers.RunbookDto,
    },
  },
  requestBody: Parameters.body5,
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.workspaceId,
  ],
  headerParameters: [
    Parameters.accept,
    Parameters.xSwOrgId,
    Parameters.contentType,
  ],
  mediaType: "json",
  serializer,
};
const runbookGetOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/runbooks/{runbookId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.RunbookDto,
    },
  },
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.runbookId,
  ],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const runbookUpdateOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/runbooks/{runbookId}",
  httpMethod: "PUT",
  responses: {
    200: {
      bodyMapper: Mappers.RunbookDto,
    },
  },
  requestBody: Parameters.body6,
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.runbookId,
  ],
  headerParameters: [
    Parameters.accept,
    Parameters.xSwOrgId,
    Parameters.contentType,
  ],
  mediaType: "json",
  serializer,
};
const runbookDeleteOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/runbooks/{runbookId}",
  httpMethod: "DELETE",
  responses: { 200: {} },
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.runbookId,
  ],
  headerParameters: [Parameters.xSwOrgId],
  serializer,
};
const runbookPathOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/runbooks/{runbookId}/path",
  httpMethod: "GET",
  responses: { 200: {} },
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.runbookId,
  ],
  headerParameters: [Parameters.xSwOrgId],
  serializer,
};
const runbookMoveOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/runbooks/{runbookId}/move",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.RunbookDto,
    },
  },
  requestBody: Parameters.body7,
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.runbookId,
  ],
  headerParameters: [
    Parameters.accept,
    Parameters.xSwOrgId,
    Parameters.contentType,
  ],
  mediaType: "json",
  serializer,
};
const runbookExecuteOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/runbooks/{runbookId}/execute",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: Mappers.RunbookExecutionDto,
    },
  },
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.runbookId,
  ],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const runbookExecutionGetOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/runbook-executions/{runbookId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.RunbookExecutionDto,
    },
  },
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.runbookId,
  ],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const runbookExecutionListOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/runbooks/{runbookId}/executions",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: {
            type: { name: "Composite", className: "RunbookExecutionDto" },
          },
        },
      },
    },
  },
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.runbookId,
  ],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const runbookPublishOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/runbooks/{runbookId}/publish",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: { type: { name: "Boolean" } },
    },
  },
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.orgId,
    Parameters.runbookId,
  ],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const runbookSearchOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/runbooks/search",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "RunbookFtsDto" } },
        },
      },
    },
  },
  queryParameters: [Parameters.search],
  urlParameters: [Parameters.$host, Parameters.apiVersion, Parameters.orgId],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const userListOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/users",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "UserDto" } },
        },
      },
    },
  },
  queryParameters: [Parameters.query],
  urlParameters: [Parameters.$host, Parameters.apiVersion, Parameters.orgId],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const userOrgListOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/user/orgs",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "OrgDto" } },
        },
      },
    },
  },
  queryParameters: [Parameters.query, Parameters.orgId1],
  urlParameters: [Parameters.$host, Parameters.apiVersion],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const workspaceListOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{org}/workspaces",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: {
        type: {
          name: "Sequence",
          element: { type: { name: "Composite", className: "WorkspaceDto" } },
        },
      },
    },
  },
  urlParameters: [Parameters.$host, Parameters.apiVersion, Parameters.org],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const workspaceGetOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/workspaces/{workspaceId}",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper: Mappers.WorkspaceDto,
    },
  },
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.workspaceId,
  ],
  headerParameters: [Parameters.accept, Parameters.xSwOrgId],
  serializer,
};
const workspaceUpdateOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/workspaces/{workspaceId}",
  httpMethod: "PUT",
  responses: { 200: {} },
  requestBody: Parameters.body8,
  urlParameters: [
    Parameters.$host,
    Parameters.apiVersion,
    Parameters.workspaceId,
  ],
  headerParameters: [Parameters.xSwOrgId, Parameters.contentType],
  mediaType: "json",
  serializer,
};
const workspaceCreateOperationSpec: coreClient.OperationSpec = {
  path: "/api/v{apiVersion}/orgs/{orgId}/workspaces",
  httpMethod: "POST",
  responses: {
    201: {
      bodyMapper: Mappers.WorkspaceDto,
    },
  },
  requestBody: Parameters.body9,
  urlParameters: [Parameters.$host, Parameters.apiVersion, Parameters.orgId],
  headerParameters: [
    Parameters.accept,
    Parameters.xSwOrgId,
    Parameters.contentType,
  ],
  mediaType: "json",
  serializer,
};
