import { StepWise } from "../client/stepWise.js"
import { AppStore, IAppRouter } from "./AppStore.js"
import { ExecutionAttachmentStore } from "./AttachmentStore.js"
import { AuthStore } from "./AuthStore.js"
import { DocStore } from "./DocStore.js"
import { OrgCtx } from "./OrgCtx.js"
import { OrgStore } from "./OrgStore.js"
import { RunbookStore } from "./Runbook.js"
import { TaskStore } from "./Tasks.js"
import { ThemeStore } from "./Theme.js"
import { UploadStore } from "./Upload.js"
import { UserStore } from "./UserStore.js"
import { UtilityBar } from "./UtilityBar.js"
import { WorkflowStore } from "./Workflow.js"
import { WorkspaceCtx } from "./WorkspaceCtx.js"


type TypeMap<T extends Newable<T>> = Map<T, InstanceType<T>>



export class RootStore {
    typeMap: TypeMap<any> = new Map()

    app: AppStore
    orgCtx: OrgCtx
    theme: ThemeStore
    docs: DocStore
    runbooks: RunbookStore
    workflows: WorkflowStore
    executionAttachments: ExecutionAttachmentStore
    tasks: TaskStore
    uploads: UploadStore
    workspaceCtx: WorkspaceCtx
    auth: AuthStore
    orgs: OrgStore
    utilityBar: UtilityBar
    users: UserStore

    constructor(readonly sw: StepWise, router: IAppRouter) {
        this.theme = this.register(new ThemeStore)
        this.docs = this.register(new DocStore(this.sw))
        this.auth = this.register(new AuthStore(this.sw))
        this.executionAttachments = this.register(new ExecutionAttachmentStore(this.sw))
        this.workflows = this.register(new WorkflowStore(this.sw, this.executionAttachments))
        this.tasks = this.register(new TaskStore(this.sw))
        this.runbooks = this.register(new RunbookStore(this.sw, this.workflows, this.docs))
        this.uploads = this.register(new UploadStore())
        this.orgs = this.register(new OrgStore(this.sw))
        this.orgCtx = this.register(new OrgCtx(this.auth, this.orgs))
        this.workspaceCtx = this.register(new WorkspaceCtx(this.orgCtx, this.docs, this.runbooks, this.workflows, router))
        this.utilityBar = this.register(new UtilityBar)
        this.users = this.register(new UserStore(this.sw))
        this.app = this.register(new AppStore(this.sw, router, this.orgCtx))
    }

    init() {
        this.auth.init()
    }

    getStoreOf<T>(type: Newable<T>): T | null {
        const store = this.typeMap.get(type)
        return store as T
    }

    private register<T extends object>(store: T) {
        this.typeMap.set(store.constructor, store)
        return store
    }
}