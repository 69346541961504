import { observer } from "mobx-react-lite"
import { useCallback, useState } from 'react'


import {
    RJSFSchema,
    UiSchema} from '@rjsf/utils'
import { IChangeEvent } from '@rjsf/core'
import validator from '@rjsf/validator-ajv8'
import { SchemaForm } from "@/components/SchemaForm/SchemaForm"
import { useAuthStore } from "@/hooks/useStores"
import { ErrorDto } from "@/client"

const schema: RJSFSchema = {
    type: 'object',
    required: [
        "password"
    ],
    properties: {
        password: {
            type: "string",
            title: "Password"
        },
    }
}

const uiSchema: UiSchema = {
    password: {
        "ui:widget": "password"
    },
    "ui:submitButtonOptions": {
        submitText: "Reset Password"
    }
}

export interface ResetPasswordFormProps {
    onLogin: () => void
    email: string
    code: string
}

export const ResetPasswordForm = observer(({onLogin, email, code}: ResetPasswordFormProps) => {
    const [formData, setFormData] = useState(null)
    const [swError, setSwError] = useState<ErrorDto | undefined>(undefined)
    const [extraErrors, setExtraErrors] = useState({})
    const auth = useAuthStore()

    const _onSubmit = useCallback(async (e: IChangeEvent<{password: string}>) => {
        if (!e.formData) return

        const {password} = e.formData
        try {
            const user = await auth.passwordReset(email, code, password)
            onLogin()
        } catch (e) {
            const ex = e as ErrorDto
            setSwError(ex)
        }
    }, [])

    return (
        <SchemaForm
            onSubmit={_onSubmit}
            formData={formData}
            onChange={e => setFormData(e.formData)}
            schema={schema}
            validator={validator}
            uiSchema={uiSchema}
            swError={swError}/>
    )
})