import { observer } from "mobx-react-lite"
import { UserRunbookActivityTable } from "@/screens/shell/activityContent/ActivityContent"


export const Activity = observer(() => {
    return (
        <div className="p-2 w-[1000px]">
            <div className="text-4xl font-bold">Steps Pending Input</div>
            <div className="bg-white">
                <UserRunbookActivityTable/>
            </div>
        </div>
    )
})