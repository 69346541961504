import {
    TemplatesType,
    BaseInputTemplateProps,
    FieldTemplateProps,
    SubmitButtonProps,
    getSubmitButtonOptions,
    IconButtonProps,
    WidgetProps,
    getUiOptions,
    getTemplate,
    WrapIfAdditionalTemplateProps,
    ADDITIONAL_PROPERTY_FLAG,
    ArrayFieldTemplateProps,
    labelValue,
    ArrayFieldTemplateItemType,
    RegistryFieldsType,
    FieldProps} from '@rjsf/utils'
import { FormProps, ThemeProps, withTheme } from '@rjsf/core'

import { SwTextField as TextField } from '../TextField/TextField'
import { ErrorDto } from '@/client'
import { JSONPath } from 'jsonpath-plus'
import { SwButton } from '../Button/Button'
import { SwCheckbox } from '../Checkbox/Checkbox'

import "./SchemaForm.css"
import { SwSelectItem, SwSelect } from '../Select/Select'
import { SwTagField } from '../TagField/TagField'
import { useListData } from 'react-stately'
import { Tag } from 'react-aria-components'
import { FocusEvent } from 'react'
import { SwIcon } from '../Icon/Icon'
import { IconTrash } from '@tabler/icons-react'

const templates: Partial<TemplatesType> = {
    BaseInputTemplate: (props: BaseInputTemplateProps) => {
        console.log('Base input', props)
        const _onChange = (value: string) => props.onChange(value === '' ? props.options.emptyValue : value)
        return (
      <TextField
          slot="rjsf"
          autoFocus={props.autofocus}
          id={props.id}
          label={labelValue(props.label, props.hideLabel, undefined)}
          name={props.id}
          placeholder={props.placeholder}
          type={props.type}
          value={props.value ?? ''}
          defaultValue={props.value}
          onChange={_onChange}
          isReadOnly={props.readonly}
          errorMessage={props.rawErrors?.join(', ')}
      />
        )
    },
    FieldTemplate: (props: FieldTemplateProps) => {
        const {registry, uiSchema} = props
        const uiOptions = getUiOptions(uiSchema)
        // console.log("Field", props)

        const WrapIfAdditionalTemplate = getTemplate(
            'WrapIfAdditionalTemplate',
            registry,
            uiOptions
        )

        return (
            <WrapIfAdditionalTemplate {...props}>
                {props.children}
            </WrapIfAdditionalTemplate>
        )
    },
    // ArrayFieldTemplate: () => {
    //     return <>Foo</>
    // },
    ArrayFieldItemTemplate: (props: ArrayFieldTemplateItemType) => {
        const { readonly, hasToolbar, hasRemove, index, onDropIndexClick } = props

        const displayToolbar = !readonly && hasToolbar

        const drop = onDropIndexClick(index)

        return <div className='flex items-center gap-2'>
            {props.children}
            {displayToolbar && (
                <div className='flex items-center'>
                    {hasRemove && (
                        <SwButton
                            onPress={(e) => {
                                const event = e as any
                                event.preventDefault = () => {}
                                drop()
                            }}
                        >
                            <SwIcon icon={IconTrash} />
                        </SwButton>
                    )}
                </div>
            )}
        </div>
    },
    WrapIfAdditionalTemplate: (props: WrapIfAdditionalTemplateProps) => {
        const { id, label, disabled, onKeyChange, onDropPropertyClick, schema, children, uiSchema = {}, registry, classNames, style, readonly } = props
        const additional = ADDITIONAL_PROPERTY_FLAG in schema

        if (!additional) return <>{children}</>


        const placeholder = uiSchema["ui:placeholder"] ?? ""

        // @ts-ignore
        const handleBlur = ({ target }: FocusEvent<Element, Element>) => onKeyChange(target.value)

        return (
            <div>
                <div className='flex gap-2'>
                    <TextField
                        slot="rjsf"
                        id={props.id}
                        name={props.id}
                        placeholder={placeholder}
                        defaultValue={label}
                        type="text"
                        onBlur={!readonly ? handleBlur : undefined}
                        isReadOnly={readonly}
                    />
                    <SwButton isDisabled={disabled || readonly} onPress={(e) => {
                        const event = e as any
                        event.preventDefault = () => {}
                        // @ts-ignore
                        onDropPropertyClick(label)(e)

                    }}><SwIcon icon={IconTrash}/></SwButton>
                </div>
                <div className="pl-4 pt-2">{children}</div>
            </div>
        )
    },
    // @ts-ignore
    ButtonTemplates: {
        SubmitButton: (props: SubmitButtonProps) => {
            const { uiSchema } = props
            const { norender, submitText } = getSubmitButtonOptions(uiSchema)
            if (norender) {
                return null
            }
            return (
            //<Button type="submit">{submitText || 'Submit'}</Button>
            <SwButton type="submit" variant="primary" size="lg" slot="rjsf">{submitText || 'Submit'}</SwButton>
            )
        },
        AddButton: (props: IconButtonProps) => {
            return (
            <SwButton isDisabled={props.disabled} slot="rjsf" onPress={(e) => {
                const event = e as any
                event.preventDefault = () => {}
                props.onClick!(e as any)
            }}
            >
                Add
            </SwButton>
            )
        },
        RemoveButton: (props: IconButtonProps) => {
            return (
            <SwButton isDisabled={props.disabled} slot="rjsf" onPress={(e) => {
                const event = e as any
                event.preventDefault = () => {}
                props.onClick!(e as any)
            }}>
                Remove
            </SwButton>
            )
        },
    },
}

const fields: RegistryFieldsType = {
    ArraySchemaField: (props: FieldProps) => {
        console.log("Array schema field props", props)
        const { index, registry, schema } = props
        const { SchemaField } = registry.fields

        const name = schema.type == "string" ? "" : props.name

        return <SchemaField {...props} label={false} name={name} />
    }
}

const theme: ThemeProps = {
    templates: templates,
    fields,
    widgets: {
        // TagFieldWidget: (props: WidgetProps) => {

        //     const items = useListData({initialItems: [{id: "foo", name: "foo"}]})

        //     console.log('value', props.value)

        //     return <SwTagField onChange={(data) => {console.log('data', data); props.onChange(data) }} items={items.items} >
        //         {item => <Tag id={item.id}><div>{item.name}</div></Tag>}
        //         {item => <Item id={item.id}>{item.name}</Item>}
        //     </SwTagField>
        // },
        // UserTagFieldWidget: (props: WidgetProps) => {

        //     const items = useListData({initialItems: [{id: "foo", name: "foo"}]})

        //     console.log('value', props.value)

        //     return <SwTagField onChange={(data) => {console.log('data', data); props.onChange(data) }} items={items.items} >
        //         {item => <Tag id={item.id}><div>{item.name}</div></Tag>}
        //         {item => <Item id={item.id}>{item.name}</Item>}
        //     </SwTagField>
        // },
        CheckboxWidget: (props) => {
            const { label, value, onChange, readonly } = props
            console.log("Checkbox", props)

            const _onChange = (e: boolean) => {
                console.log("Checkbox changed", e)
                onChange(e)
            }

            return (
                <SwCheckbox isReadOnly={readonly} isSelected={!!value} onChange={_onChange}>
                    {label}
                </SwCheckbox>
            )
        },
        SelectWidget: (props) => {
            const { hideLabel, label, options, onChange, id } = props
            console.log("Select ID", id)
            const { enumOptions, enumDisabled, emptyValue: optEmptyVal } = options
            console.log("select props", props)
            return (
                <SwSelect
                    key={id}
                    label={!hideLabel ? label : undefined}
                    isDisabled={props.readonly}
                    selectedKey={props.value}
                    onSelectionChange={onChange}
                >
                    {Array.isArray(enumOptions) &&
                    enumOptions.map(({ value, label }, i: number) => {
                        const disabled: boolean = Array.isArray(enumDisabled) && enumDisabled.indexOf(value) !== -1
                        return (
                        <SwSelectItem key={value} id={value}>
                        {label}
                        </SwSelectItem>
                        )
                    })}
                </SwSelect>
            )
        },
    }
}

export interface SchemaFormProps extends FormProps {
    swError?: ErrorDto
}

export const ThemedForm = withTheme(theme)

export const SchemaForm = ({extraErrors, ...props}: SchemaFormProps) => {
    extraErrors = props.swError ? swErrorToExtraErrors(props.swError) : extraErrors

    return <ThemedForm {...props} extraErrors={extraErrors} className="sw-schemaform" />
}

export function swErrorToExtraErrors(error: ErrorDto) {
    const errors = {
        "__errors": [error.message]
    } as any
    for (const err of error.errors ?? []) {
        const path = JSONPath.toPathArray(err.property)
        let pointer = errors
        for (const part of path) {
            pointer[part] ??= {}
            pointer = pointer[part]
        }
        pointer["__errors"] ??= []
        pointer["__errors"].push(err.message)
    }
    return errors
}