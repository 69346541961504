import { action, observable } from "mobx"
import { AuthLoginEvt, AuthStore } from "./AuthStore"
import { Org, OrgStore } from "./OrgStore"

export class OrgCtx {
    @observable accessor orgId: Nullable<number> = null
    @observable accessor org: Nullable<Org> = null

    name?: string

    private readonly _orgs: OrgStore

    constructor(
        auth: AuthStore,
        orgs: OrgStore,
    ) {
        this._orgs = orgs
        if (auth.user)
            this.orgId = auth.user.orgId

        auth.onDidLogin(this.handleAuthLogin.bind(this))
    }

    @action handleAuthLogin(evt: AuthLoginEvt) {
        this.orgId = evt.detail.orgId
        this._orgs.load(this.orgId).then(org => this.org = org)
        this._orgs.loadUserOrgs()
    }
}
