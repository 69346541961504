import { observer } from "mobx-react-lite"

import { SwActionButton } from "@/components/ActionButton"
import { SwButton } from "@/components/Button"
import { SwTab, SwTabList, SwTabs } from "@/components/Tabs/Tabs"
import { Icon } from "@/components/Icon"
import { SwText } from "@/components/Text/Text"
import { useWorkspaceCtx } from "@/contexts"
import { WorkspaceCtx, WorkspaceMode } from "@/stores/WorkspaceCtx"
import { IconBook, IconChartBar, IconEdit, IconListCheck, IconListDetails, IconLogin, IconPlayerPlay } from "@tabler/icons-react"
import { SwIcon } from "@/components/Icon/Icon"
import { TriGroup } from "@/components/TriGroup/TriGroup"
import { DialogTrigger } from "react-aria-components"
import { RunbookRenameModal } from "@/components/widgets/RunbookList/RunbookItemMenu"
import { useState } from "react"


export const ContextBar = observer(() => {
    const workspaceCtx = useWorkspaceCtx()

    const shouldShowViewTabs = !!workspaceCtx.activeRunbook
    const shouldShowReadControls = workspaceCtx.mode == WorkspaceMode.Read && workspaceCtx.activeRunbook
    const shouldShowWriteControls = workspaceCtx.mode == WorkspaceMode.Write && workspaceCtx.activeRunbook
    const shouldShowSummaryTab = workspaceCtx.mode == WorkspaceMode.Execute

    return (
        <div
            className={`px-4 h-12 border-b border-gray-300 grow-0 shrink-0 dark:border-zinc-700 dark:bg-zinc-900  dark:text-white ${workspaceCtx.mode == WorkspaceMode.Write ? 'bg-neutral-800 invert-tabs invert-buttons' : 'bg-neutral-100'}`}>
            <TriGroup>
                <div>
                    {shouldShowReadControls ? leftReadControls(workspaceCtx) : <></>}
                    {shouldShowWriteControls ? <LeftWriteControls/> : <></>}
                </div>
                <div className="flex justify-around items-center gap-20 h-full min-w-fit">
                    {shouldShowViewTabs ?
                        <SwTabs
                            size="sm"
                            disabledKeys={['output']}
                            onSelectionChange={(e: any) => workspaceCtx.setRunbookView(e)}
                        >
                            <SwTabList aria-label="Runbook View" >
                                <SwTab size="sm" id="Runbook">
                                    <Icon icon={IconBook}/>
                                    <div>Runbook</div>
                                </SwTab>
                                <SwTab size="sm" id="Workflow">
                                    <Icon icon={IconListCheck}/>
                                    <div>Workflow</div>
                                </SwTab>
                                <SwTab size="sm" id="Insights">
                                    <Icon icon={IconChartBar}/>
                                    <div>Insights</div>
                                </SwTab>
                                {shouldShowSummaryTab &&
                                <SwTab size="sm" id="Summary">
                                    <Icon icon={IconListDetails}/>
                                    <div>Summary</div>
                                </SwTab>}
                            </SwTabList>
                        </SwTabs> :
                    <></>}
                </div>
                <div className="flex items-center justify-end h-full">
                    {shouldShowReadControls ? rightReadControls(workspaceCtx) : <></>}
                    {shouldShowWriteControls ? rightWriteControls(workspaceCtx) : <></>}
                </div>
            </TriGroup>
          </div>
    )
})
ContextBar.displayName = 'ContextBar'

const LeftWriteControls = observer(() => {
    const workspace = useWorkspaceCtx()

    const title = workspace.activeRunbook?.name ?? "--"

    let path = (workspace.activeRunbook?.ancestors ?? []).map(r => r.name).join(" / ")

    return (
        <div className="flex gap-2 items-center min-h-0">
            <SwButton isQuiet style="outline" onPress={() => workspace.setMode(WorkspaceMode.Read)}>
                <SwIcon icon={IconLogin}/>
                <SwText className='mr-3 only:ml-3'>Exit</SwText>
            </SwButton>
            <div className="ml-8">
            {path && <SwText className="text-sm text-white font-sm">{path} / </SwText>}
            <SwText className="text-2xl text-white font-sm">{title}</SwText>
            </div>
            <DialogTrigger>
            <SwButton variant="icon"><SwIcon icon={IconEdit} /></SwButton>
            <RunbookRenameModal runbook={workspace.activeRunbook!}/>
            </DialogTrigger>
        </div>
    )
})

function leftReadControls(workspace: WorkspaceCtx) {
    return (
        <div className="flex gap-2">
            <SwButton isQuiet onPress={() => workspace.setMode(WorkspaceMode.Write)}>
                <Icon icon={IconEdit}/>
                <SwText>Edit</SwText>
            </SwButton>
        </div>
    )
}

function rightWriteControls(workspace: WorkspaceCtx) {

    return (
        <div className="flex gap-2">
            <SwButton variant="cta" onPress={() => workspace.publishActiveRunbook()}>
                <SwIcon icon={IconLogin}/>
                <SwText className='mr-3 only:ml-3'>Publish</SwText>
            </SwButton>
        </div>
    )
}

function rightReadControls(workspace: WorkspaceCtx) {
    const workflow = workspace.activeWorkflow

    return (
    <div className="flex gap-2">
        <SwButton variant="primary" onPress={() => workflow && workspace.executeRunbook(workspace.activeRunbook!)}>
            <SwIcon icon={IconPlayerPlay}/>
            <SwText>Run</SwText>
        </SwButton>
    </div>
    )
}
