import { createContext, memo, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import {
    GridList,
    GridListProps as AriaGridListProps,
    ListBoxItem as AriaItem,
    ListBoxItemRenderProps,
    Selection
} from 'react-aria-components'

import './TreeGridList.css'
import { useHover } from 'react-aria'


export interface GridListProps<T> extends AriaGridListProps<T> {}
const Item = memo(AriaItem)

export  interface ListItem<T> {
    name: string
    id: string | number
    children?: ListItem<T>[]
}

export interface TreeGridListProps<T extends ListItem<T>> extends Omit<GridListProps<T>, "children"> {
    initialList: Array<ListItem<T>>
    nested?: boolean
    isExpanded?: boolean
    children: (item: T, renderProps: ListBoxItemRenderProps) => ReactNode
}

interface TreeCtx {
    selectedKeys: Selection
}

const TreeContext = createContext<TreeCtx | null>(null)

export const TreeGridList = observer((props: TreeGridListProps<any>) => {
    const treeCtx = useLocalObservable(() => ({selectedKeys: new Set(props.selectedKeys)})) as TreeCtx

    useEffect(() => {
        runInAction(() => treeCtx.selectedKeys = new Set(props.selectedKeys))
    }, [props.selectedKeys])

    const onSelectionChange = useCallback((keys: Selection) => {
        if (!props.selectedKeys)
            runInAction(() => treeCtx.selectedKeys = keys)
        props.onSelectionChange?.(keys)
    }, [props.onSelectionChange])

    return <TreeContext.Provider value={treeCtx}>
        <DndGridList {...props} onSelectionChange={onSelectionChange} />
    </TreeContext.Provider>
})

const DndGridList = observer((props: TreeGridListProps<any>) => {
    const { isExpanded = false, nested = false, children } = props
    const [expanded, setExpanded] = useState(isExpanded)

    const treeCtx = useContext(TreeContext)!

    return <GridList
        {...props}
        className="sw-TreeGridList"
        items={props.initialList}
        selectedKeys={treeCtx.selectedKeys}
    >
        {(item) =>
            <Item textValue={item.name}>
                { (itemProps) => <ItemInner item={item} props={props} renderProps={itemProps} children={children} /> }
            </Item>}
        </GridList>
})

interface ItemInnerProps {
    props: TreeGridListProps<any>
    item: any
    children: (item: any, renderProps: ListBoxItemRenderProps) => ReactNode
    renderProps: ListBoxItemRenderProps
}

const ItemInner = memo(({item, props, renderProps, children}: ItemInnerProps) => {

    const {isHovered, hoverProps} = useHover({})

    return <>
        <div {...hoverProps} className='sw-gridlist__ItemControls flex' data-selected={renderProps.isSelected} data-pressed={renderProps.isPressed}>
            {children(item, {...renderProps, isHovered})}
        </div>
        {item.children?.length > 0 && <div className="pl-6">
            <DndGridList
                {...props}
                initialList={item.children}
                aria-label={item.name}
                nested
            >
                {children}
            </DndGridList>
        </div>}
    </>
})