import React, { useEffect } from 'react'

import { observer } from 'mobx-react-lite'

import {Header} from './shell/Header'
import { UtilityBar } from './shell/UtilityBar.js'
import { MainContent } from './shell/MainContent'
import { useWorkspaceCtx } from '@/contexts'
import { WorkspaceMode } from '@/stores/WorkspaceCtx'


const _Shell: React.FunctionComponent = () => {
    const workspaceCtx = useWorkspaceCtx()

    if (workspaceCtx.mode == WorkspaceMode.Write) return (
        <div style={{height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
            <MainContent key="MainContent" />
            <UtilityBar key="UtilityBar" />
        </div>
    )

    else return (
        <div style={{height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
            <Header/>
            <MainContent key="MainContent"/>
            <UtilityBar key="UtilityBar"/>
        </div>
    )
}
export const Shell = observer(_Shell)