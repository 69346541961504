import { StepWise } from "@/client"
import { action, observable } from "mobx"
import { createUseStore } from "./util"

import { Router } from '@remix-run/router'
import { OrgCtx } from "./OrgCtx"

type Screen = 'runbook' | 'activity'

export interface IAppRouter extends Pick<Router, 'navigate'> {}

export class AppStore {
    @observable accessor screen: Screen = 'runbook'

    constructor(readonly sw: StepWise, readonly router: IAppRouter, readonly org: OrgCtx) {}

    @action setScreen(screen: Screen) {
        this.screen = screen
    }

    @action navigateToActivity() {
        this.setScreen('activity')
        this.router.navigate({
            pathname: `/${this.org.orgId}/1/activity`,
        })
    }

    @action navigateToRunbooks() {
        this.setScreen('runbook')
        this.router.navigate({
            pathname: `/${this.org.orgId}/1`,
        })
    }
}
export const [AppCtx, useAppStore] = createUseStore(AppStore)