import { useContext, useRef, useState } from "react"
import { StepWiseEditorContext } from "../context"
import { observer } from "mobx-react-lite"
import { IconChevronDown, IconTable } from "@tabler/icons-react"
import { SwTooltip, SwTooltipTrigger } from "@/components/Tooltip/Tooltip"
import { SwPopover } from "@/components/Popover/Popover"
import { SwToggleButton } from "@/components"
import { Dialog } from "@/components/Dialog/Dialog"
import { AddColumnAfter, AddRowAfter, DeleteColumn, DeleteRow, HeaderColumn, HeaderRow, MergeCells, SplitCell, Table } from "."


export const TableMenu = observer(() => {
    const editor = useContext(StepWiseEditorContext)

    const [isOpen, setIsOpen] = useState(false)
    const ref = useRef<HTMLButtonElement>(null)

    // const isDisabled = !Array.from(editor?.proseCache.obj.selectedNodeTypes.keys() || []).every(n => n == editor?.tipTap.schema.nodes['text'])
    // const isActive = editor?.proseCache.obj.activeMarkTypes.has(editor?.tipTap.schema.marks['link'])


    const onOpenChange = (value: boolean) => {
        setIsOpen(value)
    }

    return (<>
        <SwTooltipTrigger>
            <SwToggleButton isSelected={isOpen} ref={ref} isQuiet onPress={() => onOpenChange(!isOpen)}>
                <IconTable size={20} className="ml-1" />
                <IconChevronDown size={16} />
            </SwToggleButton>
            <SwTooltip>
                "Table Menu"
            </SwTooltip>
        </SwTooltipTrigger>
        <SwPopover triggerRef={ref} isOpen={isOpen} onOpenChange={onOpenChange} >
            <TablePopMenu />
        </SwPopover>
    </>)
})

const TablePopMenu = () => {
    return (
        <Dialog className="p-2">
            <div className="flex flex-col">
                <Table tooltipPlacement="right"/>
                <AddRowAfter tooltipPlacement="right"/>
                <AddColumnAfter tooltipPlacement="right"/>
                <DeleteRow tooltipPlacement="right"/>
                <DeleteColumn tooltipPlacement="right"/>
                <HeaderRow tooltipPlacement="right"/>
                <HeaderColumn tooltipPlacement="right"/>
                <MergeCells tooltipPlacement="right"/>
                <SplitCell tooltipPlacement="right"/>
            </div>
        </Dialog>
    )
}