import clsx from "clsx"
import { Ref, forwardRef, useEffect, useMemo, useRef, useState } from "react"
import { useMove } from "react-aria"
import { DOMAttributes } from '@react-types/shared'
import { Axis } from "../types"
import { SizeAxis } from "@react-types/overlays"


export interface PanelProps extends React.PropsWithChildren<{}> {
    id: string
    className?: string
    initX?: number
    initY?: number
    axis: Axis
    sizeAxis?: SizeAxis
    offset?: 'inside' | 'outside'
}

interface userPrefs {
    xSize?: number
    ySize?: number
}

export function SwPanel({id, children, ...props}: PanelProps) {
    const userPrefsKey = `sw/ui/panel/prefs${id}`
    const userPrefs = useMemo(() => JSON.parse(localStorage.getItem(userPrefsKey) ?? "{}"), []) as userPrefs
    const {
        initX = 50,
        initY = 50,
        axis,
        offset = 'inside'
    } = props

    const pointerPos = useRef<{x: number, y: number}>({x: 0, y: 0})
    const ref = useRef<HTMLDivElement>(null)
    const handleRef = useRef<HTMLDivElement>(null)
    const xRef = useRef(userPrefs.xSize ?? initX)
    const [x, setX] = useState(userPrefs.xSize ?? initX)

    const [isMoving, setMoving] = useState(false)
    const {moveProps} = useMove({
        onMove(e) {
            const rect = handleRef.current?.getBoundingClientRect()
            const xDist = pointerPos.current.x - Math.abs(rect?.left! + (rect?.width! / 2))
            if (axis == "left") xRef.current -= xDist
            if (axis == "right") xRef.current += xDist
            localStorage.setItem(userPrefsKey, JSON.stringify(userPrefs))
            ref.current!.style.flexBasis = `${xRef.current}px`
            setX(xRef.current)
        },
        onMoveStart() {
            setMoving(true)
        },
        onMoveEnd() {
            setMoving(false)
        }
    })

    useEffect(() => {
        const pointerMove = (e: PointerEvent) => {
            pointerPos.current = {x: e.clientX, y: e.clientY}
        }
        addEventListener("pointermove", pointerMove)
        return () => removeEventListener("pointermove", pointerMove)
    }, [])

    useEffect(() => {
        const clientWidth = ref.current?.clientWidth
        if (clientWidth && Math.abs(clientWidth - xRef.current) > 5 ) xRef.current = clientWidth
        userPrefs.xSize = xRef.current
    })

    return (
    <div ref={ref} className={clsx("relative", props.className)} style={{flexBasis: `${x}px`}}>
        <ResizeHandle ref={handleRef} moveProps={moveProps} isMoving={isMoving} axis={axis} offset={offset} />
        {children}
    </div>)
}

interface ResizeHandleProps {
    moveProps: DOMAttributes
    axis: Axis
    offset: 'inside' | 'outside'
    isMoving?: boolean
}

const PlacementStyles = {
    left: {
        inside: "top-0 bottom-0 left-0 w-1.5 h-full",
        outside: "top-0 bottom-0 -left-1 w-1.5 h-full"
    },
    right: {
        inside: "top-0 bottom-0 right-0 w-1.5 h-full",
        outside: "top-0 bottom-0 -right-1 w-1.5 h-full"
    },
    top: {
        inside: "top-0 left-0 right-0 h-1.5 w-full",
        outside: "-top-1 left-0 right-0 h-1.5 w-full",
    },
    bottom: {
        inside: "bottom-0 left-0 right-0 h-1.5 w-full",
        outside: "bottom-0 left-0 right-0 h-1.5 w-full"
    }
}

const ResizeHandle = forwardRef(({moveProps, axis, offset, isMoving = false}: ResizeHandleProps, ref: Ref<HTMLDivElement>) => (
    <div ref={ref} {...moveProps} className={clsx(
        PlacementStyles[axis][offset],
        isMoving && "bg-blue-400 cursor-col-resize",
        "absolute bg-transparent hover:bg-blue-400 hover:cursor-col-resize",
        "transition transition-colors hover:delay-100"
    )}/>
))