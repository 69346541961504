import { CodeMirror } from "@/components/CodeMirror/CodeMirror"
import { Step, StepStatus } from "@/stores/WorkflowDefinition"
import { observer } from "mobx-react-lite"
import { Label } from "react-aria-components"

export const StepState = observer(({step}: {step: Step}) => {
    const failed = step.status == StepStatus.Failed

    return <div>
        {failed &&
        <div>
            <Label className="block text-gray-900 text-xl font-bold mb-2">Error</Label>
            <div><span className="text-base font-semibold">Error: </span><span className="font-mono bg-white">{step.state?.error ?? "Unknown"}</span></div>
            <div><span className="text-base font-semibold">Cause: </span><span className="font-mono bg-white">{step.state?.errorCause ?? "Unknown"}</span></div>
        </div>}
        <Label className="block text-gray-900 text-xl font-bold mb-2">Output</Label>
        <CodeMirror doc={JSON.stringify(step.state?.output, null, 2)}/>
    </div>
})