import { useWorkspaceCtx } from "@/contexts"
import { observer } from "mobx-react-lite"
import { SwButton } from "../../../../components"
import { SwIcon } from "../../../../components/Icon/Icon"
import { IconPlus } from "@tabler/icons-react"
import { SwText } from "@/components/Text/Text"


export const CreateStepCta = observer(() => {
    const ctx = useWorkspaceCtx()
    const {editor} = ctx

    return <div className="flex flex-col items-center mt-16 p-4">
        <div className="flex flex-col items-center mt-16 text-center">
            <p className="text-md font-bold">No Steps Have been Created</p>
            <p>Highlight documentation and click to create step.</p>
            <SwButton onPress={() => editor?.tipTap.commands.toggleSwStep()}>
                <SwIcon icon={IconPlus}/>
                <SwText>Create Step</SwText>
            </SwButton>
        </div>
    </div>
})