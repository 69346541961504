import { ModeChangedEvt, WorkspaceCtx, WorkspaceMode } from "@/stores/WorkspaceCtx"
import { Editor, EditorOptions } from "@tiptap/react"
import { action, computed, makeObservable, observable } from "mobx"


export class SWTipTapEditor extends Editor {
    private _isEditable: boolean = true
    get isEditable() {
        return this._isEditable ?? super.isEditable
    }

    constructor(options?: Partial<EditorOptions>) {
        super(options)
        this.setEditable(options?.editable ?? true)
        makeObservable<SWTipTapEditor, '_isEditable'>(this, {
            _isEditable: observable,
            isEditable: computed,
            setEditable: action,
        })
    }

    override setEditable(editable: boolean) {
        this._isEditable = editable
        super.setEditable(editable)
    }
}

export class WorkspaceEditor extends SWTipTapEditor {
    private disposers: (() => void)[] = []

    constructor(readonly workspace: WorkspaceCtx, options?: Partial<EditorOptions>) {
        super(options)
        if ([WorkspaceMode.Read, WorkspaceMode.Execute].includes(workspace.mode)) this.setEditable(false)
        this.disposers.push(this.workspace.onDidChangeMode(this.handleModeChange.bind(this)))
    }

    private handleModeChange(mode: ModeChangedEvt) {
        if (mode.detail === WorkspaceMode.Write) this.setEditable(true)
        if (mode.detail in [WorkspaceMode.Read, WorkspaceMode.Execute]) this.setEditable(false)
    }

    destroy() {
        this.disposers.forEach(disposer => disposer())
        this.disposers = []
        super.destroy()
    }
}
