import { EChart } from "@/components/EChart/EChart"
import { EChartsOption } from 'echarts'
import { ExecutionReportDto, StepReportDto } from '@/client/models'
import { Duration } from 'luxon'
import humanizeDuration from 'humanize-duration'
import { useWorkspaceCtx } from "@/contexts"
import { useEffect, useState } from "react"
import { useRootStore } from "@/hooks/useStores"
import { observer } from "mobx-react-lite"


let option = {
    aria: {
        show: true,
        decal: {show: true}
    },
    title: {
        text: "Step Duration",
    },
    grid: {
        top: 85,
        bottom: 50
    },
    tooltip: {
        trigger: 'item',
        axisPointer: {
            type: 'shadow',
        },
        valueFormatter(value) {
            return humanizeDuration(Number(value), {maxDecimalPoints: 2})
        },
    },
    legend: {
        top: 30,
        tooltip: {
            show: true,
        },
        formatter(value) {
            return `#${value}`
        }
    },
    toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
            mark: { show: true },
            saveAsImage: { show: true }
        }
    },
    dataset: {
        source: []
    },
    xAxis: [
        {
            type: 'category',
            axisTick: { show: false },
            axisLabel: {
                interval: 0,
                overflow: 'break',
                hideOverlap: true,
                width: 80
            }
        }
    ],
    yAxis: [
        {
            type: 'log',
            axisLabel: {
                formatter(value, index) {
                    const duration = Duration.fromMillis(value)
                    return duration.toFormat('hh:mm:ss')
                },
            }
        }
    ],
    series: []
} as EChartsOption

export interface WorkflowTimingProps {
    report: Nullable<ExecutionReportDto[]>
}

export const WorkflowTiming = observer(() => {
    const workspaceCtx = useWorkspaceCtx()
    const rootStore = useRootStore()
    const workflow = workspaceCtx.activeWorkflow

    const [data, setData] = useState<Nullable<ExecutionReportDto[]>>(null)

    useEffect(() => {
        if (!workflow) return
        (async () => {
            const data = await rootStore.sw.workflowHistoryGet(workflow!.orgId, workflow!.ref.id)
            setData(data)
        })()
    },[workflow])

    return <WorkflowTimingContent report={data}/>
})


export const WorkflowTimingContent = ({report}: WorkflowTimingProps) => {
    report ??= []
    const stepKeys = reportToKeys(report ?? [])
    const series = reportToSeries(report ?? [], stepKeys)
    // @ts-ignore
    option.xAxis[0].data = stepKeys
    option.series = series

    // @ts-ignore
    option!.legend!.tooltip!.formatter = (value) => {
        const ex = report!.find(e => e.id?.split('-')[0] == value.name)
        return `<b>#${value.name}</b> ${ex?.startedAt?.toLocaleString()}`
    }

    option = {...option}

    return <EChart option={option}/>
}


function reportToKeys(report: ExecutionReportDto[]): string[] {
    const seen = new Set<string>()
    const steps = [] as StepReportDto[]

    report.forEach(e => {
        e.steps?.forEach(s => {
            if (seen.has(s.key!))
                return
            else {
                seen.add(s.key!)
                steps.push(s)
            }
        })
    })

    steps.sort((a, b) => a.startedAt! > b.startedAt! ? 1 : -1)

    const labels = steps.map(s => s.key!)
    console.log(labels)
    return labels
}

function reportToSeries(report: ExecutionReportDto[], stepkeys: string[]) {
    const series = [] as any[]

    report.sort((a, b) => a.startedAt! < b.startedAt! ? 1 : -1)

    for (const ex of report) {
        const stepMap = new Map<string, StepReportDto>()
        ex.steps?.forEach(s => stepMap.set(s.key!, s))

        const data = [] as Nullable<number>[]
        stepkeys.forEach(k => data.push(stepMap.get(k)?.duration ?? null))

        series.push({
            name: `${ex.id?.split('-')[0]}`,
            type: 'bar',
            barGap: 0.1,
            emphasis: {
                focus: 'series'
            },
            data: data
        })
    }

    console.log("Series", series)

    return series
}