import { CommandProps, mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import { NodeRange, Node as PNode } from 'prosemirror-model'

import { Component } from './Component'
import { v4 } from 'uuid'
import { IStep, InputType, StepType } from '@/stores/WorkflowDefinition'

const enum Attr {
    step = 'data-step',
    id = 'data-id'
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        swStep: {
            /** Toggle alert. */
            toggleSwStep: () => ReturnType,
            createSwStep: (id: string, key: string) => ReturnType,
            setSwStepData: (step: IStep) => ReturnType,
        }
    }
}

export const Step = Node.create({
    name: 'swStep',

    content: 'block+',

    group: 'top',

    draggable: true,

    selectable: true,

    addAttributes() {
        return {
            id: {},
            step: {},
        }
    },

    addCommands() {
        return {
            toggleSwStep: () => ({commands, state, tr, dispatch}: CommandProps) => {
                let { $from, empty } = state.selection

                let parent: PNode = $from.node($from.depth)
                let depth = $from.depth
                while (depth > 0) {
                    parent = $from.node(depth)
                    depth--
                }

                if (parent.type === state.schema.nodes[this.name]) {
                    if(!empty) return false

                    const start = state.doc.resolve($from.start(1))
                    const end = state.doc.resolve($from.end(1))

                    const range = new NodeRange(start, end, 1)
                    dispatch?.(tr.lift(range, 0))
                    return true
                }

                const key = parent.textContent.split(' ').slice(0, 10).join(' ').slice(0, 64)
                return commands.createSwStep(v4(), key)
            },

            createSwStep: (id: string, key: string) => ({commands}: CommandProps) => {
                const step = {
                    type: StepType.Input,
                    key: key,
                    name: null,
                    id: id,
                    end: false,
                } as IStep

                return commands.toggleWrap(this.name, {id, step})
            },
        }
    },

    parseHTML() {
        return [{
            tag: 'sw-step',
            getAttrs: (dom) => {
                if (typeof dom == "string") return {}
                return {
                    id: dom.getAttribute(Attr.id),
                    step: JSON.parse(dom.getAttribute(Attr.step)!)
                }
            }
        }]
    },

    renderHTML({ HTMLAttributes }) {
        const {id, step} = HTMLAttributes
        return ['sw-step', {[Attr.id]: id, [Attr.step]: JSON.stringify(step)}, 0]
    },

    addNodeView: () => (props) => {
        return ReactNodeViewRenderer(Component, {className: 'my-4 -mx-4', contentDOMElementTag: 'section', attrs: {[Attr.id]: props.node.attrs.id}})(props)
    },
})
