import { observer } from "mobx-react-lite"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { IconGripVertical } from "@tabler/icons-react"

import { OrgContext, RootContext, WorkspaceContext } from "../../../contexts.js"
import { SwButton } from "@/components/Button"
import { Runbook, RunbookMoveOp } from "@/stores/Runbook"
import { useDragAndDrop } from "react-aria-components"

import { TreeGridList } from "@/components/TreeGridList/TreeGridList"
import { DragItem } from "react-aria"
import { RunbookItemMenu } from "./RunbookItemMenu.js"
import { useDocStore } from "@/stores/DocStore.js"


interface RunbookListItem {
    id: string
    name: string
    runbook: Runbook
    children: RunbookListItem[]
}

export const RunbookList = observer(() => {
    const orgCtx = useContext(OrgContext)!
    const workspaceCtx = useContext(WorkspaceContext)!
    const rootStore = useContext(RootContext)!
    const runbooks = rootStore.runbooks

    const navigate = useNavigate()

    useEffect(() => {
        if (!orgCtx.orgId) return
        runbooks.load(orgCtx.orgId)
    }, [orgCtx.orgId])

    const runbookToItem = (runbook: Runbook): RunbookListItem => ({
        id: runbook.id,
        name: runbook.name,
        runbook: runbook,
        children: runbook.children.map(runbookToItem)
    })

    const listItems = runbooks.runbookRoots.slice().map(runbookToItem)
    const selectedKeys = workspaceCtx.activeRunbook ? [workspaceCtx.activeRunbook.id] : []

    const { dragAndDropHooks: dnd } = useDragAndDrop({
        getItems: (keys) => {
            return [...keys].map(k => {
                const runbook = runbooks.byId.get(k.toString())
                return {
                    [Runbook.MimeType]: JSON.stringify(runbook!.toSpec()),
                    [`${Runbook.MimeType}/id/${k}`]: k as string,
                    "text/plain": runbook!.name as string
                }
            })
        },
        acceptedDragTypes: [Runbook.MimeType],
        onItemDrop: async (e) => {
            const targetRunbook = runbooks.byId.get(e.target.key.toString())!

            const items = (await Promise.all(e.items.map(async (item) => {
                const runbook = item.kind == 'text' ? JSON.parse(await item.getText(Runbook.MimeType)) : null
                return runbook
            }))).filter(r => r) as Runbook[]
            const sourceRunbook = runbooks.byId.get(items[0].id)!

            runbooks.moveRunbook(sourceRunbook, targetRunbook, RunbookMoveOp.InsertInto)
        },
        onInsert: async (e) => {
            const targetRunbook = runbooks.byId.get(e.target.key.toString())!
            const items = (await Promise.all(e.items.map(async (item) => {
                const runbook = item.kind == 'text' ? JSON.parse(await item.getText(Runbook.MimeType)) : null
                return runbook
            }))).filter(r => r) as Runbook[]

            const sourceRunbook = runbooks.byId.get(items[0].id)!

            if (e.target.dropPosition === 'before') {
                runbooks.moveRunbook(sourceRunbook, targetRunbook, RunbookMoveOp.InsertBefore)
            } else if (e.target.dropPosition === 'after') {
                runbooks.moveRunbook(sourceRunbook, targetRunbook, RunbookMoveOp.InsertAfter)
            }
        },
        onReorder(e) {
            const sourceKey = [...e.keys.values()][0]
            const targetKey = e.target.key
            const sourceRunbook = runbooks.byId.get(sourceKey.toString())!
            const targetRunbook = runbooks.byId.get(targetKey.toString())!

            if (e.target.dropPosition === 'before') {
                runbooks.moveRunbook(sourceRunbook, targetRunbook, RunbookMoveOp.InsertBefore)
            } else if (e.target.dropPosition === 'after') {
                runbooks.moveRunbook(sourceRunbook, targetRunbook, RunbookMoveOp.InsertAfter)
            }
        },
        getDropOperation: (target, {types}: any, allowedOps) => {
            if (target.type !== 'item') return 'cancel'

            const typeSet = types as Set<string>
            const keyType = [...typeSet.values()].find(t => t.startsWith(`${Runbook.MimeType}/id/`))
            if (!keyType) return 'cancel'
            const key = keyType.split('/').at(-1)

            if (target.key == key) return 'cancel'

            const sourceRunbook = runbooks.byId.get(key!)!
            const targetRunbook = runbooks.byId.get(target.key.toString())!

            const { dropPosition } = target

            const totalDepth = sourceRunbook.subTreeDepth + targetRunbook.depth

            if (dropPosition == 'on' && totalDepth + 1 > 2) return 'cancel'
            else if (totalDepth > 2) return 'cancel'
            if (targetRunbook.ancestors.includes(sourceRunbook)) return 'cancel'
            if (dropPosition == 'before' && targetRunbook.prevousSibling == sourceRunbook) return 'cancel'
            if (dropPosition == 'after' && targetRunbook.nextSibling == sourceRunbook) return 'cancel'
            if (dropPosition == 'on' && sourceRunbook.parent == targetRunbook) return 'cancel'

            return 'move'
        },
        renderDragPreview: (items) => <DragPreview items={items} />
    })


    return (
        <div className="sw-scroller h-full">
            <TreeGridList
                aria-label="Runbooks"
                selectedKeys={selectedKeys}
                selectionMode="single"
                initialList={listItems}
                dragAndDropHooks={dnd}
                onSelectionChange={(id) => {
                    if (id == 'all' || id.size == 0) return
                    const key = id.entries().next().value[0]
                    const runbook = runbooks.runbooks.find(r => r.id == key) ?? null
                    workspaceCtx.setActiveRunbook(runbook)
                    if (runbook) navigate(`/${runbook.orgId}/${runbook.workspaceId}/${runbook.id}`)
                }}>
                {(item, props) => {
                    const [menuOpen, setMenuOpen] = useState(false)
                    const docs = useDocStore()

                    useEffect(() => {
                        if (!props.isHovered) return
                        const timer = setTimeout(() => docs.preLoad(item.runbook.doc), 300)
                        return () => clearTimeout(timer)
                    }, [props.isHovered])

                    const displayMenu = props.isHovered || menuOpen
                    return (<>
                    <SwButton variant="icon" slot="drag">
                        <IconGripVertical size={15}/>
                    </SwButton>
                    <span className="truncate text-sm" title={item.name}>{item.name}</span>
                    <RunbookItemMenu isHidden={!displayMenu} isOpen={menuOpen} onOpenChange={setMenuOpen} runbook={item.runbook} workspace={workspaceCtx}/>
                    </>)}}
            </TreeGridList>
        </div>
    )
}) as React.FunctionComponent
RunbookList.displayName = 'RunbookList'

export const DragPreview = ({items}: {items: DragItem[]}) =>
    <div className="flex items-center rounded bg-purple-500 text-white p-2 max-w-[150px] overflow-hidden">
        <span className="truncate text-sm">
            {items[0]['text/plain']}
        </span>
        <span className="rounded bg-white ml-4 text-black min-w-[20px] text-center">
            {items.length}
        </span>
    </div>