import clsx from 'clsx'

interface LogoIconProps {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl'
}
const BaseLogoIconStyles = {
    size: {
        xs: 'w-8 h-8',
        sm: 'w-10 h-10',
        md: 'w-12 h-12',
        lg: 'w-16 h-16',
        xl: 'w-24 h-24',
        xxl: 'w-32 h-32',
        xxxl: 'w-48 h-48',
    }
}

export const LogoIcon = (props: LogoIconProps) => {
    const {
        size = 'xs',
    } = props
    return (
    <svg className={
        clsx(
            'rounded inline-block',
            BaseLogoIconStyles.size[size]
        )
    }viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_1776_17015" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="36">
<rect y="0.53125" width="34.93" height="34.93" fill="#6100FF"/>
</mask>
<g mask="url(#mask0_1776_17015)">
<path d="M0 8.41061C0 4.05896 3.52771 0.53125 7.87936 0.53125H27.0506C31.4023 0.53125 34.93 4.05896 34.93 8.41061V27.5819C34.93 31.9335 31.4023 35.4613 27.0506 35.4613H7.87936C3.52771 35.4613 0 31.9335 0 27.5819V8.41061Z" fill="#6100FF"/>
<path d="M-1.97345 23.2144L23.0779 23.9544C25.7743 24.034 27.0037 27.3546 25.0094 29.1711L9.17739 43.5904M-4.10981 15.4235L18.6566 15.4257C21.3974 15.4259 22.7027 18.7987 20.6761 20.6439L4.1885 35.6559M-5.3584 8.375L11.9769 8.38567C14.7173 8.38736 16.021 11.76 13.9942 13.6045L-4.2919 30.2454" stroke="white" strokeWidth="3"/>
</g>
</svg>


    )
}