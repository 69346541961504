import { Workflow } from "@/stores/WorkflowDefinition"

export function WorkflowToNodes(workflow: Workflow): [any[], any[]] {
    let y = 0
    const nodes = workflow.stepList.map( state => {
        const node = {id: state.id, type: 'step', position: {x: 0, y}, data: {step: state, label: state.id}}
        y+=100
        return node
    })
    const edges = workflow.stepList.map( state => {
        return state.next ? {id: state.id, source: state.id, target: state.next.id} : null
    }).filter(e => e)

    return [nodes, edges]
}