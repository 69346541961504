import { useRef, useState } from 'react'
import { NodeViewWrapper, NodeViewProps, NodeViewContent } from '@tiptap/react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'
import { SwButton } from '@/components/Button'
import { IconInfoCircle, IconInfoTriangle } from '@tabler/icons-react'


export const Component =  observer((props: NodeViewProps) => {
    const type = props.node.attrs['type'] as 'note' | 'important' | 'warning'

    let color: string
    let before: string

    switch(type) {
    case 'note':
        color = "text-blue-600"; before = `before:bg-blue-800`; break
    case 'important':
        color = 'text-purple-600'; before = `before:bg-purple-800`; break
    case 'warning':
        color = 'text-yellow-600'; before = `before:bg-yellow-800`; break
    }

    return (
        <NodeViewWrapper
            draggable={true}
            className={`before:top-0 before:bottom-0 before:absolute relative before:w-1 ${before}`}
        >
            <div className="px-5">
                <span
                    contentEditable={false}
                    className={`${color} mb-1 flex items-center gap-1.5`}
                >
                    {type == 'note' &&
                        <IconInfoCircle className="shrink-0" size={16}/>}
                    {type == 'important' &&
                        <IconInfoCircle className="shrink-0" size={16}/>}
                    {type == 'warning' &&
                        <IconInfoTriangle className="shrink-0" size={16}/>}
                    {type == 'note' ? "Note" : type == "important" ? "Important" : "Warning"}
                </span>
                <NodeViewContent/>
            </div>
        </NodeViewWrapper>
    )
})
Component.displayName = 'StepNodeView'