import { useAuthStore } from "@/hooks/useStores"
import { useNavigate, useSearchParams } from "react-router-dom"
import { ResetPasswordForm } from "./ResetPasswordForm"

export const ResetPasswordScreen = () => {
    const auth = useAuthStore()
    const [params, _] = useSearchParams()

    const code = params.get('code')!
    const email = params.get('email')!

    const navigate = useNavigate()

    const continueTo = () => {
        const continueTo = params.get('continueTo')
        if (continueTo) {
            navigate(continueTo)
        } else {
            navigate('/')
        }
    }

    return (
        <div className="flex items-center w-screen h-screen justify-around">
            <div className="w-80 h-80">
                <ResetPasswordForm onLogin={continueTo} code={code} email={email}/>
            </div>
        </div>
    )
}