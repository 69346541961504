import { diff } from "deep-diff"
import { runInAction, toJS } from "mobx"
import { observer } from "mobx-react-lite"
import validator from '@rjsf/validator-ajv8'

import { SchemaForm } from "@/components/SchemaForm/SchemaForm"
import { InputStep, TaskStep } from "@/stores/WorkflowDefinition"
import { ConcreteStepSettingsProps, StepSchema, StepUiSchema } from "../StepSettings"
import { cloneDeep, merge } from "lodash-es"
import { SplitGroup } from "@/components/SplitGroup/SplitGroup"
import { SwButton } from "@/components"
import { UserData, UserTagField } from "@/components/widgets/UserTagField/UserTagField"


interface PromptStepSettingsProps extends ConcreteStepSettingsProps {
    step: InputStep
}

export const ApprovalStepSetting = observer(({step, onSubmit, reset, isDirty, setIsDirty, readonly}: PromptStepSettingsProps) => {

    const stepSchema = merge(cloneDeep(StepSchema),
        {
            // properties: {
            //     prompts: cloneDeep(PromptSchema)
            // }
        }
    )

    const formData = {
        name: step.name,
        comment: step.comment,
        // prompts: [...step.prompts.values()]
    }

    return <div>
        <SchemaForm
            schema={stepSchema}
            uiSchema={StepUiSchema}
            formData={formData}
            validator={validator}
            readonly={readonly}
            onChange={(e, id) => {
                console.log(id)
                if (!id) return
                console.time("Update")
                const diffs = diff(formData, e.formData)
                updatePromptStep(step, e.formData)
                if (diffs) setIsDirty(true)
                console.timeEnd("Update")
            }}
            onSubmit={({formData}) => {
                console.log('Submitting step', toJS(step))
                onSubmit(step)
                setIsDirty(false)
            }}
        >
            <ReviewSettings setIsDirty={setIsDirty} step={step} readonly={readonly}/>
            {/* <PromptSettings setIsDirty={setIsDirty} step={step}/> */}
            <div className="mt-4">
                <SplitGroup>
                    <div>{isDirty && <SwButton onPress={() => reset()}>Reset</SwButton>}</div>
                    <div>{isDirty && <SwButton type="submit" variant="cta">Save</SwButton>}</div>
                </SplitGroup>
            </div>

        </SchemaForm>
    </div>
})

const ReviewSettings = observer(({step, setIsDirty, readonly}: {step: InputStep, readonly: boolean, setIsDirty: (val: boolean) => void}) => {
    const review = step.review

    if (!review) return <></>

    const _onUsersChange = (value: UserData[]) => {
        console.log('Users change')
        review.users = value.map(v => v.name)
        setIsDirty(true)
    }

    return <>
        <UserTagField label="Reviewers" isReadOnly={readonly} tagItems={review.users.map(u => ({id: u, name: u, orgId: 0}))} onChange={(value) => _onUsersChange(value)}/>
    </>
})

const InputSettings = observer(({step, setIsDirty}: {step: InputStep, setIsDirty: (val: boolean) => void}) => {
    return <></>
})

function updatePromptStep(step: InputStep, data: any) {
    console.log("Update task")
    runInAction(() => {
        step.name = data.name
        step.comment = data.comment

        if (step instanceof TaskStep)
            step.parameters = data.parameters

        // for (const [key, prompt] of step.prompts.entries()) {
        //     if (!data.prompts.some((p: any) => p.key == key))
        //         step.prompts.delete(key)
        // }

        // for (const dataPrompt of data.prompts) {
        //     console.log("Updating prompts", step, dataPrompt)
        //     dataPrompt.type ??= PromptType.Ack
        //     dataPrompt.key ??= `prompt-${step.prompts.size}`
        //     step.addPrompt(dataPrompt.key, dataPrompt)
        // }
    })
}