import {Node, ResolvedPos} from 'prosemirror-model'

declare module 'prosemirror-model' {
    interface ResolvedPos {
        nodesAbove(visitor: (node: Node) => void): void
    }
}

ResolvedPos.prototype.nodesAbove = function(visitor: (node: Node) => void) {
    let depth = this.depth
    let parent: Node | undefined
    do {
        parent = this.node(depth)
        if (parent) visitor(parent)
        depth--
    } while (depth > 0 && parent)
}
