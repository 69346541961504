import React, { PropsWithChildren } from "react"
import clsx from "clsx"
import { SlotProvider } from "@react-spectrum/utils"
import { SwText } from "../Text/Text"


export interface SwBadgeProps extends PropsWithChildren {
    variant?: 'info' | 'positive' | 'negative' | 'neutral'
}

const SwBadgeStyles = {
    variant: {
        'info' : [
            'bg-blue-700 text-white'
        ],
        'positive': [
            'bg-green-700 text-white'
        ],
        'negative': [
            'bg-red-700 text-white'
        ],
        'neutral': [
            'bg-slate-300 text-black font-semibold'
        ]

    }
} as const

export const SwBadge = ({children, variant = 'positive'}: SwBadgeProps) => {
    const isTextOnly = (typeof children !== 'function') && React.Children.toArray(children).every(c => !React.isValidElement(c))

    return (
        <span
            className={clsx(
                "sw-Badge",
                "inline-flex align-middle items-center",
                "text-[0.875rem]",
                "rounded rounded",
                "h-[1.625rem]",
                SwBadgeStyles.variant[variant]
            )}
        >
          <SlotProvider
              slots={{
                  text: { className: "last:mr-3 first:ml-3 " },
                  icon: { className: "mx-1 only:mx-0 last:mr-2 first:ml-2", size: 20 }
              }}
          >
            {typeof children === 'string' || isTextOnly
                ? <SwText>{children}</SwText>
                : children}
          </SlotProvider>
        </span>
    )
}