export function serial<TReturn, TArg extends Array<string|number|null|undefined>>(originalMethod: (...args: TArg) => Promise<TReturn>, context: ClassMethodDecoratorContext) {
    const SERIAL = Symbol("Serial")

    function replacementMethod(this: any, ...args: TArg) {
        const promMap = this[SERIAL] as Map<string, Promise<TReturn>>
        const key = args.map(a => `${a}`).reduce((k, v) => `${k}${v}`, "")
        const prom = promMap.get(key)
        if (prom) return prom
        const newProm = originalMethod.call(this, ...args).finally(() => promMap.delete(key))
        promMap.set(key, newProm)
        return newProm
    }

    context.addInitializer(function() {
        (<any>this)[SERIAL] = new Map<string, Promise<TReturn>>
    })

    return replacementMethod
}