import {
    Tabs as AriaTabs,
    TabList as AriaTabList,
    Tab as AriaTab,
    TabPanel as AriaTabPanel
} from 'react-aria-components'
import clsx from 'clsx'

import { TabsProps, TabProps, TabListProps, TabPanelProps } from './Props'
import './Tabs.css'
import { ReactElement } from 'react'

export const BaseTabsStyles = {
    outline: 'outline-none focus:outline-none',
    focus: 'focus:ring-2 focus:ring-indigo-300',
    size: {
        xs: 'text-xs py-0.2 !px-3',
        sm: 'text-sm py-0.5 !px-3',
        md: 'text-base py-1 !px-4',
        lg: 'text-lg py-2 !px-4',
    },
    variant: {
        primary: {
            tabs: 'sw-primary sw-aria-tabs',
            tabList: 'sw-primary sw-aria-tab-list flex',
            tab: 'sw-primary sw-aria-tab',
            tabPanels: 'sw-primary sw-aria-tab-panels bg-yellow-500',
            tabPanel: 'sw-primary sw-aria-tab-panel h-full overflow-hidden w-full',
        },
        secondary: {
            tabs: 'sw-secondary sw-aria-tabs',
            tabList: 'sw-secondary sw-aria-tab-list flex justify-start ',
            tab: 'sw-secondary sw-aria-tab  py-3 cursor-pointer dark:text-gray-200 no-underline hover:no-underline flex items-center space-x-4 rounded',
            tabPanels: 'sw-secondary sw-aria-tab-panels bg-yellow-500',
            tabPanel: 'sw-secondary sw-aria-tab-panel h-full overflow-hidden',
        },
        compact: {
            tabs: 'sw-compact sw-aria-tabs',
            tabList: 'sw-compact sw-aria-tab-list flex justify-start',
            tab: 'sw-compact sw-aria-tab  cursor-pointer dark:text-gray-200 no-underline hover:no-underline transition duration-150 ease-out hover:ease-in',
            tabPanels: 'sw-compact sw-aria-tab-panels bg-yellow-500',
            tabPanel: 'sw-compact sw-aria-tab-panel h-full overflow-hidden',
        },
    }
}


export const SwTabs = (props: TabsProps) => {
    const {
        variant = 'primary',
        size = 'md',
        children,
        ...otherProps
    } = props
    return <AriaTabs
    //className={`${BaseTabsStyles.variant[variant].tabs}`}
        className={() =>
            clsx(
                "sw-aria-tabs",
                "flex flex-col h-full",
                BaseTabsStyles.variant[variant].tabs,
            )
        }
        data-size={size}
        {...props}
    />
}

export const SwTabList = (props: TabListProps) => {
    const {
        variant = 'primary',
        children,
        ...otherProps
    } = props

    return <AriaTabList
        //className={`${BaseTabsStyles.variant[variant].tabList}`}
        className={() =>
            clsx(
                "sw-aria-tabs",
                BaseTabsStyles.variant[variant].tabList,

            )
        }
        {...props}
    />
}

export const SwTab = (props: TabProps) => {
    const {
        variant = 'primary',
        size = 'md',
        children,
        ...otherProps
    } = props
    return <AriaTab
        className={({isFocusVisible}) =>
            clsx(
                "sw-aria-tabs",
                "sw-aria-tab__indicator-elem",
                BaseTabsStyles.outline,
                isFocusVisible && BaseTabsStyles.focus,
                BaseTabsStyles.variant[variant].tab,
                BaseTabsStyles.size[size],
            )
        }
        {...props}
    />
}

export interface TabStackedProps extends Omit<TabProps, 'children'> {
    children: [ReactElement, ReactElement]
}

export const SwTabStacked = (props: TabStackedProps) => {
    const {
        variant = 'primary',
        size = 'md',
        children,
    } = props
    return <AriaTab
        className={({isFocusVisible}) =>
            clsx(
                "sw-aria-tabs",
                BaseTabsStyles.outline,
                isFocusVisible && BaseTabsStyles.focus,
                BaseTabsStyles.variant[variant].tab,
                BaseTabsStyles.size[size],
            )
        }
        {...props}>
            <div className='flex flex-col flex justify-center'>
                <div className="sw-aria-tab__indicator-elem pb-2 flex justify-center font-semibold">
                    {children[0]}
                </div>
                <div className='pt-2 text-5xl font-semibold flex justify-center'>
                    {children[1]}
                </div>
            </div>
        </AriaTab>
}

export const SwTabPanel = (props: TabPanelProps) => {
    const {
        variant = 'primary',
        className,
        children,
        ...otherProps
    } = props
    return <AriaTabPanel className={clsx(BaseTabsStyles.variant[variant].tabPanel, className)}  {...props} />
}