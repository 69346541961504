import * as coreClient from "@azure/core-client";
import * as coreRestPipeline from "@azure/core-rest-pipeline";

export interface AuthInfoDto {
  methods: AuthMethodDto[];
}

export interface AuthMethodDto {
  provider: string;
  entry?: string;
}

export interface AuthLoginDto {
  userName: string;
  password: string;
  rememberMe?: boolean;
}

export interface UserDto {
  orgId: number;
  id: string;
  userName: string;
  avatarUrl: string;
}

export interface ErrorDto {
  message: string;
  code: string;
  errors: ValidationErrorDto[];
}

export interface ValidationErrorDto {
  property: string;
  message: string;
  code: string;
}

export interface AuthRegisterDto {
  email: string;
  password: string;
}

export interface DocMaterialDto {
  version: number;
  /** Anything */
  baseDoc: any;
  commits: ICommit[];
}

export interface ICommit {
  version: number;
  ref: string;
  /** Anything */
  steps: any;
}

export interface FileInfoDto {
  id: string;
  mediaType: string;
  name?: string;
  size: number;
  url: string;
}

export interface WorkflowDto {
  ref: WorkflowRef;
  orgId: number;
  name: string;
  definition: string;
}

export interface WorkflowRef {
  id: string;
  revision: number;
}

export interface WorkflowCreateDto {
  name: string;
  definition: string;
}

export interface ExecutionDto {
  orgId: number;
  id: string;
  status: ExecutionStatus;
  author: AuthorDto;
  workflow: WorkflowRef;
  currentStep?: string;
  /** Dictionary of <ExecutionStepDto> */
  steps: { [propertyName: string]: ExecutionStepDto };
  breakPoints: IBreakPoint[];
  createdAt: Date;
  startedAt?: Date;
  finishedAt?: Date;
}

export interface AuthorDto {
  orgId: number;
  id: string;
  userName: string;
}

export interface ExecutionStepDto {
  id: string;
  key: string;
  name?: string;
  type: StepType;
  orgId: number;
  status: StepStatus;
  error: string;
  errorCause: string;
  /** Dictionary of <ExecutionInputDto> */
  inputs: { [propertyName: string]: ExecutionInputDto };
  end: boolean;
  submittedAt?: Date;
  submitter?: AuthorDto;
  review?: ReviewDto;
  output?: JsonNode;
  startedAt?: Date;
  finishedAt?: Date;
}

export interface ExecutionInputDto {
  id: string;
  type: InputType;
  key: string;
  name?: string;
  isSatisfied: boolean;
  /** Anything */
  value?: any;
  users: string[];
  groups: string[];
}

export interface ReviewDto {
  approved: boolean;
  reviewedAt: Date;
  reviewer: AuthorDto;
}

export interface JsonNode {
  options?: JsonNodeOptions;
  parent?: JsonNode;
  root?: JsonNode;
}

export interface JsonNodeOptions {
  propertyNameCaseInsensitive?: boolean;
}

export interface IBreakPoint {
  state?: string;
  status?: StepStatus;
}

export interface StepActivityDto {
  id: string;
  key: string;
  name?: string;
  type: StepType;
  orgId: number;
  status: StepStatus;
  error: string;
  errorCause: string;
  /** Dictionary of <ExecutionInputDto> */
  inputs: { [propertyName: string]: ExecutionInputDto };
  end: boolean;
  submittedAt?: Date;
  submitter?: AuthorDto;
  review?: ReviewDto;
  output?: JsonNode;
  startedAt?: Date;
  finishedAt?: Date;
  runbookExecutionId: string;
  runbookName: string;
}

export interface BulkResponseCreateDto {
  step: string;
  /** Dictionary of <ResponseDto> */
  values?: { [propertyName: string]: ResponseDto };
}

export interface ResponseDto {
  type: InputType;
  /** Anything */
  value?: any;
}

export interface ReviewCreateDto {
  stepKey: string;
  decision: boolean;
}

export interface TaskDto {
  name: string;
  propSchema?: string;
}

export interface ExecutionAttachmentDto {
  id: string;
  orgId: number;
  authorId: string;
  file: FileInfoDto;
  createdAt: Date;
  updatedAt: Date;
  executionId: string;
  stepId: string;
}

export interface ExecutionReportDto {
  id?: string;
  status?: ExecutionStatus;
  startedAt?: Date;
  finishedAt?: Date;
  steps?: StepReportDto[];
}

export interface StepReportDto {
  key?: string;
  status?: StepStatus;
  startedAt?: Date;
  finishedAt?: Date;
  duration?: number;
}

export interface OrgDto {
  id: number;
  name: string;
  key: string;
  emailDomain?: string;
}

export interface OrgStatusDto {
  completedExecutions: number;
  runningExecutions: number;
  pendingPromps: number;
}

export interface RunbookDto {
  id: string;
  parentId: string;
  name: string;
  orgId: number;
  workspaceId: number;
  rank: string;
  docId: string;
  workflowRef: WorkflowRef;
}

export interface RunbookCreateDto {
  name: string;
  parentId?: string;
}

export interface RunbookUpdateDto {
  name?: string;
}

export interface RunbookMoveDto {
  targetId: string;
  operation: RunbookMoveOp;
}

export interface RunbookExecutionDto {
  id: string;
  orgId: number;
  runbookId: string;
  author: AuthorDto;
  docId: string;
  docVersion: number;
  workflowRef: WorkflowRef;
  executionId: string;
  executionStatus: ExecutionStatus;
  createdAt: Date;
  updatedAt: Date;
}

export interface RunbookFtsDto {
  orgId: number;
  workspaceId: number;
  name: string;
  runbookId: string;
  text: string;
}

export interface WorkspaceDto {
  id: number;
  name: string;
  orgId: number;
}

export interface WorkspaceCreateDto {
  name: string;
}

export interface FileCreateDto {
  contentLength: number;
  mediaType: string;
  name: string;
}

/** Known values of {@link ExecutionStatus} that the service accepts. */
export enum KnownExecutionStatus {
  /** Running */
  Running = "Running",
  /** Succeeded */
  Succeeded = "Succeeded",
  /** Failed */
  Failed = "Failed",
  /** TimedOut */
  TimedOut = "TimedOut",
  /** Aborted */
  Aborted = "Aborted",
}

/**
 * Defines values for ExecutionStatus. \
 * {@link KnownExecutionStatus} can be used interchangeably with ExecutionStatus,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Running** \
 * **Succeeded** \
 * **Failed** \
 * **TimedOut** \
 * **Aborted**
 */
export type ExecutionStatus = string;

/** Known values of {@link StepType} that the service accepts. */
export enum KnownStepType {
  /** Task */
  Task = "Task",
  /** Input */
  Input = "Input",
  /** Pass */
  Pass = "Pass",
  /** Success */
  Success = "Success",
  /** Fail */
  Fail = "Fail",
  /** Group */
  Group = "Group",
}

/**
 * Defines values for StepType. \
 * {@link KnownStepType} can be used interchangeably with StepType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Task** \
 * **Input** \
 * **Pass** \
 * **Success** \
 * **Fail** \
 * **Group**
 */
export type StepType = string;

/** Known values of {@link StepStatus} that the service accepts. */
export enum KnownStepStatus {
  /** Pending */
  Pending = "Pending",
  /** Running */
  Running = "Running",
  /** Succeeded */
  Succeeded = "Succeeded",
  /** Failed */
  Failed = "Failed",
  /** Aborted */
  Aborted = "Aborted",
}

/**
 * Defines values for StepStatus. \
 * {@link KnownStepStatus} can be used interchangeably with StepStatus,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Pending** \
 * **Running** \
 * **Succeeded** \
 * **Failed** \
 * **Aborted**
 */
export type StepStatus = string;

/** Known values of {@link InputType} that the service accepts. */
export enum KnownInputType {
  /** Boolean */
  Boolean = "Boolean",
  /** Text */
  Text = "Text",
  /** Doc */
  Doc = "Doc",
  /** Number */
  Number = "Number",
  /** Date */
  Date = "Date",
  /** File */
  File = "File",
  /** Approval */
  Approval = "Approval",
  /** Ack */
  Ack = "Ack",
  /** String */
  String = "String",
  /** Float */
  Float = "Float",
  /** Int */
  Int = "Int",
}

/**
 * Defines values for InputType. \
 * {@link KnownInputType} can be used interchangeably with InputType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Boolean** \
 * **Text** \
 * **Doc** \
 * **Number** \
 * **Date** \
 * **File** \
 * **Approval** \
 * **Ack** \
 * **String** \
 * **Float** \
 * **Int**
 */
export type InputType = string;

/** Known values of {@link RunbookMoveOp} that the service accepts. */
export enum KnownRunbookMoveOp {
  /** InsertInto */
  InsertInto = "InsertInto",
  /** InsertBefore */
  InsertBefore = "InsertBefore",
  /** InsertAfter */
  InsertAfter = "InsertAfter",
}

/**
 * Defines values for RunbookMoveOp. \
 * {@link KnownRunbookMoveOp} can be used interchangeably with RunbookMoveOp,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **InsertInto** \
 * **InsertBefore** \
 * **InsertAfter**
 */
export type RunbookMoveOp = string;

/** Optional parameters. */
export interface AuthGetOptionalParams extends coreClient.OperationOptions {}

/** Contains response data for the authGet operation. */
export type AuthGetResponse = AuthInfoDto;

/** Optional parameters. */
export interface AuthLoginOptionalParams extends coreClient.OperationOptions {}

/** Contains response data for the authLogin operation. */
export type AuthLoginResponse = UserDto;

/** Optional parameters. */
export interface AuthLogoutOptionalParams extends coreClient.OperationOptions {}

/** Contains response data for the authLogout operation. */
export type AuthLogoutResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface AuthLoginExternalOptionalParams
  extends coreClient.OperationOptions {
  provider?: string;
  returnUrl?: string;
}

/** Optional parameters. */
export interface AuthRemoteCallbackOptionalParams
  extends coreClient.OperationOptions {
  returnUrl?: string;
}

/** Optional parameters. */
export interface AuthRegisterOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the authRegister operation. */
export type AuthRegisterResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface AuthEmailConfirmOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the authEmailConfirm operation. */
export type AuthEmailConfirmResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface AuthPasswordForgotOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the authPasswordForgot operation. */
export type AuthPasswordForgotResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface AuthPasswordResetOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the authPasswordReset operation. */
export type AuthPasswordResetResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface DocPublishOptionalParams extends coreClient.OperationOptions {}

/** Contains response data for the docPublish operation. */
export type DocPublishResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface DocMaterialGetOptionalParams
  extends coreClient.OperationOptions {
  version?: number;
}

/** Contains response data for the docMaterialGet operation. */
export type DocMaterialGetResponse = DocMaterialDto;

/** Optional parameters. */
export interface DocAttachmentCreateOptionalParams
  extends coreClient.OperationOptions {
  data?: coreRestPipeline.RequestBodyType;
  xSwFilename?: string;
}

/** Contains response data for the docAttachmentCreate operation. */
export type DocAttachmentCreateResponse = FileInfoDto;

/** Optional parameters. */
export interface FileGetOptionalParams extends coreClient.OperationOptions {}

/** Optional parameters. */
export interface FileCreateInOrgOptionalParams
  extends coreClient.OperationOptions {
  data?: coreRestPipeline.RequestBodyType;
  xSwFilename?: string;
}

/** Contains response data for the fileCreateInOrg operation. */
export type FileCreateInOrgResponse = FileInfoDto;

/** Optional parameters. */
export interface WorkflowGetOptionalParams extends coreClient.OperationOptions {
  revision?: number;
}

/** Contains response data for the workflowGet operation. */
export type WorkflowGetResponse = WorkflowDto;

/** Optional parameters. */
export interface WorkflowUpdateOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the workflowUpdate operation. */
export type WorkflowUpdateResponse = WorkflowDto;

/** Optional parameters. */
export interface WorkflowCreateOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the workflowCreate operation. */
export type WorkflowCreateResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface ExecutionCreateOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the executionCreate operation. */
export type ExecutionCreateResponse = ExecutionDto;

/** Optional parameters. */
export interface ExecutionStopOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the executionStop operation. */
export type ExecutionStopResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface ExecutionGetOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the executionGet operation. */
export type ExecutionGetResponse = ExecutionDto;

/** Optional parameters. */
export interface ExecutionGetForUserOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the executionGetForUser operation. */
export type ExecutionGetForUserResponse = ExecutionDto[];

/** Optional parameters. */
export interface StepActivityGetForUserOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the stepActivityGetForUser operation. */
export type StepActivityGetForUserResponse = StepActivityDto[];

/** Optional parameters. */
export interface ExecutionResponseBulkCreateOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the executionResponseBulkCreate operation. */
export type ExecutionResponseBulkCreateResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface ExecutionReviewCreateOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the executionReviewCreate operation. */
export type ExecutionReviewCreateResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface TaskListOptionalParams extends coreClient.OperationOptions {}

/** Contains response data for the taskList operation. */
export type TaskListResponse = TaskDto[];

/** Optional parameters. */
export interface ExecutionAttachmentCreateOptionalParams
  extends coreClient.OperationOptions {
  data?: coreRestPipeline.RequestBodyType;
  xSwFilename?: string;
}

/** Contains response data for the executionAttachmentCreate operation. */
export type ExecutionAttachmentCreateResponse = ExecutionAttachmentDto;

/** Optional parameters. */
export interface ExecutionAttachmentListOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the executionAttachmentList operation. */
export type ExecutionAttachmentListResponse = ExecutionAttachmentDto[];

/** Optional parameters. */
export interface ExecutionStepAttachmentCreateOptionalParams
  extends coreClient.OperationOptions {
  data?: coreRestPipeline.RequestBodyType;
  xSwFilename?: string;
}

/** Contains response data for the executionStepAttachmentCreate operation. */
export type ExecutionStepAttachmentCreateResponse = ExecutionAttachmentDto;

/** Optional parameters. */
export interface ExecutionStepAttachmentListOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the executionStepAttachmentList operation. */
export type ExecutionStepAttachmentListResponse = ExecutionAttachmentDto[];

/** Optional parameters. */
export interface WorkflowHistoryGetOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the workflowHistoryGet operation. */
export type WorkflowHistoryGetResponse = ExecutionReportDto[];

/** Optional parameters. */
export interface OrgGetByIdOptionalParams extends coreClient.OperationOptions {}

/** Contains response data for the orgGetById operation. */
export type OrgGetByIdResponse = OrgDto;

/** Optional parameters. */
export interface OrgGetStatusOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the orgGetStatus operation. */
export type OrgGetStatusResponse = OrgStatusDto;

/** Optional parameters. */
export interface RunbookListByOrgOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the runbookListByOrg operation. */
export type RunbookListByOrgResponse = RunbookDto[];

/** Optional parameters. */
export interface RunbookListByOrgAndWorkspaceOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the runbookListByOrgAndWorkspace operation. */
export type RunbookListByOrgAndWorkspaceResponse = RunbookDto[];

/** Optional parameters. */
export interface RunbookCreateOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the runbookCreate operation. */
export type RunbookCreateResponse = RunbookDto;

/** Optional parameters. */
export interface RunbookGetOptionalParams extends coreClient.OperationOptions {}

/** Contains response data for the runbookGet operation. */
export type RunbookGetResponse = RunbookDto;

/** Optional parameters. */
export interface RunbookUpdateOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the runbookUpdate operation. */
export type RunbookUpdateResponse = RunbookDto;

/** Optional parameters. */
export interface RunbookDeleteOptionalParams
  extends coreClient.OperationOptions {}

/** Optional parameters. */
export interface RunbookPathOptionalParams
  extends coreClient.OperationOptions {}

/** Optional parameters. */
export interface RunbookMoveOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the runbookMove operation. */
export type RunbookMoveResponse = RunbookDto;

/** Optional parameters. */
export interface RunbookExecuteOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the runbookExecute operation. */
export type RunbookExecuteResponse = RunbookExecutionDto;

/** Optional parameters. */
export interface RunbookExecutionGetOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the runbookExecutionGet operation. */
export type RunbookExecutionGetResponse = RunbookExecutionDto;

/** Optional parameters. */
export interface RunbookExecutionListOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the runbookExecutionList operation. */
export type RunbookExecutionListResponse = RunbookExecutionDto[];

/** Optional parameters. */
export interface RunbookPublishOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the runbookPublish operation. */
export type RunbookPublishResponse = {
  /** The parsed response body. */
  body: boolean;
};

/** Optional parameters. */
export interface RunbookSearchOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the runbookSearch operation. */
export type RunbookSearchResponse = RunbookFtsDto[];

/** Optional parameters. */
export interface UserListOptionalParams extends coreClient.OperationOptions {
  query?: string;
}

/** Contains response data for the userList operation. */
export type UserListResponse = UserDto[];

/** Optional parameters. */
export interface UserOrgListOptionalParams extends coreClient.OperationOptions {
  query?: string;
  orgId?: number;
}

/** Contains response data for the userOrgList operation. */
export type UserOrgListResponse = OrgDto[];

/** Optional parameters. */
export interface WorkspaceListOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the workspaceList operation. */
export type WorkspaceListResponse = WorkspaceDto[];

/** Optional parameters. */
export interface WorkspaceGetOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the workspaceGet operation. */
export type WorkspaceGetResponse = WorkspaceDto;

/** Optional parameters. */
export interface WorkspaceUpdateOptionalParams
  extends coreClient.OperationOptions {
  body?: WorkspaceCreateDto;
}

/** Optional parameters. */
export interface WorkspaceCreateOptionalParams
  extends coreClient.OperationOptions {}

/** Contains response data for the workspaceCreate operation. */
export type WorkspaceCreateResponse = WorkspaceDto;

/** Optional parameters. */
export interface StepWiseOptionalParams
  extends coreClient.ServiceClientOptions {
  /** The API version */
  apiVersion?: string;
  /** Overrides client endpoint. */
  endpoint?: string;
}
