import { observer } from "mobx-react-lite"
import { useCallback, useState } from 'react'


import {
    RJSFSchema,
    UiSchema} from '@rjsf/utils'
import { IChangeEvent } from '@rjsf/core'
import validator from '@rjsf/validator-ajv8'
import { SchemaForm } from "@/components/SchemaForm/SchemaForm"
import { useAuthStore } from "@/hooks/useStores"
import { ErrorDto } from "@/client"

const schema: RJSFSchema = {
    type: 'object',
    required: [
        "email",
    ],
    properties: {
        email: {
            type: "string",
            title: "Email"
        },
    }
}

const uiSchema: UiSchema = {
    password: {
        "ui:widget": "password"
    },
    "ui:submitButtonOptions": {
        submitText: "Submit"
    }
}

export interface RegistrationFormProps {
    onSubmit: () => void
}

export const ForgotPasswordForm = observer(({onSubmit}: RegistrationFormProps) => {
    const [formData, setFormData] = useState(null)
    const [swError, setSwError] = useState<ErrorDto | undefined>(undefined)
    const auth = useAuthStore()

    const _onSubmit = useCallback(async (e: IChangeEvent<{email: string, password: string}>) => {
        if (!e.formData) return

        const {email, password} = e.formData
        try {
            const user = await auth.passwordForgot(email)
            onSubmit()
        } catch (e) {
            const ex = e as ErrorDto
            setSwError(ex)
        }
    }, [])

    return (
        <SchemaForm
            onSubmit={_onSubmit}
            formData={formData}
            onChange={e => setFormData(e.formData)}
            schema={schema}
            validator={validator}
            uiSchema={uiSchema}
            swError={swError}/>
    )
})