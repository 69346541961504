import { useContext, useRef, useState } from "react"
import { StepWiseEditorContext } from "../context"
import { observer } from "mobx-react-lite"
import { IconLink } from "@tabler/icons-react"
import { SwTooltip, SwTooltipTrigger } from "@/components/Tooltip/Tooltip"
import { Form } from "react-aria-components"
import { SwPopover } from "@/components/Popover/Popover"
import { SwButton, SwToggleButton } from "@/components"
import { Dialog } from "@/components/Dialog/Dialog"
import { SwTextField } from "@/components/TextField/TextField"
import { SplitGroup } from "@/components/SplitGroup/SplitGroup"


export const Link = observer(() => {
    const editor = useContext(StepWiseEditorContext)

    const [isOpen, setIsOpen] = useState(false)
    const [prevUrl, setPrevUrl] = useState("")
    const ref = useRef<HTMLButtonElement>(null)

    const isDisabled = !Array.from(editor?.proseCache.obj.selectedNodeTypes.keys() || []).every(n => n == editor?.tipTap.schema.nodes['text'])
    const isActive = editor?.proseCache.obj.activeMarkTypes.has(editor?.tipTap.schema.marks['link'])

    const onCancel = () => {
        editor?.tipTap.chain().focus().run(); setIsOpen(false)
    }

    const onSubmit = (url: string) => {
        if (!url)
            editor?.tipTap.chain().focus().extendMarkRange('link').unsetLink().run()
        else
            editor?.tipTap.chain().focus().extendMarkRange('link').setLink({href: url}).run()
        setIsOpen(false)
    }

    const onOpenChange = (value: boolean) => {
        if (value) {
            const url = editor?.tipTap.getAttributes('link').href ?? ""
            setPrevUrl(url)
        }
        setIsOpen(value)
    }

    return (<>
        <SwTooltipTrigger>
            <SwToggleButton isSelected={isActive} isDisabled={isDisabled} ref={ref} isQuiet onPress={() => onOpenChange(!isOpen)}>
                <IconLink size={20} className="m-1" />
            </SwToggleButton>
            <SwTooltip>
                "Link"
            </SwTooltip>
        </SwTooltipTrigger>
        <SwPopover triggerRef={ref} isOpen={isOpen} onOpenChange={onOpenChange} >
            <LinkForm prevUrl={prevUrl} onCancel={onCancel} onSubmit={onSubmit}/>
        </SwPopover>
    </>)
})

const LinkForm = ({prevUrl, onSubmit, onCancel}: {prevUrl: string, onSubmit: (url: string) => void, onCancel: () => void}) => {
    const url = useRef<HTMLInputElement>(null)

    return (
        <Dialog className="p-2">
            <Form onSubmit={(e) => {
                e.preventDefault()
                onSubmit(url.current?.value ?? '')
            }}>
                <SwTextField label="Link Url" defaultValue={prevUrl} ref={url}/>
                <SplitGroup className="mt-2">
                    <div/>
                    <div>
                        <SwButton size="sm" className="mr-2" onPress={onCancel}>Cancel</SwButton>
                        <SwButton size="sm" type="submit" variant="primary">Set</SwButton>
                    </div>
                </SplitGroup>
            </Form>
        </Dialog>
    )
}