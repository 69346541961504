import { Node } from "@tiptap/core";


export const KeyValue = Node.create({
    name: 'keyValue',
    addAttributes: () => ({
        key: {},
        value: {}
    }),
    renderHTML: () => {
        return ['div', {style: 'display: none'}]
    }
})

export const MetaData = Node.create({
    name: 'metaData',
    content: 'keyValue*',
    group: 'top',
    atom: true,
    selectable: false,
    draggable: false,
    addExtensions: () => ([KeyValue]),
    renderHTML: () => {
        return ['div', {style: 'display: none'}]
    }
})


