import React, { useContext } from 'react'


import 'prosemirror-view/style/prosemirror.css'
import 'prosemirror-example-setup/style/style.css'
import 'prosemirror-menu/style/menu.css'

import './Editor.css'

import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import Superscript from '@tiptap/extension-superscript'
import SubScript from '@tiptap/extension-subscript'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import { useStepWiseEditor } from './hooks.js'
import { useDrop } from 'react-aria'
import { WorkflowStepExt } from '@/editor/nodes/step/Extension'
import { ImageExt } from '@/editor/nodes/image/Extension'
import { EmbedExt } from '@/editor/nodes/embed/Extension'
import { IDoc } from '@/stores/DocStore'
import { EditorContent } from '@tiptap/react'
import { StepWiseEditorContext } from './context'
import { FormatBar } from './FormatBar'
import clsx from 'clsx'

import { observer } from 'mobx-react-lite'
import { WorkspaceContext } from '@/contexts'
import { Alert } from '@/editor/nodes/alert/Extension'
import { Step } from '@/editor/nodes/step-block/Extension'
import { Base } from '@/editor/extensions/Base'
import { WorkflowSync } from '@/editor/extensions/WorkflowSync'
import { MetaData } from '@/editor/nodes/meta/Extension'


export type Id = string

export interface EditorProps {
    doc?: Nullable<IDoc>
    layout?: 'doc' | 'blog'
}

export const Editor = observer(({doc, layout = 'doc'}: EditorProps) => {
    const ref = React.useRef(null)
    const wrapperRef = React.useRef(null)
    const {dropProps} = useDrop({ref})

    const workspaceCtx = useContext(WorkspaceContext)!
    const mode = workspaceCtx.mode

    const editor = useStepWiseEditor(doc, {
        editorProps: {
            attributes: {
                class: 'prose prose-doc max-w-none dark:prose-invert'
            },
            handlePaste(view, evt, slice) { console.log('paste', slice) }
        },
        editable: true,
        extensions: [
            Base,
            MetaData,
            ImageExt.configure({
                genLink: (id: string) => `${location.origin}/f/${workspaceCtx.orgId}/${id}`
            }),
            EmbedExt,
            Underline.extend({
                parseHTML: () => ([
                    {
                        tag: 'u'
                    },
                    {
                        tag: ':not(a)',
                        priority: 100,
                        consuming: false,
                        getAttrs: (node) => {
                            const elm = node as HTMLElement
                            return elm.style.textDecoration.includes("underline") ? {} : false
                        }
                    }
                ])
            }),
            Superscript,
            SubScript,
            Highlight,
            Link.configure({openOnClick: false}),
            Alert,
            WorkflowSync.configure({workspaceCtx}),
            Table.extend({group: 'top'}).configure({
                resizable: false,
            }),
            TableRow,
            TableHeader,
            TableCell,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
            WorkflowStepExt,
            Step,
        ],

        content: doc?.content
    }, [doc?.id])

    workspaceCtx.setActiveEditor(editor)

    return (
        <StepWiseEditorContext.Provider value={editor}>
            <div className="flex flex-col h-full w-full grow" {...dropProps}>
            <FormatBar/>
            <div className='w-full h-full sw-editor__scroller'>
                <div className="sw-editor__header"/>
                <EditorContent editor={editor?.tipTap || null} className={clsx(
                    'sw-editor',
                    'dark:text-zinc-100',
                )}/>
            </div>
            </div>
        </StepWiseEditorContext.Provider>
    )
})
Editor.displayName = 'Editor'