import { SwIcon } from "@/components/Icon/Icon"
import { StepDisplayType } from "@/stores/WorkflowDefinition"
import { cn } from "@/util/cn"
import {  IconWebhook, IconUsers, IconChecks } from "@tabler/icons-react"
import clsx from 'clsx'

interface StepIconProps{
    iconType: StepDisplayType,
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl'
    variant?: 'loud' | 'quiet',
    className?: string
}

const BaseIconStyles = {
    size: {
        xs: 'w-4 h-4',
        sm: 'w-6 h-6',
        md: 'w-8 h-8',
        lg: 'w-12 h-12',
        xl: 'w-16 h-16',
        xxl: 'w-24 h-24',
        xxxl: 'w-32 h-32',
    }
}
export const iconMap = {
    'Confirmation': {
        icon: IconChecks,
        color: 'bg-[#289217]',
    },
    'Task': {
        icon: IconWebhook,
        color: 'bg-[#8957BB]',
    },
    'Approval': {
        icon: IconUsers,
        color: 'bg-[#E0891C]',
    },
    'Unknown': {
        icon: IconUsers,
        color: 'bg-[#E0891C]',
    },
}

export const StepIcon = ( props: StepIconProps) => {
    const {
        iconType = 'Task',
        size = 'md',
        variant = 'loud',
        className = "",
        ...otherProps
    } = props
    return (
    <div
        className={cn(
            BaseIconStyles.size[size],
            "flex-none rounded justify-center flex items-center",
            iconMap[iconType].color,
            (variant == 'quiet') && "bg-slate-500",
            className
        )}
        // style={{ backgroundColor: iconMap[iconType].color }}
    >
     <SwIcon icon={iconMap[iconType].icon} className="text-white w-full h-full p-1" />
    </div>
    )

}