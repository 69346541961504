import { Dialog as AriaDialog, DialogProps as AriaDialogProps } from 'react-aria-components'

import './Dialog.css'
import { cn } from '@/util/cn'

export interface DialogProps extends AriaDialogProps {}

export const Dialog = (props: AriaDialogProps) => {

    const {
        children,
        className,
        ...dialogProps
    } = props

    return <AriaDialog className={cn('sw-Dialog', className)} {...dialogProps}>{children}</AriaDialog>
}