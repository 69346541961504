import { DependencyList, useContext, useEffect, useMemo, useState } from 'react'
import { nanoid } from 'nanoid'
import { EditorOptions } from '@tiptap/react'
import { DocMode, HubClient } from '../../hub-client/HubClient'
import { EditorState, Plugin, PluginKey } from 'prosemirror-state'

import { schemaToJson } from '@stepwisehq/prosemirror-collab-commit/apply-commit'
import { collab } from '@stepwisehq/prosemirror-collab-commit/collab-commit'
import { IDoc } from '@/stores/DocStore'
import { cache } from './plugins/ProseCache'
import { MobxContainer } from './plugins/MobxContainer'
import { imageUpload } from './plugins/ImageUpload'
import { StepWiseEditor } from '@/stores/StepWiseEditor'
import { StepWise } from '@/client'
import { WorkspaceEditor } from './StepWiseEditor'
import { WorkspaceContext } from '@/contexts'
import { WorkspaceMode } from '@/stores/WorkspaceCtx'
import { useRootStore } from '@/hooks/useStores'
import { selectionStylePlugin } from '@/editor/plugins/selectionStyle'


export const useStepWiseEditor = (doc?: Nullable<IDoc>, options: Partial<EditorOptions> = {}, deps: DependencyList = []) => {
    // const [editor, setEditor] = useState<StepWiseEditor | null>(null)
    const sw = useRootStore().sw
    const workspaceCtx = useContext(WorkspaceContext)!
    // @ts-ignore
    window.ws = workspaceCtx


    const [editor, hubClient] = useMemo(() => {
        if (!doc) return [null, null]
        let isMounted = true

        // @ts-ignore
        const instance = new WorkspaceEditor(workspaceCtx, options)
        console.debug('Schema')
        console.debug(instance.schema)
        console.debug(JSON.stringify(schemaToJson(instance.schema), null, 4))
        instance.registerPlugin(selectionStylePlugin)
        instance.registerPlugin(collab({version: doc.version}))
        instance.registerPlugin(cache({container: new MobxContainer}))
        instance.registerPlugin(imageUpload({
            upload: async (file: File) => {
                const fileInfo = await sw.docAttachmentCreate(
                    doc.orgId, doc.id, file.type, file.size, {data: file, xSwFilename: `${nanoid()}-${file.name}`}
                )
                console.log(fileInfo)
                return fileInfo
            }
        }))
        instance.registerPlugin(new Plugin({
            key: new PluginKey('drop'),
            props: {
                handleDrop(view, evt) {
                    console.log('Drop!!!', evt, evt.dataTransfer?.getData("text"))

                    console.log(evt.dataTransfer?.types)
                    const imageStr = evt.dataTransfer?.getData("text/html")
                    const img = new DOMParser().parseFromString(imageStr!, "text/html").body.firstChild as HTMLImageElement
                    console.log(img.src)
                },
            }
        }))

        // @ts-ignore
        window.editor = instance

        const swEditor = new StepWiseEditor(doc, instance)

        const hubclient = new HubClient(doc, {
            getState() { return instance.state as EditorState},
            dispatch(tr) { instance.view.dispatch(tr) }},
                [WorkspaceMode.Read, WorkspaceMode.Execute].includes(workspaceCtx.mode) ? DocMode.Read : DocMode.Write)

        instance.on('update', ()=> hubclient.update())

        return [swEditor, hubclient]
    }, [...deps, workspaceCtx.mode, doc?.id, doc?.version])

    useEffect(() => {
        if (!editor || !hubClient) return

        return () => {
            editor.destroy()
            hubClient.close()
            // isMounted = false
            // disposeLogger()
        }
    }, [...deps, workspaceCtx.mode])

    return editor
}